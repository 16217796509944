const pc_img_json = {
  name: "PcImg",
  id: 1715666490843,
  props: {
    styles: [],
    id: 1715666490843,
    imgSrc:
      "https://df5apg8r0m634.cloudfront.net/images/2024/0808/59838f703699699a8f8579f481726fd6.jpg",
    boxWidth: 50,
    align: "center",
  },
};

const pc_base_json = {
  name: "PcBaseBox",
  children: [
    {
      name: "PcRowContent",
      children: [],
      id: 1715666488434,
      props: {
        styles: {
          width: "100%",
        },
      },
    },
  ],
  id: 1715666488433,
  props: {
    styles: {
      maxWidth: "1200px",
    },
  },
};

let h5_img_json = {
  name: "H5Img",
  id: 1715667687268,
  props: {
    styles: [],
    id: 1715667687268,
    imgSrc:
      "https://df5apg8r0m634.cloudfront.net/images/2024/0808/59838f703699699a8f8579f481726fd6.jpg",
  },
};

let h5_base_json = {
  name: "H5BaseBox",
  children: [
    {
      name: "H5RowContent",
      children: [],
      id: 1715667678117,
      props: {
        styles: {
          width: "100%",
        },
      },
    },
  ],
  id: 1715667678116,
  props: {
    styles: {
      maxWidth: "1440px",
    },
  },
};

//输入图片列表，快速生成对应的json数据
export const createProductDetailJson = (imgList) => {
  if (!imgList || imgList.length < 1) {
    return {
      pc: "",
      h5: "",
    };
  }
  let pc_tiem_arr = [];
  let h5_tiem_arr = [];
  for (let index = 0; index < imgList.length; index++) {
    const imgSrc = imgList[index].src;
    const pc_img_item = {
      ...pc_img_json,
      id: pc_img_json.id + index,
      props: { ...pc_img_json.props, imgSrc, id: pc_img_json.id + index },
    };
    const h5_img_item = {
      ...h5_img_json,
      id: h5_img_json.id + index,
      props: { ...h5_img_json.props, imgSrc, id: h5_img_json.id + index },
    };
    pc_tiem_arr.push(pc_img_item);
    h5_tiem_arr.push(h5_img_item);
  }

  pc_base_json.children[0].children = pc_tiem_arr;
  h5_base_json.children[0].children = h5_tiem_arr;
  return {
    pc: pc_base_json,
    h5: h5_base_json,
  };
};

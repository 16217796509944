import { toLine } from '../../../utils/transformCssPropertyName'

export default function staticRender ({ config, page_id = 0 }) {
  let { id, props = {} } = config
  id = `${page_id}-${id}`
  const { styles = {} } = props
  if (styles.minHeight === 'px') {
    styles.minHeight = '0px'
  }
  return {
    styles: [
      {
        name: `.pt-col-content-${page_id}`,
        content: `
          .pt-col-content-${page_id} {
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
          }
        `
      },
      {
        name: `.pt-col-content-${id}`,
        content: `
          .pt-col-content-${id} {
            ${Object.keys(styles).map(css => {
          const value = styles[css]
          if (value === undefined || value === null) return ''
          return `${toLine(css)}:${value};`
        }).join(' ')}
            ${styles.backgroundColor ? `background: ${styles.backgroundColor};` : ''}
          }
        `
      }
    ],
    html: `
      <div class="pt-col-content-${page_id} pt-col-content-${id}">
        {{CHILDREN_PLACEHOLDER}}
      </div>
    `,
  }
}

import { toLine } from "../../../utils/transformCssPropertyName"

export default function staticRender ({ config, page_id = 0 }) {
  let { id, props = {} } = config
  id = `${page_id}-${id}`
  let { styles = {} } = props

  const result = {
    styles: [
      {
        name: `.pt-swiper-item-${page_id}`,
        content: `
          .pt-swiper-item-${page_id} {
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            min-height: 100px;
          }
        `
      },
      {
        name: `.pt-swiper-item-${id}`,
        content: `
          .pt-swiper-item-${id} {
            ${Object.keys(styles).map(css => {
          return `${toLine(css)}:${styles[css]};`
        }).join(' ')}
            ${styles.backgroundColor ? `background: ${styles.backgroundColor};` : ''}
          }
        `
      }
    ],
    html: `
      <div class="pt-swiper-item-${page_id} pt-swiper-item-${id} swiper-slide">
        {{CHILDREN_PLACEHOLDER}}
      </div>
    `,
  }
  return result
}

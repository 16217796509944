import { toLine } from "../../../utils/transformCssPropertyName"

function jsStyle2NormalStyle(name, value) {
    const map = {
        'justifyContent': {
            name: 'text-align',
            valueMap: {
                '': 'left',
                'flex-start': 'left',
                'center': 'center',
                'flex-end': 'right',
                'space-between': 'center',
            }
        },
        'alignItems': {
            name: 'vertical-align',
            valueMap: {
                '': 'top',
                'flex-start': 'top',
                'center': 'middle',
                'flex-end': 'bottom',
            }
        },
    }
    const obj = map[name]
    if (!obj) {
        return []
    }
    return [obj.name, obj.valueMap[value || '']]
}

export default function staticRender({ config }) {
    const { id, props = {}, children = [] } = config
    let { styles = {} } = props
    let { justifyContent } = styles

    /*
        表格布局，如果子组件宽度不是100%，需要手动创建元素填充空白区域
        如果默认flex-start 就把gap放右边
        如果是flex-end 就把gap放左边
        如果是center 就把gap平分，放在两边
        如果是space-between，就把gap平分，放在子组件之间

        table tr 不支持padding
        tr td 不支持margin
        上边两个属性需要特殊处理一下，本来想在外边套一个div，但是如果设置了背景色之类的，又会出现问题。
    */
    let childrenWidth = children.reduce((pre, child) => {
        let width = child.props.styles.width
        width = Number(width.substring(0, width.length - 1))
        return pre + width
    }, 0)
    if (childrenWidth > 99.999999) {
        childrenWidth = 100
    }
    let gap = ''
    if (childrenWidth < 100) {
        let gapWidth = 0
        if (!justifyContent || justifyContent === 'flex-start' || justifyContent === 'flex-end') {
            gapWidth = 100 - childrenWidth
        } else if (justifyContent === 'center') {
            gapWidth = (100 - childrenWidth) / 2
        } else if (justifyContent === 'space-between') {
            gapWidth = (100 - childrenWidth) / 2
        }
        gap = `<div style="display:table-cell; width:${gapWidth}%; visibility:hidden;"></div>`
    }

    styles = {
        maxWidth: '649px',
        width: '100%',
        margin: '0 auto',
        boxSizing: 'border-box',
        borderCollapse: 'collapse',
        ...styles
    }
    const cellpadding = ((styles.paddingTop || styles.paddingBottom || '').match(/\d+/g) || [0])[0]
    const emailRowStyle = Object.keys(styles)
        .map(css => {
            const [cssName, cssVal] = jsStyle2NormalStyle(css, styles[css])
            if (cssName) {
                return `${cssName}:${cssVal};`
            }
            return `${toLine(css)}:${styles[css]};`
        })
        .join(' ')
    return {
        styles: [],
        html: `
            <table style="${emailRowStyle}" border="0" cellpadding="${cellpadding}" cellspacing="0">
                <tr>
                    ${(() => {
                        if (!gap) {
                            return children.reduce((pre, _, currIdx) => `${pre} {{CHILDREN_PLACEHOLDER_${currIdx}}}`, '')
                        } else {
                            if (!justifyContent || justifyContent === 'flex-start') {
                                return `
                                            ${children.reduce((pre, _, currIdx) => `${pre} {{CHILDREN_PLACEHOLDER_${currIdx}}}`, '')}
                                            ${gap}
                                        `
                            } else if (justifyContent === 'flex-start') {
                                return `
                                            ${gap}
                                            ${children.reduce((pre, _, currIdx) => `${pre} {{CHILDREN_PLACEHOLDER_${currIdx}}}`, '')}
                                        `
                            } else if (justifyContent === 'center') {
                                return `
                                            ${gap}
                                            ${children.reduce((pre, _, currIdx) => `${pre} {{CHILDREN_PLACEHOLDER_${currIdx}}}`, '')}
                                            ${gap}
                                        `
                            } else if (justifyContent === 'space-between') {
                                return children.reduce((pre, _, currIdx, arr) => {
                                    const isLastEl = currIdx === arr.length - 1
                                    return `${pre} {{CHILDREN_PLACEHOLDER_${currIdx}}} ${isLastEl ? '' : gap}`
                                }, '')
                            }
                        }
                    })()}
                </tr>
            </table>
        `,
        gap
    }
}
import React, { Component } from 'react'
import styled from 'styled-components'
import {Select} from "../index";
import {Button, FlexLine} from "../../style";
import {inject, observer} from "mobx-react";

const Input = styled.input({
  border:'1px solid #e4e4e4',
  padding:'10px',
  width:'300px',
  height: '40px',
  background:'#fff',
  borderRadius:'4px',
  paddingRight: '30px'
})  

const Container = styled.div({
    position:'relative'
})

const SerachBtn = styled.div({
  position:'absolute',
  top:0,
  bottom:0,
  right:0,
  padding:'10px',
  margin:'auto',
  paddingTop: '13px',
  cursor: 'pointer'
})

@inject('siteStore', 'templateStore','homeStore')
@observer
class Search extends Component {

  state = {
    search: '',
    type: null,
    site_id: null,
    status: null
  };

  onSubmit = (e) => {
    this.props.onSearch({
      search: this.state.search,
      type: this.state.type,
      site_id: this.state.site_id,
      status: this.state.status
    });
  }

  render() {
    const { onSearch } = this.props

    let _options = []
    this.props.siteStore.sites.forEach(v => {
      if (v.policiesKey) {
        if (this.props.homeStore.hasPolicies(v.policiesKey)) {
          _options.push({
            label: v.name,
            value: v.site_id
          })
        }
      }
    });

    return (
        <Container>
          <form style={{ display: 'flex' }} onSubmit={this.onSubmit}>
            <div style={{ width: '250px', marginRight: '10px' }}>
              <Select
                defaultValue={this.state.type}
                placeholder="类型"
                onChange={(data) => {
                  this.setState({
                    type: data.value
                  })
                }}
                options={[{
                  value: null,
                  label: '选择类型'
                }].concat(this.props.templateStore.templateTypes)}
              />
            </div>
            {
              this.props.templateStore.isTemplate ? <div style={{ width: '250px', marginRight: '10px' }}>
                <Select
                  defaultValue={this.state.status}
                  placeholder="状态"
                  onChange={(data) => {
                    this.setState({
                      status: data.value
                    })
                  }}
                  options={[{
                    value: null,
                    label: '选择状态'
                  }].concat(this.props.templateStore.templateStatus)}
                />
              </div> : <div style={{ width: '250px', marginRight: '10px' }}>
                <Select
                  defaultValue={this.state.site_id}
                  placeholder="站点"
                  onChange={(data) => {
                    this.setState({
                      site_id: data.value
                    })
                  }}
                  options={[{
                    value: null,
                    label: '选择站点'
                  }].concat(_options)}
                />
              </div>
            }
            <Input
              style={{ marginRight: '10px' }}
              placeholder="输入方案名称"
              value={this.state.search}
              onChange={(e) => {
                this.setState({
                  search: e.target.value
                });
              }}
            />
            <Button onClick={this.onSubmit}>
              筛选
            </Button>
          </form>
        </Container>
    )
  }
}

export default Search

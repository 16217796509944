import React from 'react';
import styled from 'styled-components';

const Box = styled.div(({ styles }) => ({
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100px',
    ...styles,
}));

function EmailRowContent({ children = '', styles = {} }) {
    return (
        <Box
            style={{
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100px',
                ...styles
            }}>
            {styles.flexDirection === 'row' && (
                <>
                    {children[0]}
                    {children[1].map((item, index) => {
                        return (
                            <div key={index} style={{ width: 'auto' }}>{item}</div>
                        )
                    })}
                </>
            )}
            {styles.flexDirection !== 'row' && children}
        </Box>
    )
}

export default EmailRowContent;


//内容占比

import React, { Component, Fragment } from 'react';
import { Select } from '../../style';
import { ContentTitle, FlexLine } from '../../style';
import { Input, WhiteSpace } from '../../components/index';
import styled from 'styled-components';
import { Switch } from 'antd';

class UnderLine extends Component {

  get positionOptions(){
    return [
      {
        value: 'top',
        label: '在上方'
      },{
        value: 'bottom',
        label: '在下方'
      }, {
        value: 'left',
        label: '在左边'
      }, {
        value: 'right',
        label: '在右边'
      }
    ]
  }

  onPositionChange = (e)=>{
    const position = e.target.value;
    const { updateDataProps, underLine = {},underLine_name} = this.props
    updateDataProps(underLine_name, { ...underLine, position })
  }

  onColorChange = (e) => {
    const color = e.target.value;
    const { updateDataProps, underLine = {}, underLine_name } = this.props
    updateDataProps(underLine_name, { ...underLine, color })
  }

  onWidthChange = (e) => {
    const width = e.target.value;
    const { updateDataProps, underLine = {}, underLine_name} = this.props
    updateDataProps(underLine_name, { ...underLine, width })
  }

  onHeightChange = (e) => {
    const height = e.target.value;

    const { updateDataProps, underLine = {}, underLine_name} = this.props
    updateDataProps(underLine_name, { ...underLine, height})
  }

  onStatusChange = (is_underLine)=>{
    const { updateDataProps, is_underLine_name, isCloseFitContent} = this.props
    updateDataProps(is_underLine_name, is_underLine)
    if(is_underLine){
      updateDataProps('align', 'center');
      updateDataProps('fitContent', is_underLine)
    }
    if (!is_underLine && isCloseFitContent){
      updateDataProps('fitContent', false)
    }
  }

  render () {
    const { underLine = {}, is_underLine = false ,name} = this.props
    const { width = 50, color, position = 'bottom' ,height = 2} = underLine

    return (
      <Fragment>
        <WhiteSpace />
        <WhiteSpace />
        <ContentTitle style={{fontWeight:'800'}}>下划线-{name}-设置:</ContentTitle>
        <WhiteSpace/>
        <Switch checked={is_underLine} onChange={this.onStatusChange}></Switch>
        <WhiteSpace />
        <WhiteSpace />
        {
          is_underLine && <Fragment>
            <p style={{ fontSize: "12px", color: '#666' }}>选择下划线位置:</p>
            <Fragment>
              <Select
                placeholder={'选择下划线位置'}
                value={position}
                style={{
                  width: '90%',
                  paddingRight: '10px'
                }}
                onChange={this.onPositionChange}
              >
                <option value={''}>请选择</option>
                {
                  this.positionOptions.map((item, index) => (
                    <option key={index} value={item.value}>{item.label}</option>
                  ))
                }
              </Select>
            </Fragment>
            <WhiteSpace />
            <WhiteSpace />
            <p style={{ fontSize: "12px", color: '#666' }}>选择下划线颜色:</p>
            <div style={{ paddingRight: '10px' }}>
              <FlexLine justify='space-between'>
                <Input
                  value={color}
                  type="color"
                  onChange={this.onColorChange}
                  style={{ width: '28px', padding: '2px' }}
                />
                <Input
                  type="text"
                  placeholder={'选中颜色/输入颜色'}
                  value={color || ''}
                  onChange={this.onColorChange}
                />
              </FlexLine>
            </div>
            <WhiteSpace />
            <WhiteSpace />
            <p style={{ fontSize: "12px", color: '#666' }}>设置下划线宽度:</p>
            <FlexLine justify='flex-start'>
              <div style={{ position: 'relative' }}>
                <Input
                  type='number'
                  style={{ width: '120px', margin: '5px', paddingRight: '0' }}
                  onChange={this.onWidthChange}
                  value={width}
                />
                <SpanText>px</SpanText>
              </div>
            </FlexLine>
            <WhiteSpace />
            <WhiteSpace />
            <p style={{ fontSize: "12px", color: '#666' }}>设置下划线粗细:</p>
            <FlexLine justify='flex-start'>
              <div style={{ position: 'relative' }}>
                <Input
                  type='number'
                  style={{ width: '120px', margin: '5px', paddingRight: '0' }}
                  onChange={this.onHeightChange}
                  value={height}
                />
                <SpanText>px</SpanText>
              </div>
            </FlexLine>
          </Fragment>
        }
        <WhiteSpace />
        <WhiteSpace />
      </Fragment>
    );
  }
}


const SpanText = styled.div({
  color: '#999',
  fontSize: '14px',
  textAlign: 'center',
  position: 'absolute',
  top: '10px',
  right: '25px'
})

export default UnderLine
import { toLine } from '../../../utils/transformCssPropertyName'

export default function staticRender ({ config, page_id = 0 }) {
  let { id, props = {} } = config
  id = `${page_id}-${id}`
  const { styles = {} } = props
  return {
    styles: [
      {
        name: `.pt-floor-content-${page_id}`,
        content: `
          .pt-floor-content-${page_id} {
            width: 100%;
            max-width: 100%;
            margin: 0 auto;
            box-sizing: border-box;
            display: flex;
          }
        `
      },
      {
        name: `.pt-floor-content-${id}`,
        content: `
          .pt-floor-content-${id} {
            ${Object.keys(styles).map(css => {
          return `${toLine(css)}:${styles[css]};`
        }).join(' ')}
            ${styles.backgroundColor ? `background: ${styles.backgroundColor};` : ''}
          }
        `
      }
    ],
    html: `
      <div class="pt-floor-content-${page_id} pt-floor-content-${id}">
        {{CHILDREN_PLACEHOLDER}}
      </div>
    `,
  }
}

import  componentMount from "../../lib/componentMount";

import EditH5SwiperImg from "./EditH5SwiperImg";
import H5SwiperImg from "./H5SwiperImg";
import H5SwiperImgStyle from "./H5SwiperImgStyle";
import staticRender from "./StaticRender.js";

const H5SwiperImgRender = componentMount({
    Component:H5SwiperImg,
    Content:EditH5SwiperImg,
    Style:H5SwiperImgStyle,
    text:'轮播图'
})

export default {
    componentMount:H5SwiperImgRender,
    name:'H5SwiperImg',
    text:'轮播图',
    staticRender
}
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { ContentTitle } from '../../../style';
import { WhiteSpace } from '../../../components/index';

import Percentage from '../../EditComponents/Percentage';
import ContentAlign from '../../EditComponents/ContentAlign';
import Editor from '../../../components/Editor';
@inject('createStore')
@observer
class EditTitle extends Component {
  state = {
    isAddEditor: false
  }
  title = ''

  componentDidMount() {
      this.init()
  }

  init = () => {
    this.title = this.props.title || '请输入标题'
    this.setState({
      isAddEditor: true
    })
  }

  handleEditorChange = (content) => {
    const { activeRowData, seletedRowId, updateDataProps } = this.props.createStore;
    if (activeRowData && seletedRowId) {//选中楼层子组件，优先更新子组件
      updateDataProps('title', content);
    }
  }

  proportionChange = (value) => {
    const { updateDataProps } = this.props.createStore;
    updateDataProps('boxWidth', value);
  }

  alignChange = (value) => {
    const { updateDataProps } = this.props.createStore;
    updateDataProps('align', value);
  }
  render() {
    const { title = '', align, boxWidth = '100' } = this.props;
    return (
      <div>
        <ContentTitle>标题:</ContentTitle>
          <div style={{ height: '550px' }}>
            <Editor
              value={title}
              showFont={false}
              onChange={this.handleEditorChange}
              style={{
                width: "100%",
                height: "400px",
              }}
            />
        </div>
        <WhiteSpace />
        <Percentage percent={boxWidth} onChange={this.proportionChange} />
        <ContentAlign align={align} onChange={this.alignChange} />
      </div>
    )
  }
}

export default EditTitle;
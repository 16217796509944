import componentMount from "../../lib/componentMount";

import EditContainer from "./EditPcRule";
import Component from "./PcRule";
import BaseStyle from "../../BaseBox/BaseStyle";
import staticRender from "./StaticRender.js";

const _Render = componentMount({
    Component:Component,
    Content:EditContainer,
    Style:BaseStyle,
    text:'规则'
})

export default {
    componentMount:_Render,
    name:'PcRule',
    text:'规则',
    staticRender
}
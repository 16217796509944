import { toLine } from "../../../utils/transformCssPropertyName"

export default function staticRender ({ config, page_id = 0 }) {
  let { id, props = {} } = config
  id = `${page_id}-${id}`
  let {
    buttonText = 'Get Code',
    placeholder = 'Enter your email address to get code',
    lang = 'en',
    styles = {},
    boxWidth = '100',
    align = '',
    error_color = '#d25151',
  } = props

  const result = {
    styles: [
      {
        name: `.pt-h5-alert-coupon-${page_id}`,
        content: `
          .pt-h5-alert-coupon-${page_id} {
            display: flex;
          }
          .pt-h5-alert-coupon-form-input {
            display: block;
            width: 100%;
            border: 1px solid rgba(228, 228, 228, 1);
            box-sizing: border-box;
            line-height: 1;
            color: #333;
            font-size: 14px;
            margin: 0 auto 20px;
            font-family: Roboto-Regular;
            text-align: center;
            border-radius: 4px;
            height: 40px;
            /* placeContent: { color: #999; } */
          }
          .pt-h5-alert-coupon-form-submit-btn {
            border: none;
            font-size: 18px;
            color: #fff;
            line-height: 1;
            width: 100%;
            height: 40px;
            margin: 0 auto;
            background: #26abff;
            border-radius: 4px;
            display: block;
          }
          .pt-h5-alert-coupon-err-msg {
            margin-top: 10px;
            text-align: center;
          }
        `
      },
      {
        name: `.pt-h5-alert-coupon-${id}`,
        content: `
          .pt-h5-alert-coupon-${id} {
            justify-content: ${align || 'flex-start'};
          }
          .pt-h5-alert-coupon-form-box {
            width: ${boxWidth}%;
          }
          .pt-h5-alert-coupon-form-submit-btn-${id} {
            ${Object.keys(styles).map(css => `${toLine(css)}:${styles[css]};`).join(' ')}
          }
          .pt-h5-alert-coupon-err-msg-${id} {
            color: ${error_color};
          }
        `
      }
    ],
    html: `
      <div class="pt-h5-alert-coupon-${page_id} pt-h5-alert-coupon-${id}">
        <div class="pt-h5-alert-coupon-form-box">
          <form id="pt-h5-alert-coupon-form-${id}">
            <input
              class="pt-h5-alert-coupon-form-input"
              type="email"
              placeholder="${placeholder}"
            />
            <button
              class="pt-h5-alert-coupon-form-submit-btn pt-h5-alert-coupon-form-submit-btn-${id}"
              type="submit"
            >${buttonText}</button>
          </form>
          <div class="pt-h5-alert-coupon-err-msg pt-h5-alert-coupon-err-msg-${id}"></div>
        </div>
      </div>
      <script>
        (function() {
          var alertCoupon = document.querySelector('.pt-h5-alert-coupon-${id}')
          var errMsg = alertCoupon.querySelector('.pt-h5-alert-coupon-err-msg')
          var form = alertCoupon.querySelector('#pt-h5-alert-coupon-form-${id}')
          var emailInput = form.querySelector('input')
          var submitBtn = form.querySelector('button')

          function handleSubmit(lang, email, elBtn, cb) {
            if (!email) return false;
            window.gtag && window.gtag('event', 'New user pop-up_Click', {});

            var oldBtnText = elBtn.innerText;
            var oldBg = getComputedStyle(elBtn)['background-color'];

            function disabledButton(el) {
              el.setAttribute('disabled', 'true');
              el.style.background = '#ccc';
              el.innerText = 'loading...';
            }
            function activeButton(el) {
                el.removeAttribute('disabled');
                el.style.background = oldBg;
                el.innerText = oldBtnText;
            }

            var scheme_id = '';
            var group_scheme_id = '';
            var dataDiv = document.querySelector('.UP_config_html');
            if (dataDiv) {
              scheme_id = dataDiv.getAttribute('data-scheme_id');
              group_scheme_id = dataDiv.getAttribute('data-group_scheme_id');
            }

            if (window.UnicodeTransverter) {
              if (document.querySelector('#UP' + window.UnicodeTransverter(window.location.hash.substr(1)))){
                  var popup_data_save = document.querySelector('#UP' + window.UnicodeTransverter(window.location.hash.substr(1))).upalert.config.popup_data_save;
                  group_scheme_id = popup_data_save.group_scheme_id
                  scheme_id = popup_data_save.scheme.id
              }
            }

            var postUrl = '/remoteapi/common/get/coupon';
            var xhr = new XMLHttpRequest();
            xhr.open('POST', postUrl);
            xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");  //否则数据无法被正常接收
            xhr.setRequestHeader("accept-language", lang);  //否则数据无法被正常接收

            xhr.send(\`email_address=\${email}&scheme_id=\${scheme_id}&group_scheme_id=\${group_scheme_id}\`);
            disabledButton(elBtn);
            xhr.onreadystatechange = function () {
                if (xhr.readyState == 4) {
                    activeButton(elBtn);
                    if (xhr.status === 200) {
                        const data = JSON.parse(xhr.responseText);
                        cb && cb(data);
                    }
                };

            }
          }

          form.onsubmit = function(e) {
            e.preventDefault()
            handleSubmit('${lang}', emailInput.value, submitBtn, function(data) {
              function AlertCopy(couponCode) {
                var textarea = document.createElement('p')
                textarea.style = "opacity:0;top:0;z-index:-999;left:0;position:absolute"
                textarea.innerHTML = couponCode
                var $textarea = textarea
            
                document.body.appendChild($textarea)
            
                var range = document.createRange();
                range.selectNodeContents($textarea);
                var selection = document.getSelection();
            
                selection.removeAllRanges();
                selection.addRange(range);
                document.execCommand('Copy');
            
                var timer = setTimeout(function () {
                  document.body.removeChild($textarea)
                  clearTimeout(timer);
                }, 1500);
                return true;
              }
              function showCode(couponCode) {
                var alertByTool = document.getElementsByClassName("H5_Alert_By_Tool")
                if (alertByTool && alertByTool.length > 1) {
                  alertByTool[0].style.display = 'none'
                  alertByTool[1].style.display = 'block'
                  if (document.getElementById('h5_new_user_alert_code')) {
                    document.getElementById('h5_new_user_alert_code').innerText = couponCode
                  }
                }
              }
              var { coupon_code = '', expire_date = '', special_code, special_expire_date, status, msg } = data;
              if (coupon_code && expire_date) {
                AlertCopy(coupon_code)
                showCode(coupon_code)
              } else if (status == 0 && special_code && special_expire_date) {
                AlertCopy(special_code)
                showCode(special_code)
              } else if (msg) {
                errMsg.innerText = msg
              }
            })
          }
        })()
      </script>
    `,
  }
  return result
}

import { toLine } from "../../../utils/transformCssPropertyName"

function flex2textAlign(value) {
    const map = {
        'flex-start': 'left',
        'center': 'center',
        'flex-end': 'right',
    }
    return map[value] || 'left';
}

export default function staticRender({ config }) {
    const { id, props = {} } = config
    let { imgSrc, href, boxWidth = 100, align, styles, imgH, imgW } = props
    if (!imgSrc) {
        imgSrc = 'https://df5apg8r0m634.cloudfront.net/images/4ca7c2e83a08d533a1d3997c514b232c.jpg'
    }
    /*
        这里样式分为几种情况
        2. 只设置了高
        3. 只设置了宽
        4. 都没设置
    */
    let imgStyle = 'display:block; box-sizing:border-box; '; // `${imgW ? `width:${imgW};` : ''} ${imgH ? `height:${imgH};` : ''}`
    switch (true) {
        case imgH !== undefined && imgW !== undefined: // 1. 设置了宽高
            imgStyle += `width:${imgW}; height:${imgH};`;
            break;
        case imgH !== undefined && imgW === undefined: // 2. 只设置了高
            imgStyle += `height:${imgH};`;
            break;
        case imgH === undefined && imgW !== undefined: // 3. 只设置了宽
            imgStyle += `width:${imgW};`;
            break;
        default: // 4.宽高都没设置
            imgStyle += 'width: 100%';
    }
    let { marginLeft } = styles
    if (marginLeft) {
        const result = marginLeft.match(/\d+/)
        if (result && result[0]) {
            boxWidth = `calc(${boxWidth}% - ${result[0]}px)`
            marginLeft = undefined
        } else {
            boxWidth = boxWidth + '%'
        }
    } else {
        boxWidth = boxWidth + '%'
    }
    styles = {
        display: 'inline-block',
        fontSize: 0,
        width: boxWidth,
        cursor: href ? 'pointer' : 'default',
        ...styles
    }
    let imgBoxStyle = Object.keys(styles).map(css => (`${toLine(css)}:${styles[css]};`)).join(' ') + 'box-sizing:border-box'
    return {
        styles: [],
        html: `
            <div class="email_img" style="width:100%; box-sizing:border-box; font-size: 0;line-height:0; text-align:${flex2textAlign(align)}">
                ${href ? `
                    <a
                        style="${imgBoxStyle}"
                        href="${href}"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img src="${imgSrc}" style="${imgStyle}" alt="" />
                    </a>
                ` : `
                    <div
                        style="${imgBoxStyle}"
                    >
                        <img src="${imgSrc}" style="${imgStyle}" alt="" />
                    </div>
                `}
            </div>
        `
    }
}

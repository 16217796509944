// pc 文本
import React from 'react';


function EmailText({ text = '请输入文本', styles = {}, boxWidth = '100', align = '' }) {
    return (
        <div
            style={
                {
                    display: 'flex',
                    width: '100%',
                    boxSizing: 'border-box',
                    justifyContent: align
                }
            }>
            <div style={
                {
                    fontSize: '14px',
                    lineHeight: '1.5',
                    textAlign: 'center',
                    color: '#333',
                    width: `${boxWidth}%`,
                    ...styles
                }
            }
                dangerouslySetInnerHTML={{ __html: text }}></div>
        </div>
    )
}
export default EmailText;
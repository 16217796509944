import React, { Component } from 'react'
import ReactQuill, { Quill } from 'react-quill'
import * as Emoji from 'quill-emoji'
import 'react-quill/dist/quill.snow.css'
import 'quill-emoji/dist/quill-emoji.css'
import './index.css'

Quill.register('modules/emoji', Emoji)
// 自定义粘贴处理函数
const Clipboard = Quill.import('modules/clipboard');

class CustomClipboard extends Clipboard {
  constructor(quill, options) {
    super(quill, options);
    this.quill = quill;
  }

  onPaste(e) {
    e.preventDefault();
    const clipboardData = e.clipboardData || window.clipboardData;
    const text = clipboardData.getData('text/plain');
    const range = this.quill.getSelection();
    if (range) {
      const index = range.index;
      this.quill.insertText(index, text);
      this.quill.setSelection(index + text.length, 0);
    }
  }
}

// 注册自定义 Clipboard 模块
Quill.register('modules/clipboard', CustomClipboard);

// 字体
const fontFamilyArr = ['Roboto-Regular', 'Roboto-Light', 'Roboto-Medium', 'Poppins-Bold', 'Poppins-Light', 'Poppins-Medium', 'Poppins-Regular']
const fonts = Quill.import('attributors/style/font')
fonts.whitelist = fontFamilyArr
Quill.register(fonts, true)
// 字体大小
const fontSizeArr = ['12px', '14px', '16px', '18px', '20px', '22px', '24px', '28px', '32px', '36px', '48px']
const Size = Quill.import('attributors/style/size')
Size.whitelist = fontSizeArr
Quill.register(Size, true)

class MyEditor extends Component {
  constructor(props) {
    super(props)
    this.quillRef = React.createRef()
  }

  componentDidMount() {
    // eslint-disable-next-line no-unused-expressions
    document.querySelector('.ql-toolbar')?.addEventListener('mousedown', e => {
      e.preventDefault()
    })
  }

  handleChange = value => {
    this.props.onChange(value)
  }

  render() {
    const { style, value, showFont = true } = this.props
    let toolbarContainer = [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      ['link'],
      [
        {
          size: fontSizeArr
        }
      ],
      [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ color: [] }, { background: [] }],
      ['emoji'],
      ['clean']
    ];

    // 只有当showFont为true时才展示字体选项
    if (showFont) {
      toolbarContainer.splice(4, 0, [
        {
          font: fontFamilyArr
        }
      ]);
    }

    const modules = {
      toolbar: {
        container: toolbarContainer
      },
      'emoji-toolbar': true,
      'emoji-textarea': false,
      'emoji-shortname': true,
      clipboard: {
        matchVisual: false
      }
    }

    const formats = ['header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', 'image', 'imageBlot', 'emoji', 'color', 'background', 'width', 'height', 'font', 'size']

    return (
      <>
        <ReactQuill theme='snow' value={value} style={style} modules={modules} ref={this.quillRef} formats={formats} onChange={this.handleChange} />
      </>
    )
  }
}

export default MyEditor

import {observable, action, runInAction} from 'mobx';
import {axiosDelete, axiosGet} from '../api';

class FileStore {
  @observable
  fileList = [];

  @observable
  currentFile = null;

  @observable
  searchText = '';

  preFlag = '';

  @action
  setFileList = (fileList) => {
    this.fileList = fileList;
  }

  @action
  getFileList = async (flag) => {
    if (flag) {
      this.preFlag = flag;
    }
    const { data } = await axiosGet('/apiproxy/resources', { flag: flag || this.preFlag });
    runInAction(() => {
      this.fileList = data;
    });
    return data;
  }

  @action
  deleteFile = async (id) => {
    await axiosDelete(`/apiproxy/resources/${id}`);
    await this.getFileList();
  }

  @action
  setCurrentFile = (id) => {
    let current;
    if (typeof id === "number") {
      current = this.getFileById(id);
    } else {
      current = id;
    }
    this.currentFile = current;
    return current;
  }

  @action
  getFileById = (id) => {
    return this.fileList.find(v => v.id === id);
  }

  @action
  onSearchChange = (e) => {
    this.searchText = e;
  }
}

export default FileStore;

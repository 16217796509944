import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import PageComponentsMachine from '../pageComponents/pageComponentsMachine';

import { withRouter } from "react-router-dom";

import { getTemplate } from '../api';
import pageBasePars from '../pageComponents/pageBasePars';

@withRouter
@inject('viewStore')
@observer
class TemplateView extends Component {

    state = {
        isH5: false
    }
    componentWillMount() {
        const width = document.body.clientWidth
        this.setState({
            isH5: width < 500
        })

    }

    componentDidMount() {
        const id = this.props.match.params.id;
        //获取 方案详情数据
        getTemplate(id)
            .then((data) => {
                if (!data) return;

                const { component_json = {} } = data.data.content;
                const { pc = [], h5 = [] } = component_json;
                if (pc.length < 1) {
                    this.setState({
                        isH5: true
                    })
                }
                const { setHtmlJson } = this.props.viewStore
                setHtmlJson({ ...component_json })
            })
            .catch(e => {
                console.log(e);
                toast("获取 方案详情数据失败，请刷新重试!", {
                    type: 'error'
                });
            })
    }


    renderContent = (page) => {
        return (
            <React.Fragment>
                {
                    page.map((arg, key) => {
                        const BaseBox = pageBasePars.render(arg.name)
                        return (
                            <BaseBox key={key} styles={arg.props.styles} isShowPc={this.state.isH5}>
                                {
                                    arg.children.map((item, idx) => {
                                        const { name, props = {}, id } = item;
                                        if (!name) return '';
                                        const RowContent = pageBasePars.render(name)
                                        return (
                                            <RowContent key={idx} styles={props.styles}>
                                                {
                                                    item.children.map((ele, _indx) => (<div key={_indx}>
                                                        {ele.name && PageComponentsMachine.render(ele.name).renderComponent(ele.props || {}) || ''}
                                                    </div>))
                                                }
                                            </RowContent>
                                        )
                                    })
                                }
                            </BaseBox>
                        )
                    })
                }
            </React.Fragment>
        )
    }
    render() {
        const { html_json } = this.props.viewStore
        const { pc = [], h5 = [], email = [] } = html_json;
        if (email.length > 0) {
            return <div>{this.renderContent(email)}</div>
        }
        return (
            <div>
                {
                    this.state.isH5 ?
                        <React.Fragment>{this.renderContent(h5)}</React.Fragment>
                        :
                        <React.Fragment>{this.renderContent(pc)}</React.Fragment>
                }
            </div>
        );
    }
}

export default TemplateView;
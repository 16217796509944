import React, { Component, Fragment } from 'react';
import { ContentTitle, FlexLine, Input, Button } from '../../../style';
import { inject, observer } from 'mobx-react';
import Percentage from '../../EditComponents/Percentage';
import ContentAlign from '../../EditComponents/ContentAlign';
import Icon from '../../../components/Icon';
import { CheckBox, WhiteSpace } from '../../../components/index';
import styled from 'styled-components';
@inject('createStore','viewStore')
@observer
class EditH5Video extends Component {
    changeBoxWidth = (val) => {
        const { updateDataProps } = this.props.createStore;
        updateDataProps('boxWidth', val);
    }

    alignChange = (value) => {
        const { updateDataProps } = this.props.createStore;
        updateDataProps('align', value);
    }


    cleanVideo = () => {
        const { updateDataProps } = this.props.createStore;
        updateDataProps('videoSrc', '');
        updateDataProps('videoName', '');
    }

    selectVideo = () => {
        this.props.viewStore.setSiteView('video')
    }

    render() {
        const { boxWidth = '100', videoSrc = '', videoName = "", align, autoPlay = true, loop = true, showControl = false } = this.props;
        let videoSrcSplit = videoSrc.split(".")
        const { updateDataProps } = this.props.createStore;
        return (
            <Fragment>
                <FlexLine justify="flex-start" >
                    <ContentTitle>视频：</ContentTitle>
                    <SelectImg onClick={this.selectVideo} >选择视频</SelectImg>
                </FlexLine>
                {videoSrc &&
                    <FlexLine
                        justify="flex-start"
                        style={{ paddingLeft: '45px', marginBottom: '40px', marginTop: '5px' }}
                    >
                    <div onClick={this.cleanVideo} style={{ cursor: 'pointer' }}>
                            <Icon icon="icon-delete" />
                        </div>
                        <div style={{ marginLeft: '10px', color: '#26ABFF' }}>{videoName ? `${videoName}.${videoSrcSplit[videoSrcSplit.length - 1]}` : videoSrc}</div>
                </FlexLine>}
                
                <FlexLine justify="flex-start" >
                    <div style={{ 'width': '15px', marginRight: '10px' }} >
                        <CheckBox
                            checked={autoPlay}
                            onChange={(e) => {
                                updateDataProps('autoPlay', e.target.checked);
                            }} />
                    </div>
                    <CheckBoxTitle>自动播放</CheckBoxTitle>
                </FlexLine>
                <WhiteSpace />
                <WhiteSpace/>
                <FlexLine justify="flex-start">
                    <div style={{ 'width': '15px', marginRight: '10px' }} >
                        <CheckBox
                            checked={loop}
                            onChange={(e) => {
                                updateDataProps('loop', e.target.checked);
                            }} />
                    </div>
                    <CheckBoxTitle>是否循环</CheckBoxTitle>
                </FlexLine>
                <WhiteSpace />
                <WhiteSpace/>
                <FlexLine justify="flex-start">
                    <div style={{ 'width': '15px', marginRight: '10px' }} >
                        <CheckBox
                            checked={showControl}
                            onChange={(e) => {
                                updateDataProps('showControl', e.target.checked);
                            }} />
                    </div>
                    <CheckBoxTitle>是否显示控制器</CheckBoxTitle>
                </FlexLine>
                <WhiteSpace/>
                <Percentage percent={boxWidth} onChange={this.changeBoxWidth}/>
                <ContentAlign align={align} onChange={this.alignChange} />
            </Fragment>
        )
    }
}

export default EditH5Video;

const CheckBoxTitle = styled.div({
    fontSize:'14px',
    marginLeft:'5px'
})

const SelectImg = styled.div({
    width: '100px', 
    padding: '5px 0', 
    textAlign: 'center',
    fontSize:'14px',
    borderRadius:'4px',
    border:'1px solid #ccc',
    cursor:'pointer',
    marginLeft:'10px'
})
import React from 'react';
import AppRouter  from './AppRouter';
import { inject, observer } from 'mobx-react';

@inject('homeStore')
@observer
class App extends React.Component {

  componentDidMount() {
    this.props.homeStore.getPolicies();
  }

  render() {
    return this.props.homeStore.isAuth ? (
      <AppRouter />
    ) : null;
  }
}

export default App;

export default function loadScript(url) {
    return new Promise((resolve, reject) => {
        if (document.getElementById(url)) {
            resolve()
        } else {
            const script = document.createElement('script')
            script.onload = resolve
            script.onerror = reject
            script.id = url
            script.src = url
            document.body.appendChild(script)
        }
    })
}

import componentMount from "../../lib/componentMount";

import EditH5Img from "./EditH5Img";
import H5Img from "./H5Img";
import BaseStyle from "../../BaseBox/BaseStyle";
import staticRender from "./StaticRender.js";

const H5ImgRender = componentMount({
    Component:H5Img,
    Content:EditH5Img,
    Style:BaseStyle,
    text:'图片'
})

export default {
    componentMount:H5ImgRender,
    name:'H5Img',
    text:'图片',
    staticRender
}
import componentMount from "../../lib/componentMount";

import EditContainer from "../../BaseBox/EditFooterBaseBox";
import Component from "./H5BaseBox";
import BaseStyle from "../../BaseBox/BaseStyle";
import staticRender from "./StaticRender.js";

const _Render = componentMount({
    Component:Component,
    Content:EditContainer,
    Style:BaseStyle,
    text:'楼层'
})

export default {
    componentMount:_Render,
    name:'H5BaseBox',
    text:'楼层',
    staticRender
}
import React from 'react';
import {Select as AntdSelect} from 'antd';
import 'antd/dist/antd.css';

const {Option: AntdOption} = AntdSelect;

class Select extends React.Component {
    handleChange = (value) => {
        const {onChange, options} = this.props
        if (onChange) {
            let i = options.length
            while (i--) {
                if (options[i].value === value) {
                    onChange(options[i])
                    return
                }
            }
        }
    }

    render() {
        const {
            placeholder,
            disabled,
            defaultValue,
            options
        } = this.props
        return (
            <div>
                <style>{`
                    :global(.rc-virtual-list-holder) {
                        max-height: 512px;
                    }
                `}</style>
                <AntdSelect
                    style={{width: '100%'}}
                    defaultValue={defaultValue}
                    placeholder={placeholder}
                    disabled={!!disabled}
                    onChange={this.handleChange}
                    size="large"
                    listHeight={512}
                >
                    {
                        options.map((it) => (
                            <AntdOption value={it.value} key={it.value}>{it.label}</AntdOption>
                        ))
                    }
                </AntdSelect>
            </div>
        )
    }
}

export default Select;
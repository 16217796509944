import componentMount from "../../lib/componentMount";

import EditContainer from "./EditH5Button";
import Component from "./H5Button";
import BaseStyle from "../../BaseBox/BaseStyle";
import staticRender from "./StaticRender.js";

const _Render = componentMount({
    Component:Component,
    Content:EditContainer,
    Style:BaseStyle,
    text:'按钮'
})

export default {
    componentMount:_Render,
    name:'H5Button',
    text:'按钮',
    staticRender
}
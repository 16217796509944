import React from 'react';
import styled from 'styled-components';
const Box = styled.div(({ styles, isShowPc }) => {
    return {
        boxSizing: 'border-box',
        display: 'flex',
        ...styles,
        "@media screen and (min-width: 768px)": {
            display: (window && window.isEdit) || isShowPc ? 'flex' : 'none'
        }
    }
})
function H5BaseBox({ children = '', styles = {}, isShowPc = false }) {
    return <Box styles={{ ...styles }} isShowPc={isShowPc}>{children}</Box>
}

export default H5BaseBox;
import componentMount from "../../lib/componentMount";

import EditContainer from "./EditPcTime";
import Component from "./PcTime";
import BaseStyle from "../../BaseBox/BaseStyle";
import staticRender from "./StaticRender.js";

const _Render = componentMount({
    Component:Component,
    Content:EditContainer,
    Style:BaseStyle,
    text:'倒计时'
})

export default {
    componentMount:_Render,
    name:'PcTime',
    text:'倒计时',
    staticRender
}
import { toLine } from '../../../utils/transformCssPropertyName'

export default function staticRender ({ config, page_id = 0 }) {
  let { id, props = {} } = config
  id = `${page_id}-${id}`
  const { align, href, hoverTextColor, hoverBgColor, text = '', styles = {} } = props

  const result = {
    styles: [
      {
        name: `.pt-btn-${page_id}`,
        content: `
          .pt-btn-${page_id} {
            display: flex;
            width: 100%;
            box-sizing: border-box;
          }
          .pt-btn-${page_id} a {
            font-size: 0;
            text-decoration: none;
          }
          .pt-btn-${page_id} a:focus {
            text-decoration: none;
          }
          .pt-btn-${page_id} a:active {
            text-decoration: none;
          }
          .pt-btn-${page_id} div {
            width: 180px;
            height: 35px;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            border-radius: 4px;
            background: #26abff;
            cursor: pointer;
          }
          .pt-btn-${page_id} div > p {
            margin: 0;
          }
        `
      },
      {
        name: `.pt-btn-${id} div`,
        content: `
          .pt-btn-${id} div {
            ${Object.keys(styles).reduce((pre, css) => `${pre} ${toLine(css)}:${styles[css]};`, '')}
          }
          .pt-btn-${id} div:hover {
            ${hoverBgColor ? `background-color:${hoverBgColor};` : ''}
            ${hoverTextColor ? `color:${hoverTextColor};` : ''}
          }
        `
      },
    ],
    html: `
      <div class="pt-btn-${page_id} pt-btn-${id}">
        <a ${href ? `href=${href} target="_blank"` : ''}>
          <div>${text}</div>
        </a>
      </div>
    `,
  }
  if (align) {
    result.styles.push(
      {
        name: `.pt-btn-${id}`,
        content: `
          .pt-btn-${id} { justify-content: ${align}; }
        `
      }
    )
  }
  if (styles.width) {
    result.styles.push(
      {
        name: `pt-btn-${id} a`,
        content: `
          .pt-btn-${id} a {
            width: ${styles.width};
          }
        `
      }
    )
  }
  return result
}

import { toLine } from "../../../utils/transformCssPropertyName"

export default function staticRender({ config }) {
    const { id, props = {} } = config
    let {
        title = "请输入标题",
        styles = {},
        boxWidth = '100',
        align = 'flex-start'
    } = props
    styles = {
        fontSize: '28px',
        lineHeight: '1.5',
        textAlign: 'center',
        color: '#333',
        width: `${boxWidth}%`,
        ...styles
    }
    title = title.replace(/<p([^>]+)>/g, (match, p1, offset, str) => {
        return `<p${p1.substring(0, p1.length - 1)}margin:0;word-break:break-word${p1[p1.length - 1]}>`
    }).replace(/(<p>)/g, (match, p1, offset, str) => {
        return `<p style="margin:0;word-break:break-word;">`
    })
    const emailTextInnerStyle = Object.keys(styles).map(css => (`${toLine(css)}:${styles[css]};`)).join(' ')
    return {
        styles: [],
        html: `
            <div class="email_title" style="display:flex; width:100%; box-sizing:border-box; justify-content:${align};">
                <div class="email_title_inner" style="${emailTextInnerStyle}">${title}</div>
            </div>
        `
    }
}

import componentMount from "../../lib/componentMount";

import EditContainer from "./EditTitle";
import Component from "./Title";
import BaseStyle from "../../BaseBox/BaseStyle";
import staticRender from "./StaticRender.js";

const _Render = componentMount({
    Component:Component,
    Content:EditContainer,
    Style:BaseStyle,
    text:'标题'
})

export default {
    componentMount:_Render,
    name:'Title',
    text:'标题',
    staticRender
}
import React,{ Component } from "react";
import styled from 'styled-components'
import MyModal from '../Modal'
import { FlexLine,Button } from "../../style";
import {Select, WhiteSpace} from "../index";
import {inject, observer} from "mobx-react";
import RCSelecte from "react-select";

const ModalContainer = styled.div({
    width:'500px',
    height:'200px',
    background:'#fff'
})

const Input = styled.input({
  border:'1px solid #e4e4e4',
  padding:'15px',
  width:'347px',
  height: '40px',
  background:'#fff',
  borderRadius:'4px',
  paddingRight: '30px'
})

@inject('siteStore', 'templateStore','homeStore')
@observer
class CopyTemplateModal extends Component{
    // input = React.createRef()
    state = {
        name: '副本-'+this.props.item.name,
        url: this.props.item.url,
        type: this.props.item.type,
        site_id: this.props.item.site_id
    }

    componentDidMount() {
        this.nameInputSelect()
    }

    nameInputSelect() {
        let input = document.getElementById('select-input')
        if (!input) {
            return setTimeout(this.nameInputSelect, 10)
        }
        input.select()
    }

    render() {
        const {onClose, onSubmi} = this.props

        let _options = []
        this.props.siteStore.sites.forEach(v => {
          if (v.policiesKey) {
            if (this.props.homeStore.hasPolicies(v.policiesKey)) {
              _options.push({
                label: v.name,
                value: v.site_id
              })
            }
          } else {
            _options.push({
              label: v.name,
              value: v.site_id
            })
          }
        });

        return (
            <MyModal visible={true} onClose={onClose}>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        if (!this.state.name) {
                            return
                        }
                        onSubmi({
                            id: this.props.item.id,
                            name: this.state.name,
                            url: this.state.url,
                            type: this.state.type,
                            site_id: this.state.site_id
                        });
                    }}
                >
                    <FlexLine
                      style={{
                        width: '500px',
                        height: '370px',
                        background: '#ffffff'
                      }}
                      column={true}
                    >
                        <p>创建方案副本</p>
                        <Input
                            id='select-input'
                            value={this.state.name}
                            placeholder="方案名称"
                            onChange={(e) => {
                                this.setState({
                                    name: e.target.value
                                })
                            }}
                        />
                        <WhiteSpace />
                        {
                          !this.props.template && <React.Fragment>
                            <Input
                              value={this.state.url}
                              placeholder="URL"
                              onChange={(e) => {
                                this.setState({
                                  url: e.target.value
                                })
                              }}
                            />
                            <WhiteSpace />
                          </React.Fragment>
                        }
                      <div style={{ width: '347px' }}>
                        <RCSelecte
                            defaultValue={this.props.templateStore.templateTypes.filter(it=>it.value===this.state.type)[0]}
                            options={this.props.templateStore.templateTypes}
                            isDisabled
                        />
                        <WhiteSpace />
                      </div>
                      <div style={{ width: '347px' }}>
                        {this.props.template ? null : (
                          <Select
                            defaultValue={this.state.site_id}
                            placeholder="站点"
                            onChange={(data) => {
                              this.setState({
                                site_id: data.value
                              })
                            }}
                            options={_options}
                          />
                        )}
                      </div>
                        <Button 
                            onClick={()=>{
                              if (!this.state.name) {
                                return
                              }
                              onSubmi({
                                id: this.props.item.id,
                                name: this.state.name,
                                url: this.state.url,
                                type: this.state.type,
                                site_id: this.state.site_id
                              });
                            }}
                            disable={!this.state.name}
                            style={{marginTop:'30px',width:'120px'}}
                        >
                           确认
                        </Button>
                    </FlexLine>
                </form>
            </MyModal>     
        );
    }
}

export default CopyTemplateModal
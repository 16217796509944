import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import Percentage from '../EditComponents/Percentage';
import ColAlign from '../EditComponents/ColAlign';
import styled from 'styled-components';
import { Input, WhiteSpace } from '../../components/index';
import { FlexLine } from '../../style';

@inject('createStore')
@observer
class EditRowBaseBox extends Component {

  getWidth = (val) => {
    return val ? parseInt(val) : 100;
  }

  handleRefreshStyle = (key, value) => {
    //更新某个组件，或则楼层样式属性
    const { seletedData, updateDataProps } = this.props.createStore;
    const { props } = seletedData;
    const { styles } = props;
    let updataData = {
      ...styles,
      [key]: value
    };
    updateDataProps('styles', updataData);
  }

  render () {
    const { styles={}} = this.props;
    const { width, justifyContent,minHeight = '100px',height = '' } = styles

    const isPercent = height && height.indexOf('%')>0
    return (
      <div>
        <FlexLine justify='flex-start'>
          <p style={{ fontSize: "14px", marginRight: '10px' }}>最小高度:</p>
          <div style={{ position: 'relative' }}>
            <Input
              type='number'
              name={'minHeight'}
              style={{ width: '120px', margin: '5px', paddingRight: '0' }}
              onChange={(e) => this.handleRefreshStyle('minHeight', `${e.target.value}px`)} 
              value={minHeight ? this.getWidth(minHeight) : ''}
            />
            <SpanText>px</SpanText>
          </div>
        </FlexLine>
        <FlexLine justify='flex-start'>
          <p style={{ fontSize: "14px", marginRight: '10px' }}>固定高度:</p>
          <div style={{ position: 'relative' }}>
            <Input
              type='number'
              name={'height'}
              style={{ width: '120px', margin: '5px', paddingRight: '0' }}
              onChange={(e) => {
                const _height = e.target.value
                if (_height > 0) {
                  this.handleRefreshStyle('height', `${_height}px`)
                  this.handleRefreshStyle('minHeight', ``)
                } else {
                  this.handleRefreshStyle('height', `unset`)
                }

              }}
              value={!isPercent && height ? this.getWidth(height) : ''}
            />
            <SpanText>px</SpanText>
          </div>
        </FlexLine>
        <FlexLine justify='flex-start'>
          <p style={{ fontSize: "14px", marginRight: '10px' }}>高度百分比:</p>
          <div style={{ position: 'relative' }}>
            <Input
              type='number'
              name={'height'}
              style={{ width: '120px', margin: '5px', paddingRight: '0' }}
              onChange={(e) => {
                const _height = e.target.value
                if (_height > 0) {
                  this.handleRefreshStyle('height', `${_height}%`)
                  this.handleRefreshStyle('minHeight', ``)
                } else {
                  this.handleRefreshStyle('height', `unset`)
                }

              }}
              value={isPercent && height ? this.getWidth(height) : ''}
            />
            <SpanText>%</SpanText>
          </div>
        </FlexLine>
        <Percentage percent={this.getWidth(width)} onChange={(value) => this.handleRefreshStyle('width', `${value}%`)} />
        <ColAlign align={justifyContent} onChange={(value) => this.handleRefreshStyle('justifyContent', value)} />
      </div>
    )
  }
}

export default EditRowBaseBox;


const SpanText = styled.div({
  color: '#999',
  fontSize: '14px',
  textAlign: 'center',
  position: 'absolute',
  top: '10px',
  right: '25px'
})
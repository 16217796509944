
//内容占比

import React, { Component,Fragment } from 'react';
import ReactSlider from 'react-slider'
import { ContentTitle, FlexLine } from '../../style';

class Percentage extends Component{

  state = {
    pre_percent: this.props.percent ? this.props.percent : 100,
    percent: this.props.percent ? this.props.percent : 100
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    if (prevState.pre_percent != nextProps.percent) {
      console.log(nextProps.percent);
      return {
        pre_percent: nextProps.percent,
        percent: nextProps.percent,
      }
    } else {
      return null;
    }
  }

  onChange = (value)=>{
    this.setState({
      percent:value
    })
  }

  onAfterChange = ()=>{
    this.props.onChange && this.props.onChange(this.state.percent)
  }
  render() {
    const { percent = 100} = this.props
    return (
      <Fragment>
        <FlexLine justify="space-between" style={{padding:'0 10px',margin:'20px 0'}}>
          <ContentTitle>内容占比:</ContentTitle>
          <ContentTitle>{this.state.percent}%</ContentTitle>
        </FlexLine>
        <div style={{ padding: '0 10px',marginBottom:'20px'}}>
          <ReactSlider
            value={this.state.percent}
            className="horizontal-slider"
            thumbClassName="example-thumb"
            trackClassName="example-track"
            onChange={this.onChange}
            onAfterChange={this.onAfterChange}
            renderThumb={(props, state) => <div {...props}></div>}
          />
        </div>
      </Fragment>
    );
  }
}

export default Percentage
import componentMount from "../../lib/componentMount";

import EditContainer from "./EditPcListText";
import Component from "./PcListText";
import BaseStyle from "../../BaseBox/BaseStyle";

const _Render = componentMount({
    Component:Component,
    Content:EditContainer,
    Style:BaseStyle,
    text:'文本列'
})

export default {
    componentMount:_Render,
    name:'PcListText',
    text:'文本列'
}
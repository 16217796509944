import React from 'react';
import styled from 'styled-components';

const Video = styled.video(({ styles = {}, boxWidth = 100}) => ({
    width: `${boxWidth}%`,
    ...styles
}))

const Container = styled.div(({ styles }) => ({
    display: 'flex',
    width: '100%',
    boxSizing: 'border-box',
    ...styles
}))

function PcVideo ({ videoSrc, boxWidth=100, align, styles, autoPlay = true, loop = true, showControl = false }) {
    if (!videoSrc){
        return <div style={{padding:'30px 0',textAlign:'center'}}>
            请在内容区域编辑视频内容，不可为空
        </div>
    }
    return (
        <Container styles={align ? { justifyContent: align } : {}}>
            <Video
                controls={showControl ? 'controls' : ''}
                autoPlay={autoPlay}
                src={videoSrc}
                playsInline
                muted
                loop={loop}
                styles={styles}
                boxWidth={boxWidth}
            >
                您的浏览器不支持 video 标签。
            </Video>
        </Container>
    )
}

export default PcVideo;
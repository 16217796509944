import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import styled from 'styled-components'
import { FlexLine, Button } from '../style'
import Search from '../components/Main/Search'
import TemplateList from '../components/Main/TemplateList'
import Pagination from '../components/Main/Pagination'

import {
  searchSchemes,
  deleteSchemes,
  renameSchemes,
  copySchemes,
  createSchemes,
  exportScheme,
  getSchemesList
} from "../api";
import Loading from '../components/Loading'
import CreateTemplateModal from '../components/Main/CreateTemplateModal'
import CopyTemplateModal from '../components/Main/CopyTemplateModal'
import DeleteTemplateModal from '../components/Main/DeleteTemplateModal'
import ExportTemplateModal from "../components/Main/ExportTemplateModal";
import {toast, ToastContainer} from "react-toastify";

const Header = styled.div({
  padding:'20px 30px',
  borderBottom:'1px solid #e4e4e4'
})

const ListContainer = styled.div({
  padding:'60px 30px'
})

@inject('homeStore','siteStore')
@observer
class Index extends Component {

  state = {
    search:'',
    list:[],
    page:1,
    per_page:15,
    total:0,
    reNameItem:0,
    onSearch:false,
    modalVisible:false,
    copyModalVisible:false,
    isShowDeleteModal:false,
    isShowExportModal:false,
    deleteItem:{},
    copyItem:{},
    template:''
  }

  componentWillMount(){
    this.props.siteStore.getSitesList().then(() => {
      this.getList();
    });
  }

  getList = ()=>{
    this.setState({
      loading:true
    })
    getSchemesList({
      page:this.state.page,
      per_page:this.state.per_page,
      name:this.state.name,
      type:this.state.type,
      site_id:this.state.site_id
    }).then((arg)=>{
      const { data, meta } = arg
      this.setState({
        list:data,
        total:meta.total,
        loading:false
      })
    }).catch((e)=>{
      this.setState({
        loading:false
      })
      console.log('获取列表失败',e);
    })
  }

  onChange = ({search,type,site_id})=>{
    this.setState({
      search: search,
      type: type,
      site_id: site_id
    },()=>{
      if(!search && this.state.onSearch){
        this.onSearch()
      }
    })
    
  }


  onSearch = (props)=>{
    this.setState({
      loading:true,
      page:1,
      onSearch:this.state.search ? true : false,
      name:props.search,
      type:props.type,
      site_id:props.site_id
    })
    searchSchemes({
      page:1,
      per_page:this.state.per_page,
      name:props.search,
      type:props.type,
      site_id:props.site_id
    }).then((arg)=>{
      const {data,meta} = arg
      this.setState({
        list:data,
        total:meta.total,
        loading:false
      })
    }).catch((e)=>{
      this.setState({
        loading:false
      })
      console.log('获取列表失败',e);
    })
  }

  pageChange = (page)=>{
    this.setState({
      page
    },()=>{
      this.getList()
    })
  }

  deleteItem = (item)=>{
    this.setState({
      deleteItem:item
    })
    this.toggerDeleteModal()
  }

  onDelete = ()=>{
    deleteSchemes({id:this.state.deleteItem.id}).then(()=>{
      this.toggerDeleteModal()
      this.getList()
    }).catch((e)=>{
      console.log('删除失败',e);
    })
  }

  toggerModal = ()=>{
    this.setState(preState =>({
      modalVisible:!preState.modalVisible
    }))
  }

  toggerDeleteModal = ()=>{
    this.setState(preState =>({
      isShowDeleteModal:!preState.isShowDeleteModal
    }))
  }

  toggerCopyModal = ()=>{
    this.setState(preState =>({
      copyModalVisible:!preState.copyModalVisible
    }))
  }

  toggerExportModal = ()=>{
    this.setState(preState =>({
      isShowExportModal:!preState.isShowExportModal
    }))
  }

  creatModalSubmi = ({name,id,url,type,site_id})=>{
    this.toggerModal()
    if(id){
      //重命名
      renameSchemes({
        id:id,
        name:name,
        url:url,
        site_id:site_id
      }).then(()=>{
        this.getList()
      })
    }else{
      //创建方案
      createSchemes({
        name:name,
        url:url,
        type:type,
        site_id:site_id
      }).then((arg)=>{
        this.getList();
        console.log('创建方案', arg);
      })
      // createTemplate({name}).then((arg)=>{
      //   this.setState({
      //     page:1
      //   },()=>{
      //     if(arg.id){
      //       window.location.href = `/create/${arg.id}`
      //       return
      //     }
      //     this.getList()
      //   })
      // })
    }
  }

  copyModalSubmi = ({id,name,url,site_id})=>{
    this.toggerCopyModal()
    copySchemes({
      id:id,
      name:name,
      url:url,
      site_id:site_id
    }).then(()=>{
      this.setState({
        page:1
      },()=>{
        this.getList()
      })
    })
  }
  //打开重命名弹窗
  onRename = (reNameItem)=>{
    this.setState({
      reNameItem:reNameItem
    },()=>{
      this.toggerModal()
    })
  }

  onExport = (reNameItem)=>{
    exportScheme(reNameItem.id).then((res) => {
      if (!res.data){
        toast(`方案内容为空，导出失败`, {
          type: 'error'
        });
        return
      }
      this.setState({
        template: res.data.html_content
      })
      this.toggerExportModal();
    });
  }

  onCopy = (item)=>{
    this.setState({
      copyItem:item
    },()=>{
      this.toggerCopyModal()
    })
  }

  onExportCopy = (res) => {
    if (res) {
      toast("复制成功", {
        type: 'success'
      });
    } else {
      toast("复制失败", {
        type: 'error'
      });
    }
  }

  render() {
    const { list, page, per_page,total } = this.state
    return (
      <FlexLine>
        <div
          style={{
            width: '15%',
            alignSelf: 'flex-start',
            backgroundColor: '#ffffff',
            boxShadow: '#e5e5e5 0 2px 10px 0',
            height: '100vh',
            position: 'relative'
          }}
        >
          <ul style={{ listStyle: 'none', padding: '0' }}>
            {
              this.props.homeStore.hasPolicies('cms.scheme.list') ?  (
                <li
                  style={{
                    margin: "24px 16px",
                    fontSize: "20px",
                    lineHeight: "48px",
                    borderRight: "2px solid #26abff"
                  }}
                >
                  <a href="/" style={{ color: "#26abff" }}>
                    运营方案
                  </a>
                </li>
              ) : null
            }
            {
              this.props.homeStore.hasPolicies('cms.template.search') ? (
                <li style={{ margin: "24px 16px", fontSize: "20px", lineHeight: "48px" }}>
                  <a href="/templates" style={{ color: "#000000" }}>
                    设计模板
                  </a>
                </li>
              ) : null
            }
          </ul>
        </div>
        <div
          style={{
            width: '85%',
            backgroundColor: '#f5f5f5',
            height: '100vh',
            overflowY: 'scroll'
          }}
        >
          <Header>
            <FlexLine justify='space-between'>
              <Button onClick={()=>{
                this.setState({
                  reNameItem:{}
                },()=>{
                  this.toggerModal()
                })
              }}>+ 新建方案</Button>
              <Search onSearch={this.onSearch}/>
            </FlexLine>

          </Header>
          <ListContainer>
            {
              this.state.loading ? <Loading/> :
                <TemplateList
                  list={list}
                  onCopy={this.onCopy}
                  onDelete={this.deleteItem}
                  onRename={this.onRename}
                  onExport={this.onExport}
                />
            }
            {total > per_page &&
            <Pagination
              page={page}
              total={total}
              per_page={per_page}
              onChange={this.pageChange}
            />}
          </ListContainer>
          {this.state.modalVisible &&
          <CreateTemplateModal
            reNameItem={this.state.reNameItem}
            onSubmi={this.creatModalSubmi}
            visible={this.state.modalVisible}
            onClose={this.toggerModal}/>}
          {this.state.copyModalVisible &&
          <CopyTemplateModal
            item={this.state.copyItem}
            onSubmi={this.copyModalSubmi}
            visible={this.state.copyModalVisible}
            onClose={this.toggerCopyModal}/>}
          {this.state.isShowDeleteModal &&
          <DeleteTemplateModal
            name={this.state.deleteItem.name}
            onSubmi={this.onDelete}
            visible={this.state.isShowDeleteModal}
            onClose={this.toggerDeleteModal}/>}
          {this.state.isShowExportModal &&
          <ExportTemplateModal
            template={this.state.template}
            onClose={this.toggerExportModal}
            onCopy={this.onExportCopy}
          />}
          <ToastContainer />
        </div>
      </FlexLine>
    )
  }
}

export default Index

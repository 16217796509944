
//每一个组件元素的操作
import React, { Fragment, Component } from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import PageComponentsMachine from '../../pageComponents/pageComponentsMachine';
import Icon from '../Icon';
import FloorContent from './FloorContent';
import { onContentCopy, onContentPaste } from '../../utils/copy';

@inject(({ createStore, viewStore }) => ({
  seletedComponentId: createStore.seletedComponentId,
  setComponentId: createStore.setComponentId,
  setRowId: createStore.setRowId,
  setFloorId: createStore.setFloorId,
  activeRowData: createStore.activeRowData,
  activeFloorData: createStore.activeFloorData,
  updateRowData: createStore.updateRowData,
  historyFloorData: createStore.historyFloorData,
  isTemplate: createStore.isTemplate,
  setSiteView: viewStore.setSiteView,
  copyContent: createStore.copyContent,
  type: createStore.type
}))
@observer
class ComponentChild extends Component {

  state = {
    isHover: false
  }

  //选中，删除，上/下移动，向上增加
  get OperationButtons() {
    const { data, updateRowData, setComponentId, historyFloorData,setFloorId,activeRowData, setRowId, activeFloorData, type, isTemplate, copyContent } = this.props;
    if (type === 2 && !isTemplate) {
      return []
    }
    const list = [
      {
        tex: <Fragment>
          <Icon icon={'icon-ellypsis'} style={{ fontSize: '16px', fontWeight: '800' }} />
          <OperationText>编辑楼层</OperationText>
        </Fragment>,
        click: () => {
          setFloorId(activeFloorData.id)
        }
      },
      {
        tex: <Fragment>
          <Icon icon={'icon-ellypsis'} style={{ fontSize: '16px', fontWeight: '800' }} />
          <OperationText>编辑分栏</OperationText>
        </Fragment>,
        click: () => {
          setRowId(activeRowData.id, activeFloorData.id)
        }
      },
      {
        tex: <Fragment>
          <OperationText>复制</OperationText>
        </Fragment>,
        click: () => {
          onContentCopy(this.props.data)
        }
      }, {
        tex: <Fragment>
          <OperationText>粘贴</OperationText>
        </Fragment>,
        click: () => {
          onContentPaste(this.props.data, copyContent)
        }
      },
      {
        tex: <Fragment>
          <Icon icon={'icon-arrow-down2'} style={{ fontSize: '16px', fontWeight: '800' }} />
        </Fragment>,
        click: () => {
          const _activeRowData = { ...activeRowData }
          const index = _activeRowData.children.findIndex(comp => comp.id === data.id);
          let inserIndex = '';
          if (index >= _activeRowData.children.length) {
            return
          }
          historyFloorData()
          inserIndex = index + 1;
          const moveCom = _activeRowData.children.splice(index, 1);
          const insertData = moveCom[0];
          inserIndex !== undefined && _activeRowData.children.splice(inserIndex, 0, insertData)
          updateRowData(_activeRowData)
        }
      },
      {
        tex: <Fragment>
          <Icon icon={'icon-arrow-up'} style={{ fontSize: '16px' }} />
        </Fragment>,
        click: () => {
          const _activeRowData = { ...activeRowData }
          const index = _activeRowData.children.findIndex(comp => comp.id === data.id);
          let inserIndex = '';
          if (index <= 0) {
            return
          }
          historyFloorData()
          inserIndex = index - 1;
          const moveCom = _activeRowData.children.splice(index, 1);
          const insertData = moveCom[0];
          inserIndex !== undefined && _activeRowData.children.splice(inserIndex, 0, insertData)
          updateRowData(_activeRowData)
        }
      },
      {
        tex: <Fragment>
          <Icon icon={'icon-delete'} style={{ fontSize: '16px', fontWeight: '800' }} />
        </Fragment>,
        click: () => {
          historyFloorData()
          const _activeRowData = { ...activeRowData }
          const index = _activeRowData.children.findIndex(comp => comp.id === data.id);
          _activeRowData.children.splice(index, 1);
          updateRowData(_activeRowData)
        }
      },
      {
        tex: <Fragment>
          <OperationText>取消</OperationText>
        </Fragment>,
        click: () => {
          setRowId('', '')
        }
      }
    ]
    return list;
  }

  render() {
    const {
      data,
      seletedComponentId,
      setComponentId,
      handleSeletedFloorChild,
      setSiteView,
      activeFloorData,
      activeRowData
    } = this.props

    let _props = { ...data.props } || {}
    _props.boxWidth = 100;
    const { boxWidth = 100, fitContent, align } = data.props
    let positionRight = 0
    let positionLeft = 'unset'
    if (activeFloorData.id && activeRowData.id){
      let index = activeFloorData.children.findIndex(item => item.id === activeRowData.id)
      if (activeFloorData.children.length >= 3 && index <= 2 && index != activeFloorData.children.length -1) {
        positionLeft = 0
        positionRight = 'unset'
      }
    }
    return (
      <div style={{ display: 'flex', width: '100%', justifyContent: align ? align : 'flex-start' }}>
        <Container
          active={data.id === seletedComponentId}
          styles={{
            width: fitContent ? 'fit-content' : `${boxWidth}%`
          }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            handleSeletedFloorChild(data.id)
            setComponentId(data.id)
            if (data.name === 'PcImg' || data.name === 'H5Img' || data.name === 'Img') {
              setSiteView('image')
            }
            if (data.name === 'PcVideo' || data.name === 'H5Video') {
              setSiteView('video')
            }
          }}
        >
          {(!data.name || !data.name.includes('BaseBox')) && (
            <Operation positionLeft={positionLeft} positionRight={positionRight} isShow={data.id === seletedComponentId}>
              {this.OperationButtons.map((item, idx) => {
                const { tex, click } = item;
                return (
                  <OperationButton
                    key={idx}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      click()
                    }}>
                    <OperationButtonInner>{tex}</OperationButtonInner>
                    </OperationButton>
                )
              })}
            </Operation>
          )}
          {/* EmailBaseBox 这里的子组件可能是floor  TODO 这里需要修复!!! */}
          {data.name ? <>
            {data.name.includes('BaseBox') && (
              <>
                {data && <FloorContent floorData={data} />}
                {!data && <div>2333</div>}
              </>
            )}
            {!data.name.includes('BaseBox') && PageComponentsMachine.render(data.name).renderComponent(_props)}
          </> : ''}
        </Container>
      </div>
    );
  }
}

export default ComponentChild


const Container = styled.div(({ styles, active }) => ({
  position: 'relative',
  border: active ? '2px solid #26ABFF' : 'unset',
  ...styles
}))


//楼层操作
const Operation = styled.div({
  width: 'max-content',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  right: props => props.positionRight,
  left: props => props.positionLeft,
  top: '0',
  height: '42px',
  transform: 'translateY(-100%)',
  zIndex: 99,
  backgroundColor: 'rgba(70, 79, 96, .95)',
  borderRadius: '8px',
  boxShadow: '0 0 0 1px rgba(255,255,255,.4), 4px 4px 6px rgba(0,0,0,.5)',
  visibility: props => props.isShow ? 'visible' : 'hidden'
});
const OperationText = styled.div({
  marginLeft: '5px'
})

const OperationButtonInner = styled.div({
  borderRight: '1px solid rgba(255, 255, 255, .3)',
  padding: '2px 10px',
  display: 'flex',
  alignItems: 'center',
  height: '25px',
})
const OperationButton = styled.div({
  cursor: 'pointer',
  padding: '10px 0',
  color: '#fff',
  borderRadius: '8px',
  background: 'unset',
  ":hover": {
    background: 'rgba(0,0,0,0.2)',
    color: '#fff'
  }
})
import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Index from './pages/Index';
import Create from './pages/Create';
import CreateEmailTpl from './pages/CreateEmailTpl';
import Preview from './pages/Preview';
import PreviewEmailTpl from './pages/PreviewEmailTpl';
import AlertPreview from './pages/AlertPreview';
import TemplateView from './pages/TemplateView';
import Translation from './pages/Translation';
import Templates from './pages/Templates';

function AppRouter() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <Index/>
        </Route>
        <Route path="/create/:id">
          <Create/>
        </Route>
        <Route path="/createEmailTpl/:id">
          <CreateEmailTpl/>
        </Route>
        <Route path="/preview">
          <Preview/>
        </Route>
        <Route path="/previewEmailTpl">
          <PreviewEmailTpl/>
        </Route>
        <Route path="/alertPreview">
          <AlertPreview/>
        </Route>
        <Route path="/translation">
          <Translation/>
        </Route>
        <Route path="/templateView/:id">
          <TemplateView/>
        </Route>
        <Route path="/templates">
          <Templates/>
        </Route>
      </Switch>
    </BrowserRouter>
  )
}

export default AppRouter;

import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { ContentTitle } from '../../../style';
import { WhiteSpace } from '../../../components/index';

import Percentage from '../../EditComponents/Percentage';
import ContentAlign from '../../EditComponents/ContentAlign';
import Editor from '../../../components/Editor';

@inject('createStore')
@observer
class EditPcText extends Component {
  state = {
    isAddEditor: false
  }
  text = ''

  componentDidMount() {
    this.init()
  }

  init = () => {
    this.text = this.props.text || '请输入文本'
    this.setState({
      isAddEditor: true
    })
  }

  handleEditorChange = (content) => {
    const { activeRowData, seletedRowId, updateDataProps } = this.props.createStore;
    if (activeRowData && seletedRowId) {//选中楼层子组件，优先更新子组件
      updateDataProps('text', content);
    }
  }

  proportionChange = (value) => {
    const { updateDataProps } = this.props.createStore;
    updateDataProps('boxWidth', value);
  }

  alignChange = (value) => {
    const { updateDataProps } = this.props.createStore;
    updateDataProps('align', value);
  }

  render() {
    const { text = '', boxWidth = 100, align = '' } = this.props;
    return (
      <div>
        <ContentTitle>文本:</ContentTitle>
          <div style={{ height: '550px' }}>
            <Editor
              value={text}
              showFont={false}
              onChange={this.handleEditorChange}
              style={{
                width: "100%",
                height: "400px",
              }}
            />
          </div>
        <WhiteSpace />
        <Percentage percent={boxWidth} onChange={this.proportionChange} />
        <ContentAlign align={align} onChange={this.alignChange} />
      </div>
    )
  }
}

export default EditPcText;
//每个分栏
import React, { Fragment, Component } from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { Modal, Radio } from 'antd';
import pageBasePars from "../../pageComponents/pageBasePars";
import Icon from '../Icon';
import ComponentChild from "./ComponentChild";

import { onContentCopy, onContentPaste } from '../../utils/copy';

@inject(({ createStore }) => ({
  seletedRowId: createStore.seletedRowId,
  setRowId: createStore.setRowId,
  activeRowData: createStore.activeRowData,
  activeFloorData: createStore.activeFloorData,
  setFloorId: createStore.setFloorId,
  updateFloorData: createStore.updateFloorData,
  historyFloorData: createStore.historyFloorData,
  addFloorChild: createStore.addFloorChild,
  addSubFloor: createStore.addSubFloor,
  seletedData: createStore.seletedData,
  isTemplate: createStore.isTemplate,
  copyContent: createStore.copyContent,
  type: createStore.type,
}))
@observer
class RowContent extends Component {

  state = {
    isHover: false,
    isDrag: false,
    isAddSubFloorModalVisible: false,
    subFloorNum: 2
  }

  onAddSubFloorModalOk = () => {
    this.props.addSubFloor({
      colNum: this.state.subFloorNum, // 子分栏数量
      boxId: this.props.rowData.id, // 子分栏的容器的id
      name: 'EmailRowContent'
    });
    this.setState({
      subFloorNum: 2,
      isAddSubFloorModalVisible: false,
    })
  }

  onAddSubFloorModalCancel = () => {
    this.setState({
      subFloorNum: 2,
      isAddSubFloorModalVisible: false,
    })
  }

  get HoverOperationButtons () {
    const { setRowId, rowData, floorData } = this.props;
    return [
      {
        tex: <Fragment>
          <Icon icon={'icon-ellypsis'} style={{ fontSize: '16px', fontWeight: '800' }} />
          <OperationText>编辑分栏</OperationText>
        </Fragment>,
        click: (floor) => {
          setRowId(rowData.id, floorData.id)
        }
      }
    ]
  }

  //选中，删除，上/下移动，向上增加
  get OperationButtons () {
    const { updateFloorData, setRowId, historyFloorData, setFloorId, floorData, copyContent, activeFloorData } = this.props;

    const list = [{
      tex: <Fragment>
        <Icon icon={'icon-ellypsis'} style={{ fontSize: '16px', fontWeight: '800' }} />
        <OperationText>编辑楼层</OperationText>
      </Fragment>,
      click: () => {
        setFloorId(activeFloorData.id)
      }
    },
      {
        tex: <Fragment>
          <OperationText>复制</OperationText>
        </Fragment>,
        click: (row) => {
          onContentCopy(row)
        }
      }, {
        tex: <Fragment>
          <OperationText>粘贴</OperationText>
        </Fragment>,
        click: (row) => {
          onContentPaste(row, copyContent)
        }
      },
      {
        tex: <Fragment>
          <OperationText>左移</OperationText>
        </Fragment>,
        click: (row) => {
          const _floorData = { ...activeFloorData }
          const index = _floorData.children.findIndex(comp => comp.id === row.id);
          let inserIndex = '';
          if (index <= 0) {
            return
          }
          historyFloorData()
          inserIndex = index - 1;
          const moveCom = _floorData.children.splice(index, 1);
          const insertData = moveCom[0];
          inserIndex !== undefined && _floorData.children.splice(inserIndex, 0, insertData)
          updateFloorData(_floorData)
        }
      }, {
        tex: <Fragment>
          <OperationText>右移</OperationText>
        </Fragment>,
        click: (row) => {
          const _floorData = { ...activeFloorData }
          const index = _floorData.children.findIndex(comp => comp.id === row.id);
          let inserIndex = '';
          if (index >= _floorData.children.length) {
            return
          }
          historyFloorData()
          inserIndex = index + 1;
          const moveCom = _floorData.children.splice(index, 1);
          const insertData = moveCom[0];
          inserIndex !== undefined && _floorData.children.splice(inserIndex, 0, insertData)

          updateFloorData(_floorData)
        }
      }, {
        tex: <Fragment>
          <OperationText>ADD</OperationText>
        </Fragment>,
        click: (row) => {
          historyFloorData()
          let _floorData = { ...activeFloorData }
          let length = _floorData.children.length
          let width = `${parseFloat(1 / (length + 1)) * 100}%`
          _floorData.children.forEach(element => {
            element.props.styles.width = width
          });
          const index = _floorData.children.findIndex(comp => comp.id === row.id);
          _floorData.children.splice(index + 1, 0, {
            name: row.name,
            children: [],
            id: Math.round(Math.random() * 10000000000),
            props: {
              styles: {
                width: width,
                minHeight: '100px',
              }
            }
          })

          updateFloorData(_floorData)

        }
      },
      {
        tex: <Fragment>
          <Icon icon={'icon-delete'} style={{ fontSize: '16px', fontWeight: '800' }} />
        </Fragment>,
        click: (rowData) => {
          historyFloorData()
          const _floorData = { ...floorData }
          const index = _floorData.children.findIndex(row => row.id === rowData.id);
          _floorData.children.splice(index, 1);
          _floorData.children.map(row => row.props.styles.width = `${1 / _floorData.children.length * 100}%`)
          updateFloorData(_floorData)
        }
      },
      {
        tex: <Fragment>
          <Icon icon={'icon-plus'} style={{ fontSize: '16px', fontWeight: '800' }} />
        </Fragment>,
        click: () => {
          this.setState({
            isAddSubFloorModalVisible: true,
          })
        }
      }, 
      {
        tex: <Fragment>
          <OperationText>取消</OperationText>
        </Fragment>,
        click: () => {
          setRowId('', '')
        }
      }
    ]

    return list;
  }


  handleSeletedFloorChild = (id, floorId) => {
    if (!id) return;
    const { setRowId } = this.props;
    setRowId(id, floorId);
  }

  onMouseEnter = () => {
    this.setState({
      isHover: true
    })
  }

  onMouseLeave = () => {
    this.setState({
      isHover: false
    })
  }

  render() {
    const {
      rowData,
      floorData,
      addFloorChild,
      seletedData,
      activeFloorData
    } = this.props;
    const { id, props, children, name } = rowData;

    const RowContent = pageBasePars.render(name);
    let border = 'unset'
    if (children.length <= 0) {
      border = '2px dashed #efefef'
    }
    return (
      <>
        <div
          style={{
            height: props.styles.height,
            width: props.styles.width,
            flexShrink: 0,
            position: 'relative',
            boxShadow: this.state.isDrag ? '0px 0px 5px 5px rgba(38, 171, 255, 0.5)' : 'unset',
            border: this.state.isDrag ? 'unset' : id === seletedData.id ? '2px solid #F69000' : border,
          }}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          onDragOver={
            () => {
              this.setState({
                isDrag: true
              })
            }
          }
          onDragLeave={
            () => {
              this.setState({
                isDrag: false
              })
            }
          }
          onDrop={(e) => {
            e.preventDefault();
            e.stopPropagation();
            //如果楼层不为空，这返回
            //如果楼层 的child
            var dragComponentName = e.dataTransfer.getData("dragComponentName");
            console.log('dragComponentName', dragComponentName)
            //pc title paddingBottom, 默认0
            addFloorChild({
              floorId: floorData.id,
              itemId: id,
              name: dragComponentName
            })
            this.setState({
              isDrag: false
            })
            return false;
          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            this.handleSeletedFloorChild(id, floorData.id)
          }}
        >
          <RowContent
            {...props}
            styles={{ height:'100%',...props.styles, width: '100%' }}
          >
            <Operation
              isShow={(this.state.isHover) && (id !== seletedData.id) && activeFloorData.id != floorData.id }
            >
              {this.HoverOperationButtons.map((item, idx) => {
                const { tex, click } = item;
                return (
                  <OperationButton
                    key={idx}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation(); 
                      click(rowData)}}>
                    <OperationButtonInner>
                      {tex}
                    </OperationButtonInner>
                  </OperationButton>
                )
              })}
            </Operation>

            <Operation isShow={id === seletedData.id}>
              <Fragment>
                {this.OperationButtons.map((item, idx) => {
                  const { tex, click } = item;
                  return (
                    <OperationButton
                      key={idx}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        click(rowData)
                      }}>
                      <OperationButtonInner>
                        {tex}
                      </OperationButtonInner>
                    </OperationButton>
                  )
                })}
              </Fragment>
            </Operation>
            {
              children.map((arg, key) => (
                <ComponentChild
                  key={key}
                  data={arg}
                  handleSeletedFloorChild={() => {
                    this.handleSeletedFloorChild(id, floorData.id)
                  }} />))
            }
          </RowContent>
        </div>
        <Modal
          centered
          getContainer="body"
          title="添加子分栏"
          okText="确认"
          cancelText="取消"
          visible={this.state.isAddSubFloorModalVisible}
          onOk={this.onAddSubFloorModalOk}
          onCancel={this.onAddSubFloorModalCancel}
        >
          <p>请选择分栏数量：</p>
          <Radio.Group
            name="radiogroup"
            value={this.state.subFloorNum}
            onChange={e => this.setState({ subFloorNum: e.target.value })}
          >
            <Radio value={2}>2</Radio>
            <Radio value={3}>3</Radio>
          </Radio.Group>
        </Modal>
      </>
    );
  }
}

export default RowContent

//楼层子组件
const RowChild = styled.div({
  border: props => props.isDrag ? 'unset' : '1px dashed #ddd',
  minHeight: '100px',
  borderColor: props => props.active ? 'orange' : '#ddd'
})
const Operation = styled.div({
  width: 'max-content',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  left: 0,
  top: '0',
  height: '42px',
  transform: 'translateY(-100%)',
  zIndex: 99,
  backgroundColor: 'rgba(70, 79, 96, .95)',
  borderRadius: '8px',
  boxShadow: '0 0 0 1px rgba(255,255,255,.4), 4px 4px 6px rgba(0,0,0,.5)',
  visibility: props => props.isShow ? 'visible' : 'hidden'
});
const OperationText = styled.div({
  marginLeft: '5px'
})

const OperationButtonInner = styled.div({
  borderRight: '1px solid rgba(255, 255, 255, .3)',
  padding: '2px 10px',
  display: 'flex',
  alignItems: 'center',
  height: '25px',
})
const OperationButton = styled.div({
  cursor: 'pointer',
  padding: '10px 0',
  color: '#fff',
  borderRadius: '8px',
  background: 'unset',
  ":hover": {
    background: 'rgba(0,0,0,0.2)',
    color: '#fff'
  }
})
import React, { Component, Fragment } from 'react';
import { ContentTitle, FlexLine, Input, TextArea, Button } from '../../../style';
import { inject, observer } from 'mobx-react';
import Percentage from '../../EditComponents/Percentage';
import ContentAlign from '../../EditComponents/ContentAlign';
import Icon from '../../../components/Icon';
import styled from 'styled-components';
@inject('createStore', 'viewStore')
@observer
class EditImg extends Component {
    changeBoxWidth = (val) => {
        const { updateDataProps } = this.props.createStore;
        updateDataProps('boxWidth', val);
    }
    cleanImg = () => {
        const { updateDataProps } = this.props.createStore;
        updateDataProps('imgSrc', '');
        updateDataProps('imgName', '');
    }
    changeHref = (e) => {
        const { updateDataProps } = this.props.createStore;
        updateDataProps('href', e.target.value);
    }
    onImgSizeChange = (e, attr) => {
        const { updateDataProps } = this.props.createStore;
        updateDataProps(attr, e.target.value ? e.target.value + 'px' : '');
    }

    getNumber = (val) => {
        return val ? parseInt(val) : '';
    }

    alignChange = (value) => {
        const { updateDataProps } = this.props.createStore;
        updateDataProps('align', value);
    }

    selectImg = () => {
        this.props.viewStore.setSiteView('image')
    }

    changeImg = (e) => {
        const { updateDataProps } = this.props.createStore;
        updateDataProps('imgSrc', e.target.value);
    }

    render() {
        const { boxWidth = '100', imgName = '', imgSrc = '', href = '', align, imgH, imgW } = this.props;
        let imgSrcSplit = imgSrc ? imgSrc.split(".") : ''
        const parent = this.props.createStore.getParent(this.props.id)
        const parentFlexDirection = parent.props.styles.flexDirection

        return (
            <Fragment>
                <FlexLine justify="flex-start" style={{ marginBottom: '20px' }}>
                    <ContentTitle>图片：</ContentTitle>
                    <SelectImg onClick={this.selectImg} >选择图片</SelectImg>
                </FlexLine>
                <ContentTitle>图片地址：</ContentTitle>
                <div style={{ paddingRight: '10px' }}>
                    <TextArea
                        value={`${imgSrc}`}
                        type='text'
                        placeholder='输入图片地址'
                        style={{ width: '100%', boxSizing: 'border-box' }}
                        onChange={this.changeImg}
                    />
                </div>
                {imgSrc &&
                    <FlexLine
                        justify="flex-start"
                        style={{ marginBottom: '40px', marginTop: '10px' }}
                    >
                        <div onClick={this.cleanImg} style={{ cursor: 'pointer' }}>
                            <Icon icon="icon-delete" />
                        </div>
                        <div style={{ marginLeft: '10px', color: '#26ABFF' }}>{imgName ? `${imgName}.${imgSrcSplit[imgSrcSplit.length - 1]}` : imgSrc}</div>
                    </FlexLine>}
                <ContentTitle>跳转链接：</ContentTitle>
                <div style={{ paddingRight: '10px' }}>
                    <TextArea
                        value={`${href}`}
                        type='text'
                        placeholder='输入图片跳转跳转链'
                        style={{ width: '100%', boxSizing: 'border-box' }}
                        onChange={this.changeHref}
                    />
                </div>
                {parentFlexDirection !== 'row' && <Percentage percent={boxWidth} onChange={this.changeBoxWidth} />}
                {parentFlexDirection === 'row' && (
                    <>
                        <FlexLine
                            justify="flex-start"
                            style={{ marginBottom: '40px', marginTop: '10px' }}
                        >
                            <div style={{ flex: 'none' }}>图片高度：</div>
                            <Input value={this.getNumber(imgH)} placeholder="输入高度(单位：像素)" onChange={e => this.onImgSizeChange(e, 'imgH')} />
                        </FlexLine>
                        <FlexLine
                            justify="flex-start"
                            style={{ marginBottom: '40px', marginTop: '10px' }}
                        >
                            <div style={{ flex: 'none' }}>图片宽度：</div>
                            <Input value={this.getNumber(imgW)} placeholder="输入宽度(单位：像素)" onChange={e => this.onImgSizeChange(e, 'imgW')} />
                        </FlexLine>
                    </>
                )}
                <ContentAlign align={align} onChange={this.alignChange} />
            </Fragment>
        )
    }
}

export default EditImg;

const SelectImg = styled.div({
    width: '100px',
    padding: '5px 0',
    textAlign: 'center',
    fontSize: '14px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    cursor: 'pointer',
    marginLeft: '10px'
})
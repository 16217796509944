import React,{ Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {inject, observer} from "mobx-react";

const Container = styled.table({
    width:'100%'
})

const TableRH = styled.tbody({
    background:'#f2f2f2'
})
const TableR = styled.tr({

})

const TableD = styled.td({
    padding:'10px'
})
const TableH = styled.td({
    padding:'10px'
})

const ModeBtn = styled.span({
    color:'#26ABFF',
    fontSize:'16px',
    padding:'0 10px',
    cursor:'pointer'
})

@inject('siteStore', 'templateStore')
@observer
class TemplateList extends Component{
    render() {
      const { list, onDelete, onRename, onCopy, onExport, template} = this.props
        if(list.length < 1){
            return ''
        }
        return template ? (
            <React.Fragment>
                <Container border='1'>
                    <TableRH>
                        <TableH>序号</TableH>
                        <TableH>ID</TableH>
                        <TableH>模版名称</TableH>
                        <TableH>类型</TableH>
                        <TableH>状态</TableH>
                        <TableH>最近一次修改时间</TableH>
                        <TableH>操作人</TableH>
                        <TableH>操作</TableH>
                    </TableRH>
                    {
                        list.map((item,index)=>(
                          <TableR key={index}>
                            <TableD>{index + 1}</TableD>
                            <TableD>{item.id}</TableD>
                            <TableD>{item.name}</TableD>
                            <TableD>{this.props.templateStore.getTypeName(item.type)}</TableD>
                            <TableD>{this.props.templateStore.getStatusName(item.status)}</TableD>
                            <TableD>{item.update_time}</TableD>
                            <TableD>{item.operator_name}</TableD>
                            <TableD>
                              <Link
                                onClick={e => {
                                  // const url = !template ? `/${this.props.templateStore.isEmailTpl(item.type) ? 'createEmailTpl' : 'create'}/${item.id}?flag=template` : `/${this.props.templateStore.isEmailTpl(item.type) ? 'createEmailTpl' : 'create'}/${item.id}?user=admin&flag=template`
                                  const url = !template
                                    ? `/${this.props.templateStore.isEmailTpl(item.type) ? "createEmailTpl" : "create"}/${item.id}?flag=template`
                                    : `/${this.props.templateStore.isEmailTpl(item.type) ? "createEmailTpl" : "create"}/${item.id}?user=admin&flag=template`;
                                  window.open(url, '_blank')
                                  e.preventDefault()
                                  return false
                                }}
                              >
                                <ModeBtn>编辑</ModeBtn>
                              </Link>
                              <ModeBtn onClick={()=>{onCopy(item)}}>复制</ModeBtn>
                              <ModeBtn onClick={()=>{onRename(item)}}>重命名</ModeBtn>
                              <ModeBtn onClick={()=>{onDelete(item)}}>删除</ModeBtn>
                            </TableD>
                          </TableR>
                        ))
                    }
                </Container>
            </React.Fragment>
        ) : (
          <React.Fragment>
            <Container border='1'>
              <TableRH>
                <TableH>序号</TableH>
                <TableH>ID</TableH>
                <TableH>方案名称</TableH>
                <TableH>页面URL</TableH>
                <TableH>类型</TableH>
                <TableH>站点</TableH>
                <TableH>最近一次修改时间</TableH>
                <TableH>操作人</TableH>
                <TableH>操作</TableH>
              </TableRH>
              {
                list.map((item,index)=>(
                  <TableR key={index}>
                    <TableD>{index + 1}</TableD>
                    <TableD>{item.id}</TableD>
                    <TableD>{item.name}</TableD>
                    <TableD><ModeBtn>{item.url}</ModeBtn></TableD>
                    <TableD>{this.props.templateStore.getTypeName(item.type)}</TableD>
                    <TableD>{this.props.siteStore.getSiteName(item.site_id)}</TableD>
                    <TableD>{item.update_time}</TableD>
                    <TableD>{item.operator_name}</TableD>
                    <TableD>
                      {!this.props.templateStore.isEmailTpl(item.type) && (
                        <Link
                          onClick={(e) => {
                            window.open(`/create/${item.id}?flag=${item.site_id}`, '_blank')
                            e.preventDefault()
                            return false
                          }}
                        >
                          <ModeBtn>编辑</ModeBtn>
                        </Link>
                      )}
                      {this.props.templateStore.isEmailTpl(item.type) && (
                        <Link
                          onClick={e => {
                            window.open(`/createEmailTpl/${item.id}?flag=${item.site_id}`, '_blank')
                            e.preventDefault()
                            return false
                          }}
                        >
                          <ModeBtn>编辑</ModeBtn>
                        </Link>
                      )}
                      <ModeBtn onClick={()=>{onCopy(item)}}>复制</ModeBtn>
                      <ModeBtn onClick={()=>{onExport(item)}}>导出</ModeBtn>
                      <ModeBtn onClick={()=>{onRename(item)}}>重命名</ModeBtn>
                      <ModeBtn onClick={()=>{onDelete(item)}}>删除</ModeBtn>
                    </TableD>
                  </TableR>
                ))
              }
            </Container>
          </React.Fragment>
        );
    }
}

export default TemplateList
//H5 标题
import React from 'react';
import styled from 'styled-components';

const Div = styled.div(({ boxWidth, styles})=>{
    return {
        fontSize: '18px',
        lineHeight:'1.35',
        textAlign: 'center',
        color: '#333',
        ...styles
    }
})

const Container = styled.div(({ styles }) => ({
    display: 'flex',
    width: '100%',
    boxSizing: 'border-box',
    ...styles
}))

function getUnderLineStyles (underLine = {}) {
    const { width = 50, color = "#000000", position = 'bottom', height = 2 } = underLine;
    let absolute = {
        left: '-100%',
        right: '-100%',
        bottom: 0
    }
    if (position === 'top') {
        absolute = {
            left: '-100%',
            right: '-100%',
            top: 0
        }
    }

    if (position === 'left') {
        absolute = {
            left: `-${Number(width ? width : 0) + 10}px`,
            bottom: 0,
            top: 0
        }
    }

    if (position === 'right') {
        absolute = {
            right: `-${Number(width ? width : 0) + 10}px`,
            bottom: 0,
            top: 0
        }
    }

    return {
        content: "''",
        width: `${width}px`,
        height: `${height}px`,
        background: color,
        position: 'absolute',
        ...absolute,
        margin: 'auto'
    }
}


function H5Title ({
    title = "请输入标题",
    fitContent = false,
    styles = {},
    boxWidth = '100',
    align = '',
    one_underLine = {},
    is_one_underLine = false,
    two_underLine = {},
    is_two_underLine = false,
}) {
    return (
        <Container styles={align ? { justifyContent: align } : {}}>
            <Div
                styles={{
                    ...styles,
                    position: 'relative',
                    width: fitContent ? 'fit-content' : `${boxWidth}%`,
                    '&::after': is_one_underLine ? getUnderLineStyles(one_underLine) : {},
                    '&::before': is_two_underLine ? getUnderLineStyles(two_underLine) : {}
                }}
                dangerouslySetInnerHTML={{ __html: title }}
            ></Div>
        </Container>
    )
}
export default H5Title;
const defItem = {
  imgSrc: 'https://df5apg8r0m634.cloudfront.net/images/5610fe3aa56a462b8e575a9eb2dee686.png',
  name: 'User Name1',
  text: 'It is a long established fact that a reader will be distrvacted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal,It is a long established fact that a reader will be distrvacted by the readable content of a page when looking at its layout.',
  href: ''
}
const defList = [defItem, defItem, defItem, defItem, defItem, defItem]
const pageCls = 'user-show-page';
const wrapCls = 'user-show-swiper';

export default function staticRender ({ config, page_id = 0 }) {
  let { id, props = {} } = config
  id = `${page_id}-${id}`
  let { list = defList, autoPlay, cardBgColor = '#fff', color } = props

  const result = {
    styles: [
      {
        name: `.pt-user-show-${page_id}`,
        content: `
          .pt-user-show-${page_id} {
            position: relative;
            overflow: hidden;
            padding-bottom: 0px;
            height: 0px;
          }
          .pt-user-show-${page_id}.show {
            padding-bottom: 40px;
            height: auto;
          }
          .pt-user-show-${page_id} .${pageCls} {
            left: 50%;
            padding-top: 20px;
            transform: translate3d(-50%, 0, 0) !important;
          }
          .pt-user-show-${page_id} .${pageCls} > span {
            width: 10px;
            height: 10px;
            margin-right: 16px;
          }
          .pt-user-show-${page_id} .${pageCls} > span:focus {
            outline: none;
          }
          .pt-user-show-${page_id} .${wrapCls} {
            width: 100%;
            max-width: 1200px;
          }
          .pt-user-show-${page_id} .user-show-item.is-not-swiper {
            display: block;
            flex: 1;
            margin-right: 20px;
          }
          .pt-user-show-${page_id} .user-show-item-flex-box {
            border-radius: 8px;
            border: 1px solid rgba(109, 109, 109, 1);
            display: flex;
            align-items: center;
            height: 220px;
            box-sizing: border-box;
            padding: 19px 28px 29px 11px;
            margin-left: 1px;
            color: #333;
            text-decoration: none;
          }
          .pt-user-show-${page_id} .user-show-item-flex-box:hover {
            color: #333;
          }
          .pt-user-show-${page_id} .user-show-item-flex-box:focus {
            color: #333;
            text-decoration: none;
            outline: none;
            outline-offset: 0;
          }
          .pt-user-show-${page_id} .user-show-item-left {
            text-align: center;
            width: 100px;
            flex-shrink: 0;
            align-self: flex-start;
          }
          .pt-user-show-${page_id} .user-show-item-img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
          }
          .pt-user-show-${page_id} .user-show-item-name {
            color: #000;
            font-size: 14px;
            line-height: 1;
            padding-top: 16px;
            overflow: hidden;
            font-family: Roboto-Regular;
          }
          .pt-user-show-${page_id} .user-show-item-right {
            height: 100%;
            overflow: hidden;
            text-align: left;
            text-overflow: ellipsis;
            line-height: 1.5;
            margin-left: 26px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 7;
            font-family: Robote-Regular;
          }
        `
      },
      {
        name: `.pt-user-show-${id} .user-show-item-flex-box`,
        content: `
          .pt-user-show-${id} .user-show-item-flex-box {
            background-color: ${cardBgColor};
          }
          ${color ? `
            .pt-user-show-${id} .user-show-item-name {
              color: ${color};
            }
            .pt-user-show-${id} .user-show-item-right {
              color: ${color};
            }
          ` : ''}
        `
      }
    ],
    html: `
      <link rel="stylesheet" href="https://df5apg8r0m634.cloudfront.net/react/swiper.css"></link>
      <div class="pt-user-show-${page_id} ${list.length <= 3 ? 'show' : ''} pt-user-show-${id}">
        <div class="swiper-container ${wrapCls}">
          <div class="swiper-wrapper">
            ${list.map(item => {
      const { text, name, imgSrc, href } = item
      return `
                <div class="user-show-item ${list.length <= 3 ? 'is-not-swiper' : ''} swiper-slide">
                  <a class="user-show-item-flex-box" ${href ? `href="${href}" target="_blank"` : ''}>
                    <div class="user-show-item-left">
                      <img class="user-show-item-img" src="${imgSrc || defItem.imgSrc}" />
                      <div class="user-show-item-name"><b>${name || defItem.name}</b></div>
                    </div>
                    <div class="user-show-item-right">${text || defItem.text}</div>
                  </a>
                </div>
              `
    }).join(' ')}
          </div>
        </div>
        <div class="swiper-pagination ${pageCls}"></div>
      </div>
      ${list.length > 3 ? `
        <script>
          (function(){
            const script = document.createElement('script')
            script.onload = function(){
              new Swiper('.pt-user-show-${id} .${wrapCls}', {
                loop: true,
                autoplay: ${autoPlay ? `{ delay: 3000, stopOnLastSlide: false, disableOnInteraction: false }` : 'false'},
                slidesPerView: 3,
                spaceBetween: 20,
                pagination: {
                  el: ".pt-user-show-${id} .${pageCls}",
                  clickable: true
                }
              })
              document.querySelector('.pt-user-show-${id}').classList.add('show')
            }
            script.src = 'https://df5apg8r0m634.cloudfront.net/react/swiper.js'
            document.body.appendChild(script)
          })()
        </script>
      ` : ''}
    `,
  }
  return result
}

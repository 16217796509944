import { toLine } from '../../../utils/transformCssPropertyName'

export default function staticRender ({ config, page_id = 0 }) {
  let { id, props = {} } = config
  id = `${page_id}-${id}`
  const {
    price,
    pre_price,
    product_name,
    href,
    btn_size = 'md',
    btn_color = '#000',
    btn_text = 'Shop Now >',
    imgSrc = "https://df5apg8r0m634.cloudfront.net/images/2023/0428/10f199161ca9a9605534e4211763c1af.jpg",
    styles = {},
    product_style = '',
    isShowPrice = true,
    isShowName = true,
    isShowBtn = true,
    isBorderBtn = false,
  } = props

  let btnStyle = {
    width: '220px',
    lineHeight: '52px',
    fontSize: '20px',
    backgroundColor: isBorderBtn ? '#fff' : btn_color,
    color: !isBorderBtn ? '#fff' : btn_color,
    border: isBorderBtn ? `1px solid ${btn_color}` : 'unset'
  }
  if (btn_size === 'md') {
    btnStyle = {
      ...btnStyle,
      width: '192px',
      lineHeight: '40px',
      fontSize: '18px'
    }
  }
  if (btn_size === 'ms') {
    btnStyle = {
      ...btnStyle,
      width: 'unset',
      lineHeight: 'unset',
      fontSize: '16px',
      padding: '5px 15px'
    }
  }
  if (btn_size === 'auto') {
    btnStyle = {
      ...btnStyle,
      width: 'unset',
      lineHeight: 'unset',
      fontSize: '16px',
      padding: '10px 40px'
    }
  }

  const btnStyleStr = Object.keys(btnStyle)
    .map(css => (`${toLine(css)}:${btnStyle[css]};`))
    .join(' ')
  const aStyle = `text-decoration:none; display:inline-block; cursor:${href ? 'pointer' : 'default'}; color:${styles.color || 'unset'}`

  const result = {
    styles: [],
    html: `
      <div style="box-sizing:border-box; text-align:center;padding:30px 10px;width:100%;">
        <a style="${aStyle}" href="${href ? href : ''}" target="_blank"><img style="width:100%;min-height:70px;" src="${imgSrc}"/></a>
        ${isShowPrice ? `<div>
            ${pre_price ? `<s style="color:#666;font-size:16px;margin-right:5px;">${pre_price}</s>` : ``}
            ${price ? `<span style="color:#333;font-size:16px;font-weight:700;">${price}</span>` : ``}
          </div>` : ''
      }
        ${product_name && isShowName ? `<div style="color:#333;font-size:16px;margin-top:10px;">${product_style}<span style="font-weight:700;">${product_name}</span></div>` : ''}
        ${isShowBtn ? `<a style="${aStyle}" href="${href ? href : ''}" target="_blank">
              <div style="display: inline-block;border-radius:4px;margin-top:10px;text-align:center;border-radius:4px;${btnStyleStr}">${btn_text}</div>
            </a>` : ''
      }
      </div>
    `,
  }

  return result
}

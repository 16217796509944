import componentMount from "../../lib/componentMount";

import EditContainer from "./EditH5Video";
import Component from "./H5Video";
import BaseStyle from "../../BaseBox/BaseStyle";
import staticRender from "./StaticRender.js";

const _Render = componentMount({
    Component:Component,
    Content:EditContainer,
    Style:BaseStyle,
    text:'视频'
})

export default {
    componentMount:_Render,
    name:'H5Video',
    text:'视频',
    staticRender
}
import H5Title from './H5Title'
import H5Text from "./H5Text";
import H5Img from "./H5Img";
import H5IconColum from "./H5IconColum";
import H5IconRow from "./H5IconRow";
import H5SwiperImg from "./H5SwiperImg";
import H5UserShow from "./H5UserShow";
import H5Rule from "./H5Rule";
import H5CouponForm from "./H5CouponForm";
import H5Icon3Row from "./H5Icon3Row";
import H5Button from "./H5Button";
import H5Video from "./H5Video";
import H5CodeContainer from "./H5CodeContainer";
import H5AlertCoupon from "./H5AlertCoupon";
import H5Time from "./H5Time";
import H5Product from "./H5Product";


import H5Alert from "./H5Alert";
import H5BaseBox from "./H5BaseBox";
import H5RowContent from "./H5RowContent";
import H5Swiper from "./H5Swiper";
import H5SwiperItem from "./H5SwiperItem";

export default [
    H5BaseBox,
    H5RowContent,
    H5Title,
    H5Text,
    H5Img,
    H5IconColum,
    H5IconRow,
    H5SwiperImg,
    H5UserShow,
    H5Rule,
    H5CouponForm,
    H5Icon3Row,
    H5Button,
    H5Video,
    H5AlertCoupon,
    H5CodeContainer,
    H5Time,
    H5Alert,
    H5BaseBox,
    H5RowContent,
    H5Swiper,
    H5SwiperItem,
    H5Product
]
import componentMount from "../../lib/componentMount";

import EditContainer from "./EditPcSwiperImg";
import Component from "./PcSwiperImg";
import EditBaseBox from "./EditPcSwiperImgStyles";
import staticRender from "./StaticRender.js";

const _Render = componentMount({
    Component:Component,
    Content:EditContainer,
    Style:EditBaseBox,
    text:'轮播图'
})

export default {
    componentMount:_Render,
    name:'PcSwiperImg',
    text:'轮播图',
    staticRender
}
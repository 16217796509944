import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import ContentAlign from '../../EditComponents/ContentAlign';
import ColAlign from '../../EditComponents/ColAlign';
import styled from 'styled-components';
import { CheckBox, Input, WhiteSpace } from '../../../components/index';
import { FlexLine } from '../../../style';

@inject('createStore')
@observer
class EditPCAlert extends Component {

  getWidth = (val) => {
    return val ? parseInt(val) : 100;
  }

  handleRefreshStyle = (key, value) => {
    //更新某个组件，或则楼层样式属性
    const { seletedData, updateDataProps } = this.props.createStore;
    const { props } = seletedData;
    const { styles } = props;
    let updataData = {
      ...styles,
      [key]: value
    };
    updateDataProps('styles', updataData);
  }

  render () {
    const {  updateDataProps } = this.props.createStore;
    const { styles = {}, isHide = false} = this.props;
    const { justifyContent, alignItems, height = '',width='' } = styles

    return (
      <div>
        <FlexLine justify="flex-start">
          <div style={{ 'width': '15px', marginRight: '10px' }} >
            <CheckBox
              checked={isHide}
              onChange={(e) => {
                updateDataProps('isHide', e.target.checked);
              }} />
          </div>
          <p style={{ fontSize: "14px", marginRight: '10px' }}>初始化的时候是否隐藏:</p>
        </FlexLine>
        <FlexLine justify='flex-start'>
          <p style={{ fontSize: "14px", marginRight: '10px' }}>固定宽度:</p>
          <div style={{ position: 'relative' }}>
            <Input
              type='number'
              name={'width'}
              style={{ width: '120px', margin: '5px', paddingRight: '0' }}
              onChange={(e) => {
                const _width = e.target.value
                if (_width > 0) {
                  this.handleRefreshStyle('width', `${_width}px`)
                } else {
                  this.handleRefreshStyle('width', `unset`)
                }

              }}
              value={width ? this.getWidth(width) : ''}
            />
            <SpanText>px</SpanText>
          </div>
        </FlexLine>
        <FlexLine justify='flex-start'>
          <p style={{ fontSize: "14px", marginRight: '10px' }}>固定高度:</p>
          <div style={{ position: 'relative' }}>
            <Input
              type='number'
              name={'height'}
              style={{ width: '120px', margin: '5px', paddingRight: '0' }}
              onChange={(e) => {
                const _height = e.target.value
                if (_height > 0) {
                  this.handleRefreshStyle('height', `${_height}px`)
                  this.handleRefreshStyle('minHeight', `0px`)
                } else {
                  this.handleRefreshStyle('height', `unset`)
                }

              }}
              value={height ? this.getWidth(height) : ''}
            />
            <SpanText>px</SpanText>
          </div>
        </FlexLine>
        <ColAlign align={alignItems} onChange={(value) => this.handleRefreshStyle('alignItems', value)} />
        <ContentAlign isShowSpace={true} align={justifyContent} onChange={(value) => this.handleRefreshStyle('justifyContent', value)} />
      </div>
    )
  }
}

export default EditPCAlert;


const SpanText = styled.div({
  color: '#999',
  fontSize: '14px',
  textAlign: 'center',
  position: 'absolute',
  top: '10px',
  right: '25px'
})
import { toLine } from '../../../utils/transformCssPropertyName'

export default function staticRender ({ config, page_id = 0 }) {
  let { id, props = {} } = config
  id = `${page_id}-${id}`
  const { swiperList = [], autoPlay = false } = props
  const result = {
    styles: [
      {
        name: `.pt-h5-swiper-img-${page_id}`,
        content: `
          .pt-h5-swiper-box-${page_id} {
            width: 100%;
          }
          .pt-h5-swiper-item-a {
            display: block;
            padding-bottom: 30px;
          }
          .pt-h5-swiper-item-img {
            width: 100%;
          }
        `
      },
    ],
    html: `
      <link rel="stylesheet" href="https://df5apg8r0m634.cloudfront.net/react/swiper.css"></link>
      <div class="pt-h5-swiper-img-${page_id} pt-h5-swiper-img-${id}">
        <div class="pt-h5-swiper-box-${page_id} pt-h5-swiper-box-${id} swiper-container">
          <div class="swiper-wrapper">
            ${swiperList.map((item) => {
      const { imgSrc, link } = item
      return `
                <div class="swiper-slide">
                  ${link ? `
                    <a class="pt-h5-swiper-item-a" href="${link}" target="_blank">
                      <img class="pt-h5-swiper-item-img" src="${imgSrc}" />
                    </a>
                  ` : `
                    <img class="pt-h5-swiper-item-img" src="${imgSrc}" />
                  `}
                </div>
              `
    }).join(' ')}
          </div>
          <div class="swiper-pagination pt-h5-swiper-pagination-${id}"></div>
        </div>
      </div>
      <script>
        (function() {
          var script = document.createElement('script')
          script.onload = function() {
            new Swiper('.pt-h5-swiper-box-${id}', {
              loop: true,
              slidesPerView: 1,
              autoplay: ${autoPlay ? '{ delay:3000, stopOnLastSlide:false, disableOnInteraction:false }' : 'false'},
              pagination: {
                el: '.pt-h5-swiper-pagination-${id}'
              }
            })
          }
          script.src = 'https://df5apg8r0m634.cloudfront.net/react/swiper.js'
          document.body.appendChild(script)
        })()
      </script>
    `,
  }
  return result
}

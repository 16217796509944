import React, { Fragment, Component } from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import PageComponentsMachine from '../../pageComponents/pageComponentsMachine';

@inject('createStore')
@observer
class PageLeftNav extends Component{

  onChildClick = (id, row_id, floor_id)=>{
    const { setComponentId, setFloorId, setRowId } = this.props.createStore
    setRowId(row_id, floor_id)
    setComponentId(id)
    
  }
  renderChild = (children,row_id,floor_id)=>{
    const {
      seletedData
    } = this.props.createStore;
    return <Fragment>{
      children.map((item,index)=>{
        return (
          <ChildrenContent key={index}>
            <ChildrenName isActive={seletedData.id == item.id} onClick={()=>{
              this.onChildClick(item.id, row_id, floor_id)
            }}>{this.transName(item.name)}</ChildrenName>
            {item.children && this.renderChild(item.children, row_id, floor_id)}
          </ChildrenContent>
        )
      })
      }</Fragment>
  }

  transName = (name)=>{
    const componentsObj = PageComponentsMachine.render(name) || {};
    return componentsObj.name
  }

  render() {
    const {
      floorList = [],
      seletedData,
      setComponentId, setFloorId, setRowId
    } = this.props.createStore;
    console.log(JSON.parse(JSON.stringify(floorList)));
    return (
       <Fragment>
        <Title>页面导航</Title>
        {
          floorList.map((floor,f_index)=>(
            //渲染楼层
            <FloorContent key={f_index} >
              <FloorName isActive={seletedData.id == floor.id}  onClick={() => {
                setFloorId(floor.id)
              }}>{this.transName(floor.name)}{f_index+1}</FloorName>
              {/* 渲染分栏 */}
              {floor.children && floor.children.map((row,r_index)=>(
                <ChildrenContent key={r_index}>
                  <RowName isActive={seletedData.id == row.id}  onClick={() => {
                    setRowId(row.id,floor.id)
                  }}>{this.transName(row.name)}{r_index+1}</RowName>
                  {/* 渲染内容 */}
                  {row.children && this.renderChild(row.children, row.id, floor.id)}
                </ChildrenContent>
              )) }
            </FloorContent>
          ))
        }
       </Fragment>
    );
  }
}

export default PageLeftNav

const Title = styled.div({
  width:'100%',
  paddingTop:"20px",
  textAlign:'center',
  fontSize:'18px',
  fontWeight:'600'
})

const FloorContent = styled.div({
  paddingLeft: '10px',
})

const FloorName = styled.div({
  width:'100%',
  marginTop:'20px',
  padding:'3px 0',
  paddingLeft: '10px',
  position:'relative',
  cursor: 'pointer',
  '&::before':{
    content:`''`,
    position:'absolute',
    width:'2px',
    height:'15px',
    background:'red',
    left:'0',
    top:'0',
    bottom:0,
    margin:'auto'
  }
},({isActive})=>({
  color: isActive ? 'red' : '#333'
}))

const RowName = styled.div({
  width: '100%',
  position: 'relative',
  padding: '5px 0',
  cursor: 'pointer'
}, ({ isActive }) => ({
  color: isActive ? 'rgb(246, 144, 0)' : '#333'
}))

const ChildrenContent = styled.div({
  paddingLeft:'15px'
})

const ChildrenName = styled.div({
  padding:'5px 0',
  cursor: 'pointer'
}, ({ isActive }) => ({
  color: isActive ? '#26ABFF' : '#333'
}))
//内容 对齐方式

import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import Icon from '../../components/Icon';
import { ContentTitle, FlexLine } from '../../style';

class FontAlign extends Component {
  render () {

    const {align = '' , onChange = ()=>{}} = this.props
    return (
      <Fragment>
        <p style={{ fontSize: '14px', margin: '10px 0', color: '#666' }}>文本对齐方式</p>
        <FlexLine justify={"space-between"} style={{ margin: '10px 0' }}>
          <FlexLine>
            <IconButton 
              active={align === 'left'}
              onClick={()=>{
                onChange('left')
              }}
            >
              <Icon icon="icon-left" />
            </IconButton>
            <IconButton 
              active={align === 'center'}
              onClick={() => {
                onChange('center')
              }}
            >
              <Icon icon="icon-centre"/>
            </IconButton>
            <IconButton 
              active={align === 'right'}
              onClick={() => {
                onChange('right')
              }}
            >
              <Icon icon="icon-right"/>
            </IconButton>
            <IconButton
              active={align === 'justify'}
              onClick={() => {
                onChange('justify')
              }}
            >
              <Icon icon="icon-Verticalcenter" />
            </IconButton>
          </FlexLine>
        </FlexLine>
      </Fragment>
    );
  }
}

export default FontAlign


const IconButton = styled.div(({active = false}) => ({
  padding: '3px',
  border: active ? '1px solid #26ABFF' : '1px solid #7D7D7D',
  color: active ?  '#fff' : '#333',
  background: active ? '#26ABFF' : '#fff',
  marginLeft: '10px',
  borderRadius: '4px',
  cursor: 'pointer',
  ':hover': {
    border: '1px solid #26ABFF',
    color: '#26ABFF'
  }
}))
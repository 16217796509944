export default function getImgSrcByVideoUrl(videoUrl) {
return new Promise((resolve) => {
  const video = document.createElement('video');
  video.autoplay = true;
  video.muted = true;
  video.crossOrigin = 'anonymous';
  video.style.position = 'fixed';
  video.style.opacity = '0';
  video.style.zIndex = '-1000';
  video.style.left = '100px';
  video.style.top = '100px';
  video.onloadeddata = () => {
    setTimeout(() => {
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
      document.body.removeChild(video);
      resolve(canvas.toDataURL('image/png'));
    }, 1000);
  };
  video.src = videoUrl;
  document.body.appendChild(video);
})
}

import React from 'react';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';
import FileItem from './FileItem';

@inject('createStore', 'fileStore')
@observer
class FileList extends React.Component {

  async componentDidMount() {
    await this.props.fileStore.getFileList(this.props.createStore.siteId);
  }

  onDelete = async (id) => {
    await this.props.fileStore.deleteFile(id);
  }

  onSelect = async (id) => {
    await this.props.fileStore.setCurrentFile(id);
  }

  get type() {
    return Array.isArray(this.props.type) ? this.props.type : [this.props.type]
  }

  render() {
    const { onSelect } = this.props;
    return (
      <React.Fragment>
        <MyList>
          {this.props.fileStore.fileList
            .filter(v => this.type.includes(v.type))
            .filter(v => {
              if (!this.props.fileStore.searchText) {
                return true;
              }
              return v.name.toLowerCase().indexOf(this.props.fileStore.searchText) > -1;
            })
            .map((v) => {
            return (
              <FileItem
                key={v.id}
                id={v.id}
                url={v.url}
                type={v.type}
                name={v.name}
                onDelete={this.onDelete}
                onSelect={onSelect}
              />
            )
          })}
        </MyList>
      </React.Fragment>
    );
  }
}

export default FileList;

const MyList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 8px;
`

import React, { Fragment, Component } from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { Modal } from '../../../pageComponents/pc/PcComponent';
import { FlexLine, Input } from '../../../style';
import TemplateList from './TemplateList';

import { getTemplate, getTemplateList, searchTemplate } from '../../../api';
import { Loading } from '../..';
import Pagination from '../../Main/Pagination';

@inject(({ createStore }) => ({
  addTemplateToFloor: createStore.addTemplateToFloor,
  emailFlag: createStore.emailFlag,
  pageFlag: createStore.pageFlag
}))
@observer
class TemplateModal extends Component {
  state = {
    activeId:0,
    page:1,
    per_page:15,
    loading:false,
    list:[],
    total:0,
    searchStr:''
  }

  componentDidMount(){
    this.getList()
  }

  getList = () => {
    this.setState({
      loading: true
    })

    getTemplateList({
      page: this.state.page,
      per_page: this.state.per_page,
      type: this.getType(),
      status: 2
    }).then((arg) => {
      const { data, meta } = arg
      this.setState({
        list: data,
        total: meta.total,
        loading: false
      })
    }).catch((e) => {
      this.setState({
        loading: false
      })
      console.log('获取列表失败', e);
    })
  }

  getType = ()=>{
    let type = 1;
    if (this.state.activeId === 0) {
      type = 1
    }
    if (this.state.activeId === 1) {
      type = 4
    }

    if (this.props.pageFlag === this.props.emailFlag) {
      type = 3;
    }

    return type
  }

  onChange = (e) => {
    const { value } = e.target
    this.setState({
      search: value
    }, () => {
      if (!value && this.state.onSearch) {
        this.onSearch()
      }
    })
  }

  pageChange = (page) => {
    this.setState({
      page
    }, () => {
      this.getList()
    })
  }


  onSelect = (id) => {
    getTemplate(id)
      .then((data) => {
        if (!data) return;
        const { component_json = {} } = data.data.content;
        this.props.addTemplateToFloor(component_json)
        this.props.onClose()
      })
      .catch(e => {
        console.log(e);
      })
  }

  onChange = (e)=>{
    this.setState({
      searchStr:e.target.value
    })
  }

  onSubmit = (e)=>{
    e.preventDefault();
    this.onSearch()
    return
  }

  onShowPage = (active)=>{
    if (this.state.activeId === active){
      return
    }
    this.setState({
      activeId: active
    },()=>{
      this.onSearch()
    })
  }

  onSearch = ()=>{
    searchTemplate({
        page: 1,
        per_page: this.state.per_page,
        type: this.getType(),
        name:this.state.searchStr,
        status: 2
      }).then((arg) => {
        const { data, meta } = arg
        this.setState({
          list: data,
          total: meta.total,
          loading: false
        })
      }).catch((e) => {
        this.setState({
          loading: false
        })
        console.log('获取列表失败', e);
    })
  }

  render () {
    const { visible, onClose } = this.props
    const isEmail = this.props.pageFlag === this.props.emailFlag;
    return (
      <Modal show={visible} onclose={onClose} width={'70%'}>
        <div style={{ padding: '40px 20px', position: 'relative' }}>
          <FlexLine justify="space-between">
            <ModalTitle>模版库</ModalTitle>
            <FlexLine style={{ marginBottom: '20px' }}>
              {
                isEmail ? <TabItem active={true}>邮件</TabItem> : <Fragment>
                  <TabItem active={this.state.activeId === 0} onClick={() => { this.onShowPage(0) }}>页面</TabItem>
                  <TabItem active={this.state.activeId === 1} onClick={() => { this.onShowPage(1) }}>楼层</TabItem>
                </Fragment>
              }
            </FlexLine>
            <div style={{ position: 'relative' }}>
              <form onSubmit={this.onSubmit}>
                <Input placeholder='输入方案名称' value={this.state.searchStr} onChange={this.onChange} />
                <SerachBtn onClick={this.onSearch}>
                  <svg t="1591261719889" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4010" width="20" height="20"><path d="M465.454545 861.090909c-218.763636 0-395.636364-176.872727-395.636363-395.636364S246.690909 69.818182 465.454545 69.818182s395.636364 176.872727 395.636364 395.636363-176.872727 395.636364-395.636364 395.636364z m0-744.727273C272.290909 116.363636 116.363636 272.290909 116.363636 465.454545s155.927273 349.090909 349.090909 349.09091 349.090909-155.927273 349.09091-349.09091S658.618182 116.363636 465.454545 116.363636zM930.909091 954.181818c-6.981818 0-11.636364-2.327273-16.290909-6.981818l-186.181818-186.181818c-9.309091-9.309091-9.309091-23.272727 0-32.581818s23.272727-9.309091 32.581818 0l186.181818 186.181818c9.309091 9.309091 9.309091 23.272727 0 32.581818-4.654545 4.654545-9.309091 6.981818-16.290909 6.981818z" p-id="4011"></path></svg>
                </SerachBtn>
              </form>
            </div>
          </FlexLine>
          {
            this.state.loading ? <FlexLine style={{minHeight:'400px'}}>
              <Loading />
            </FlexLine> :
              <TemplateList onSelect={this.onSelect} data={this.state.list}/>
          }
          {
            this.state.total > this.state.per_page && <Pagination
            page={this.state.page}
            total={this.state.total}
            per_page={this.state.per_page}
            onChange={this.pageChange}
            ></Pagination>}
        </div>
      </Modal>
    );
  }
}

export default TemplateModal

const TabItem = styled.div(({active})=>({
  fontSize:'16px',
  padding:'5px 15px',
  position:'relative',
  cursor:'pointer',
  "&::after": !active ? {} : {
    content:'""',
    position:"absolute",
    width:'30px',
    height:'2px',
    background: '#26ABFF',
    left:0,
    right:0,
    bottom:'-5px',
    margin:'auto'
  }
}))

const ModalTitle = styled.div({
  fontSize: '16px',
  position: 'absolute',
  top: '10px',
  left: '10px'
})

const SerachBtn = styled.div({
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  padding: '10px',
  margin: 'auto',
  paddingTop: '13px',
  cursor: 'pointer'
})

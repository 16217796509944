import componentMount from "../../lib/componentMount";

import EditContainer from "./EditImg";
import Component from "./Img";
import BaseStyle from "../../BaseBox/BaseStyle";
import staticRender from "./StaticRender.js";

const _Render = componentMount({
    Component:Component,
    Content:EditContainer,
    Style:BaseStyle,
    text:'图片'
})

export default {
    componentMount:_Render,
    name:'Img',
    text:'图片',
    staticRender
}
import React, {Component} from 'react';
import {inject, observer} from 'mobx-react';
import {ContentTitle, FlexLine, Input, TextArea} from '../../../style';
import {Loading, WhiteSpace} from '../../../components/index';

import Editor from '../../../components/Editor';
import Percentage from '../../EditComponents/Percentage';
import ContentAlign from '../../EditComponents/ContentAlign';

@inject('createStore')
@observer
class EditH5Text extends Component {

  state = {
    isAddEditor: false,
  }
  text = ''

  componentDidMount() {
    this.text = this.props.text || '请输入文本'
    this.setState({
      isAddEditor: true,
    })
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.id !== this.props.id) {
      this.text = this.props.text || '请输入文本'
      this.setState({
        isAddEditor: false
      }, () => {
        setTimeout(() => {
          this.setState({
            isAddEditor: true
          })
        }, 500);
      })
    }
  }


  handleEditorChange = (value) => {
    const {activeRowData, seletedRowId, updateDataProps} = this.props.createStore;
    if (activeRowData && seletedRowId) {//选中楼层子组件，优先更新子组件
      updateDataProps('text', value);
    }
  }

  proportionChange = (value) => {
    const {updateDataProps} = this.props.createStore;
    updateDataProps('boxWidth', value);
  }

  alignChange = (value) => {
    const {updateDataProps} = this.props.createStore;
    updateDataProps('align', value);
  }

  render() {
    const {text = '', boxWidth = 100, align = ''} = this.props;
    return (
      <div>
        <ContentTitle>文本:</ContentTitle>
        <div style={{height:'550px'}}>
                 <Editor
                    value={text}
                    placeholder={'请输入文本'}
                    onChange={this.handleEditorChange}
                    style={{
                        width: "100%",
                        height: "400px",
                    }}
                />
                 </div>
        <WhiteSpace/>
        <Percentage percent={boxWidth} onChange={this.proportionChange}/>
        <ContentAlign align={align} onChange={this.alignChange}/>
      </div>
    )
  }
}

export default EditH5Text;
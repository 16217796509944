import React, { Component, createRef } from 'react'

class NewPriview extends Component {
  constructor(props) {
    super(props)
    this.state = {
      html: ''
    }
    this.ref = createRef()
  }

  componentDidMount() {
    this.getHtml()
  }

  getHtml = () => {
    let html = localStorage.getItem('html_json')
    if (!html) {
      return setTimeout(this.getHtml, 16)
    }
    this.setState({ html }, () => {
      const { current } = this.ref
      const scriptList = [...current.getElementsByTagName('script')]
      scriptList.forEach(script => {
        // eslint-disable-next-line no-new-func
        new Function(script.innerHTML)()
      })
    })
  }

  render() {
    return <div ref={this.ref} dangerouslySetInnerHTML={{ __html: this.state.html }}></div>
  }
}

export default NewPriview

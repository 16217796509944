import React, { Fragment, Component } from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { autorun } from 'mobx';

@inject('createStore')
@observer
class PCSelect extends Component{
  get options(){
    return [
      {
        value:'1440px',
        lable:'PC 1440'
      },
      {
        value: '1200px',
        lable: 'PC 1200'
      },
      {
        value: '100%',
        lable: 'PC 全屏'
      }
    ]
  }

  state = {
    visible:false
  }

  toggerShow = ()=>{
    this.setState(preState => ({
      visible: !preState.visible
    }))
  }

  onChange = (val)=>{
    const { onPageSizeChange } = this.props.createStore;

    console.log(val);
    onPageSizeChange && onPageSizeChange(val)
    this.toggerShow()
  }
  render() {
    const { pageSize = '100%',type } = this.props.createStore;
    const active_item = this.options.find(item => item.value === pageSize)
    return (
       <div style={{position:'relative'}}>
        <TopTxt onClick={type != 2 ? this.toggerShow : () => { }}>{type != 2 ? active_item.lable : 'PC'}</TopTxt>
        {this.state.visible && <OptionContainer>
          {
            this.options.map((item,index)=>(
              <OptionItem onClick={()=>{this.onChange(item.value)}} key={index}>{item.lable}</OptionItem>
            ))
          }
        </OptionContainer>}
      </div>
    );
  }
}

export default PCSelect;

const OptionItem = styled.div({
  lineHeight:'48px',
  textAlign:'center',
  fontSize:'14px',
  color:'#333',
  cursor:'pointer',
  '&:hover':{
    color:'#26ABFF'
  }
})

const OptionContainer = styled.div({
  background:'#fff',
  width:"120px",
  position:'absolute',
  top:'50px',
  left:'-10px',
  zIndex: 99,
  boxShadow: '0 0 5px 1px rgba(0 ,0 ,0,0.1)'
})

const TopTxt = styled.div({
  margin: '0 10px',
  cursor: 'pointer',
  color: '#26ABFF',
  padding: '5px 0',
  position: 'relative'
});
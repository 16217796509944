import { toLine } from "../../../utils/transformCssPropertyName"

function jsStyle2NormalStyle (name, value, flexDirection) {
    const map = flexDirection === 'column' ? {
        'justifyContent': {
            name: 'vertical-align',
            valueMap: {
                'default': 'top',
                'flex-start': 'top',
                'center': 'middle',
                'flex-end': 'bottom',
            }
        },
    } : {
        'justifyContent': {
            name: 'text-align',
            valueMap: {
                'default': 'left',
                'flex-start': 'left',
                'center': 'center',
                'flex-end': 'right',
            }
        },
    }
    const obj = map[name]
    if (!obj) {
        return []
    }
    return [obj.name, obj.valueMap[value || 'default']]
}

export default function staticRender ({ config }) {
    const { id, props = {}, children = [] } = config
    let { styles = {} } = props
    let { flexDirection = 'column' } = styles
    styles = {
        boxSizing: 'border-box',
        minHeight: '100px',
        ...styles,
        justifyContent: styles.justifyContent || '',
    }

    const emailColStyle = Object.keys(styles)
        .map(css => {
            const [cssName, cssVal] = jsStyle2NormalStyle(css, styles[css], flexDirection)
            if (cssName) {
                return `${cssName}:${cssVal};`
            }
            return `${toLine(css)}:${styles[css]};`
        })
        .join(' ')
    return {
        styles: [],
        html: `
            <td style="${emailColStyle}">
                ${children.reduce((pre, _, currIdx) => `${pre} {{CHILDREN_PLACEHOLDER_${currIdx}}}`, '')}
            </td>
        `
    }
}
import { Button as AntButton } from "antd";
import React, { useRef } from "react";
import { Textarea, WhiteSpace } from "../index";
import MyModal from "../Modal";

function ExportTemplateModal(props) {
  const textAreaRef = useRef(null)
  return (
    <MyModal
      visible={true}
      onClose={props.onClose}
    >
      <div style={{ width: '500px', height: '500px' }}>
        {props.template ? <>
          <WhiteSpace height="30px" />
          <Textarea
            onRef={(cur) => {
              textAreaRef.current = cur;
            }}
            style={{ height: '420px' }}
            value={props.template}
          />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <AntButton
              onClick={() => {
                textAreaRef.current.select();
                props.onCopy(document.execCommand('copy'));
              }}
            >
              全选复制
            </AntButton>
          </div>
        </> : <>
          <WhiteSpace height="30px" />
          <p style={{ textAlign: 'center' }}>内容为空，导出失败！</p>
        </>}
      </div>
    </MyModal>
  );
}

export default ExportTemplateModal;

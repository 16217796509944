import React, { useState} from 'react';
import styled from 'styled-components';

const Container = styled.div(({ styles }) => ({
    display: 'flex',
    width: '100%',
    boxSizing: 'border-box',
    ...styles
}))

const TimeFlex = styled.div(({styles})=>({
    display: 'flex',
    alignItems: 'center',
    ...styles
}))

const TimeContent = styled.div(({ styles }) => ({
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    background: '#000',
    color: '#fff',
    textAlign: 'center',
    display: 'flex',
    flexDirection: "column",
    justifyContent: 'center',
    alignItems: 'center',
    ...styles
}))

const TimeText = styled.div(({styles={}})=>({
    fontSize: '32px', 
    fontWeight: '800px',
    marginBottom:'5px'
}))

const TimeTextMin = styled.div(({ styles = {} }) => ({
    margin: '0 15px', 
    fontSize: '28px', 
    fontWeight: '800px',
    ...styles
}))

const TimeTextSub = styled.div(({ styles = {} }) => ({
    fontSize: '18px', 
    fontWeight: '800px',
    ...styles
}))
let timer = '';

function changeTime (_endTime, setTimeData, setIsShow){
    let _time = Math.floor(new Date(_endTime) / 1000);
    let now = Math.floor(Date.now() / 1000);

    if (now < _time) {
        setIsShow(true);
        timer = setInterval(() => {
            if (now >= _time) {
                setIsShow(false)
                clearInterval(timer)
                return
            }
            now = now + 1;
            let timeData = filterTime(_time - now)
            setTimeData(timeData)
        }, 1000);
    }else{
        setIsShow(false);
    }
}



function PcTime ({ 
    align, 
    styles, 
    endTime = '',
    day = "Day",
    hours = 'Hours',
    minutes = 'Minutes',
    seconds = 'Seconds',
    bgColor = "#000",
    textColor = "#fff"
}){


    let [timeData, setTimeData] = useState({
        day: '0',
        hour: "00",
        min: "00",
        second: "00"
    });
    let [_endTime, setEndTime] = useState('');

    let [isShow, setIsShow] = useState(false);

    if (endTime != _endTime){
        setEndTime(endTime)
        clearInterval(timer)
        endTime && changeTime(endTime, setTimeData, setIsShow)
    } 


    return (
        <Container styles={align ? { justifyContent: align } : {}}>
            <TimeFlex styles={styles}>
                <TimeContent styles={{ background: bgColor, color: textColor }}>
                    <TimeText>{isShow ? timeData.day : '0'}</TimeText>
                    <TimeTextSub>{day}</TimeTextSub>
                </TimeContent>
                <TimeTextMin styles={{ color: bgColor }}>:</TimeTextMin>
                <TimeContent styles={{ background: bgColor, color: textColor }}>
                    <TimeText>{isShow ? timeData.hour : '00'}</TimeText>
                    <TimeTextSub>{hours}</TimeTextSub>
                </TimeContent>
                <TimeTextMin styles={{ color: bgColor }}>:</TimeTextMin>
                <TimeContent styles={{ background: bgColor, color: textColor }}>
                    <TimeText>{isShow ? timeData.min : '00'}</TimeText>
                    <TimeTextSub>{minutes}</TimeTextSub>
                </TimeContent>
                <TimeTextMin styles={{ color: bgColor }}>:</TimeTextMin>
                <TimeContent styles={{ background: bgColor, color: textColor }}>
                    <TimeText>{isShow ? timeData.second : '00'}</TimeText>
                    <TimeTextSub>{seconds}</TimeTextSub>
                </TimeContent>
            </TimeFlex>
        </Container>
    )
}

export default PcTime;

function filterTime (lastTime) {
    if (lastTime < 0) {
        lastTime = 0;
    }
    const day = lastTime > 86400 ? Math.floor(lastTime / 86400) : 0;
    lastTime = lastTime - day * 86400;
    const hour = lastTime > 3600 ? Math.floor(lastTime / 3600) : 0;
    lastTime = lastTime - hour * 3600;
    const min = lastTime > 60 ? Math.floor(lastTime / 60) : 0;
    lastTime = lastTime - min * 60;
    return {
        day: addZero(day),
        hour: addZero(hour),
        min: addZero(min),
        second: addZero(lastTime)
    };
}

function addZero (num) {
    if (num < 10) {
        num = '0' + num;
    }
    return num;
}
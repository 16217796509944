import React from 'react';
import styled from 'styled-components';

const Box = styled.div(({ styles }) => {
  return {
    margin: '0 auto',
    boxSizing: 'border-box',
    background:'#fff',
    display:'felx',
    ...styles,
    "@media screen and (max-width: 768px)": {
      display: 'none'
    }
  }
});


function PcAlert ({ children = '', styles = {}, isHide = false }) {
  return <div className="Alert_By_Tool" style={{ display: isHide && !window.isEdit ? 'none' : 'block'}}>
    <Box  styles={{ ...styles, background: styles.backgroundColor || '#fff' }}>{children}</Box>
  </div>
}

export default PcAlert;
//pc 页面组件======================================
import PcTitle from './PcTitle';
import PcText from './PcText';
import PcListText from './PcListText';
import PcImg from './PcImg';
import PcImgTextColumn from './PcImgTextColumn';
import PcIconColumn from './PcIconColumn';
import PcSwiperImg from './PcSwiperImg';
import PcImgAndText from './PcImgAndText';
import PcUserShow from './PcUserShow';
import PcCouponForm from './PcCouponForm';
import PcRule from './PcRule';
import PcButton from './PcButton';
import PcVideo from "./PcVideo";
import PcTime from "./PcTime";

import PcAlertCoupon from "./PcAlertCoupon";
import PCCodeContainer from "./PCCodeContainer";

import PcBaseBox from "./PcBaseBox";
import PcRowContent from "./PcRowContent";
import PcAlert from "./PcAlert";
import PcSwiper from "./PcSwiper";
import PcSwiperItem from "./PcSwiperItem";
import PcProduct from "./PcProduct";


export default [
    PcBaseBox,
    PcRowContent,
    PcTitle,
    PcText,
    PcListText,
    PcImg,
    PcImgTextColumn,
    PcIconColumn,
    PcSwiperImg,
    PcImgAndText,
    PcUserShow,
    PcCouponForm,
    PcRule,
    PcButton,
    PcVideo,
    PcTime,
    PcAlertCoupon,
    PCCodeContainer,
    PcBaseBox,
    PcRowContent,
    PcAlert,
    PcSwiper,
    PcSwiperItem,
    PcProduct
]
import React, { Component } from 'react';
import styled from 'styled-components';

const PreviewBox = styled.div`
    font-family: Arial, Helvetica, sans-serif;
    padding: 0 0 100px;
`

class Preview extends Component {
    state = {
        html: ''
    }
    componentDidMount() {
        this.getHtml()
    }

    getHtml = () => {
        let html = localStorage.getItem('html_json')
        if (!html) {
            return setTimeout(this.getHtml, 16)
        }
        this.setState({ html })
    }

    render() {
        return <PreviewBox dangerouslySetInnerHTML={{ __html: this.state.html }}></PreviewBox>
    }
}

export default Preview;
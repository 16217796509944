//pc 标题
import React from 'react';

function Title({ title = "请输入标题", styles = {}, boxWidth = '100', align = 'flex-start' }) {
    return (
        <div
            style={
                {
                    display: 'flex',
                    width: '100%',
                    boxSizing: 'border-box',
                    justifyContent: align
                }
            }>
            <div style={
                {
                    fontSize: '28px',
                    lineHeight: '1.5',
                    textAlign: 'center',
                    color: '#333',
                    width: `${boxWidth}%`,
                    ...styles
                }
            }
                dangerouslySetInnerHTML={{ __html: title }}></div>
        </div>
    )
}
export default Title;
import React,{ Component } from "react";
import styled from 'styled-components'
import MyModal from '../Modal'
import { FlexLine,Button } from "../../style";
import {Select, WhiteSpace} from "../index";
import {inject, observer} from "mobx-react";


const Input = styled.input({
  border:'1px solid #e4e4e4',
  padding:'15px',
  width:'347px',
  height: '40px',
  background:'#fff',
  borderRadius:'4px',
  paddingRight: '30px'
})

@inject('siteStore', 'templateStore','homeStore')
@observer
class CreateTemplateModal extends Component{

    state = {
        name: this.props.reNameItem.name,
        url: this.props.reNameItem.url,
        type: this.props.reNameItem.type,
        site_id: this.props.reNameItem.site_id,
    }

    render() {
        const {visible, onClose,onSubmi,reNameItem} = this.props

        let _options = []
        this.props.siteStore.sites.forEach(v => {
          if (v.policiesKey) {
            if (this.props.homeStore.hasPolicies(v.policiesKey)) {
              _options.push({
                label: v.name,
                value: v.site_id
              })
            }
          } else {
            _options.push({
              label: v.name,
              value: v.site_id
            })
          }
        });
        return (
            <MyModal visible={visible} onClose={onClose}>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    if(!this.state.name) {
                      return
                    }
                    onSubmi({
                      name: this.state.name,
                      id: this.props.reNameItem.id,
                      url: this.state.url,
                      type: this.state.type,
                      site_id: this.state.site_id
                    })
                  }}
                >
                    <FlexLine
                      style={{
                        width:'500px',
                        height:'370px',
                        background:'#ffffff'
                      }}
                      column={true}
                    >
                      <p>{`${this.props.template ? '模版' : '方案'}`}</p>
                      <Input
                        value={this.state.name}
                        placeholder={`${this.props.template ? '模版' : '方案'}名称`}
                        onChange={(e) => {
                          this.setState({
                            name: e.target.value
                          })
                        }}
                      />
                      <WhiteSpace />
                      {this.props.template ? null : <>
                        <Input
                          value={this.state.url}
                          placeholder="URL"
                          onChange={(e) => {
                            this.setState({
                              url: e.target.value
                            })
                          }}
                        />
                        <WhiteSpace />
                      </>}
                      {
                        this.props.reNameItem.id ? null : <div style={{ width: '347px' }}>
                          <Select
                            defaultValue={this.state.type}
                            placeholder="类型"
                            onChange={(data) => {
                              this.setState({
                                type: data.value
                              })
                            }}
                            options={this.props.templateStore.templateTypes}
                          />
                          <WhiteSpace />
                        </div>
                      }
                      <div style={{ width: '347px' }}>
                        {this.props.template ? null : (
                          <Select
                            defaultValue={this.state.site_id}
                            placeholder="站点"
                            onChange={(data) => {
                              this.setState({
                                site_id: data.value
                              })
                            }}
                            options={_options}
                          />
                        )}
                      </div>
                        <Button 
                            onClick={()=>{
                                if(!this.state.name){
                                    return
                                }
                                onSubmi({
                                  name: this.state.name,
                                  id: this.props.reNameItem.id,
                                  url: this.state.url,
                                  type: this.state.type,
                                  site_id: this.state.site_id
                                })
                            }}
                            disable={
                              this.props.template ? (
                                !this.state.name ||
                                !this.state.type
                              ) : (
                                !this.state.name ||
                                !this.state.type ||
                                !this.state.site_id
                              )
                            }
                            style={{
                              marginTop: '30px',
                              width: '120px'
                            }}
                        >
                            {reNameItem.id ? '提交' : '创建'}
                        </Button>
                    </FlexLine>
                </form>
            </MyModal>     
        );
    }
}

export default CreateTemplateModal;

// pc 文本
import React from 'react';
import styled from 'styled-components';
const Text = styled.div(({styles}) => { 
    return {
        width: '180px',
        height: '35px',
        fontSize: '14px',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        color: '#fff',
        borderRadius:'4px',
        background:'#26ABFF',
        cursor:'pointer',
        "&>p":{
            margin:0
        },
        ...styles
    }
})

const Container = styled.div(({ styles}) => ({
    display: 'flex',
    width: '100%',
    boxSizing: 'border-box',
    ...styles
}))

const HrefBox = styled.a(({ styles = {}, href }) => {
    return {
        fontSize: 0,
        cursor: href ? 'pointer' : 'default',
        textDecoration:'none',
        '&:focus':{
            textDecoration: 'none',
        },
        '&:active': {
            textDecoration: 'none',
        },
        ...styles
    }
})

function PcButton ({ text = '按钮文本', styles = {}, align = '', href = "", hoverTextColor, hoverBgColor }) {
    return (
        <Container styles={align ? {justifyContent:align} : {}}>
            <HrefBox
                href={href ? href : ''}
                styles={{ width: styles.width}}
                onClick={(e) => { !href && e.preventDefault() }} target='_blank'
            >
                <Text styles={{
                    ...styles,
                    '&:hover':{
                        backgroundColor: hoverBgColor,
                        color: hoverTextColor,
                    }
                }} dangerouslySetInnerHTML={{ __html: text }}></Text>
            </HrefBox>
            
        </Container>
    )
}
export default PcButton;
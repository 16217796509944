import componentMount from "../../lib/componentMount";

import EditContainer from "../../BaseBox/EditRowBaseBox";
import Component from "./H5RowContent";
import BaseStyle from "../../BaseBox/BaseStyle";
import staticRender from "./StaticRender.js";

const _Render = componentMount({
    Component:Component,
    Content:EditContainer,
    Style:BaseStyle,
    text:'分栏'
})

export default {
    componentMount:_Render,
    name:'H5RowContent',
    text:'分栏',
    staticRender
}
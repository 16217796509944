// 编辑 页面
import { Button as AntButton } from 'antd';
import React, { Component, Fragment } from 'react'
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';
import { CreatePage } from '../components';
import EmailPreBtn from "../components/create/EmailPreBtn";
import {
  saveTemplate,
  getTemplate,
  saveAndPackagePageData,
  saveSchemes,
  getSchemes
} from '../api';
import { Button, FlexLine, SubButton } from '../style';
import { Link, withRouter } from "react-router-dom";
import { Loading, Modal, Textarea, ProductDetail } from '../components/index';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { getQueryVariable } from "../utils/helps";
import PCSelect from "../components/create/PCSelect";
import getHtmlByConfig from "../utils/getHtmlByConfig";
import loadScript from '../utils/loadScript';

const Div = styled.div({
  position: 'relative',
  height: '100vh',
  boxSizing: 'border-box',
  paddingTop: '66px',
  background: '#f5f5f5'
})
//banner
const BannerWraper = styled.div`  
  background: #fff;  
  height: 60px;  
  display: flex;  
  justify-content: space-between;  
  align-items: center;  
  font-size: 20px;  
  padding: 0 20px;  
  position: absolute;  
  left: 0;  
  top: 0;  
  width: 100%;  
  box-sizing: border-box;  
  margin-bottom: 6px; 

  @media (max-width: 1300px) { 
    padding: 0 5px; 
  }  
`;
const Left = styled.div({
  display: 'flex',
  alignItems: 'center',
  fontSize: '16px',
  color: '#333333',
  [`@media (max-width: 1400px)`]: {
    fontSize: '14px',
  },
});
const Right = styled.div({
  display: 'flex',
  alignItems: 'center'

});
const TopTxt = styled.div(({ ac }) => ({
  margin: '0 10px',
  cursor: 'pointer',
  color: '#26ABFF',
  padding: '5px 0',
  whiteSpace: 'nowrap',
  position: 'relative',
  [`@media (max-width: 1400px)`]: {
    margin: '0 5px',
    fontSize: '18px',
  },
  '&::after': {
    content: `''`,
    position: 'absolute',
    height: '2px',
    width: '40px',
    background: ac ? '#26ABFF' : '#fff',
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto'
  }
}));

const HeaderP = styled.p({
  fontSize: '16px',
  margin: 0,
  marginRight: "40px",
  color: '#333',
  whiteSpace: 'nowrap',
  [`@media (max-width: 1400px)`]: {
    margin: '0 10px',
    fontSize: '14px',
  },
})

const DeprecatedComponentNotice = styled.p({
  padding: '0 20px 0 0',
  maxWidth: '33vw',
  wordBreak: 'break-word',
})

const SvgButton = styled.div({
  padding: '0 10px',
  paddingTop: '10px',
  cursor: 'pointer',
  opacity: ({ isActive }) => isActive ? '1' : '0.5',
  [`@media (max-width: 1400px)`]: {
    padding: '0 8px',
    paddingTop: '8px',
  },
})

const ResponseButton = styled(Button)`  
  @media (max-width: 1400px) {  
    padding: 5px 10px;
  }  
`;
const ResponseSubButton = styled(SubButton)`  
  @media (max-width: 1400px) {  
    padding: 5px 10px;
  }  
`;



@withRouter
@inject('createStore', 'viewStore', 'siteStore')
@observer
class Create extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      buildHtml: '',
      show: false,
      type: 1,
      site_id: 1,
      name: '',
      deprecatedComponentNotice: '',
    }
  }
  handleNewReview = () => {
    this.handleReview(true)
  }
  handleReview = async (newPreview) => {
    console.log('----newPreview----', !!newPreview)
    if (newPreview === true) {
      localStorage.setItem('html_json', '');
      await loadScript('/postcss.js');
      const { pcFloorList, h5FloorList } = this.props.createStore
      console.log('pcFloorList', JSON.parse(JSON.stringify(pcFloorList)));
      const { code, statusCode, msg } = await getHtmlByConfig({ pcFloorList, h5FloorList, page_id: this.props.match.params.id })
      if (statusCode === 0) { // 使用新的方式展示
        localStorage.setItem('html_json', code);
      } else {
        toast("预览失败 " + msg, {
          type: 'error'
        })
      }
    } else { // 如果是弹窗 或者是采用老方式展示
      this.props.createStore.preview();
    }
  }
  showLoading() {
    this.setState({
      loading: true
    })
  }
  hideLoading() {
    this.setState({
      loading: false
    })
  }

  handleRecordTemplate = (status) => {
    const id = this.props.match.params.id;
    const { pcFloorList, h5FloorList, emailFloorList, pageFlag, emailFlag, isTemplate } = this.props.createStore;
    let component_json = {
      pc: pcFloorList,
      h5: h5FloorList
    }

    if (pageFlag === emailFlag) {
      if (emailFloorList.length < 1 && status === 2) {
        toast("发布失败，请添加邮件模板内容", {
          type: 'error'
        });
        return
      }
      component_json = {
        email: emailFloorList
      }
    }

    let tip = '保存成功'

    if (pcFloorList.length < 1 && status === 2) {
      tip = '保存成功，请注意PC端模版内容为空'
    }
    if (h5FloorList.length < 1 && status === 2) {
      tip = '保存成功，请注意H5端模版内容为空'
      return
    }

    this.showLoading()

    saveTemplate(id, { component_json: component_json, status }).then(data => {
      toast(tip, {
        type: 'success'
      });
    })
      .catch(e => {
        toast("保存失败，请稍后重试", {
          type: 'error'
        });
      })
      .finally(() => {
        this.hideLoading();
      })
  }

  handleRecord = async () => {
    this.showLoading()
    const id = this.props.match.params.id
    const { pcFloorList, h5FloorList, emailFloorList, pageFlag, emailFlag, isTemplate } = this.props.createStore

    await loadScript('/postcss.js')
    let html_result = {}
    html_result = await getHtmlByConfig({ pcFloorList, h5FloorList, page_id: id })
    const { pcCode, h5Code } = html_result

    let component_json = {
      pc: pcFloorList,
      h5: h5FloorList
    }

    if (pageFlag === emailFlag) {
      component_json = {
        email: emailFloorList
      }
    }
    // 保存时 需要传入PC和H5打包后的代码
    saveSchemes(id, {
      component_json,
      pc_content: pcCode,
      h5_content: h5Code
    })
      .then(data => {
        toast('保存成功', {
          type: 'success'
        })
      })
      .catch(e => {
        toast('保存失败，请稍后重试', {
          type: 'error'
        })
      })
      .finally(() => {
        this.hideLoading()
      })
  }
  getBody(content) {
    var REG_BODY = /<body[^>]*>([\s\S]*)<\/body>/;
    var subStr = /steveroot/ig;
    const _content = content.replace(subStr, `steveroot-${new Date().getTime()}`)
    var result = REG_BODY.exec(_content);
    if (result && result.length === 2)
      return result[1];
    return _content;
  }
  //验证保存和导出
  vertifyBuild = () => {
    toast("确保pc端和h5端页面都有内容！", {
      type: 'info'
    });
  }
  textArea = null;
  copyCode = () => {
    this.textArea.select();
    if (document.execCommand('copy')) {
      toast("复制成功", {
        type: 'success'
      });
    } else {
      toast("复制失败", {
        type: 'error'
      });
    }
  }
  // 不使用webpack打包 使用自定义的render来实现
  handleNewExport = async device => {
    const { pcFloorList, h5FloorList } = this.props.createStore
    const id = this.props.match.params.id
    if (!id) return
    if (!device && (!pcFloorList.length || !h5FloorList.length)) return this.vertifyBuild()
    let params = {
      component_json: {
        pc: pcFloorList,
        h5: h5FloorList
      }
    }
    await loadScript('/postcss.js')
    let html_result = {}
    if (device === 'pc') {
      if (pcFloorList.length < 1) {
        toast("导出失败，PC内容为空，请检查方案", {
          type: 'error'
        });
        return
      }
      html_result = await getHtmlByConfig({ pcFloorList, h5FloorList: [], page_id: this.props.match.params.id })
    } else if (device === 'h5') {
      if (h5FloorList.length < 1) {
        toast("导出失败，PC内容为空，请检查方案", {
          type: 'error'
        });
        return
      }
      html_result = await getHtmlByConfig({ pcFloorList: [], h5FloorList, page_id: this.props.match.params.id })
    } else {
      html_result = await getHtmlByConfig({ pcFloorList, h5FloorList, page_id: this.props.match.params.id })
    }

    const { code, statusCode, msg, pcCode, h5Code } = html_result
    // 保存并导出时 需要传入PC和H5打包后的代码
    if (!(device === 'pc' || device === 'h5')) {
      params = {
        ...params,
        pc_content: pcCode,
        h5_content: h5Code
      }
    }

    saveSchemes(id, params)

    if (statusCode === 0) {
      let buildHtml;
      if (device === 'pc') {
        buildHtml = pcCode
      }else if (device === 'h5') {
        buildHtml = h5Code
      }else{
        buildHtml = code;
      }
      this.setState({
        show: true,
        buildHtml
      })
      toast('打包成功，复制打包内容到站内方案！', {
        type: 'success'
      })
      saveSchemes(id, { html_content: buildHtml })
    } else {
      this.setState({ deprecatedComponentNotice: msg })
    }
  }
  handleExport = async () => {
    const { pcFloorList, h5FloorList } = this.props.createStore
    const id = this.props.match.params.id
    if (!id) return
    if (!pcFloorList.length || !h5FloorList.length) return this.vertifyBuild()
    this.showLoading()
    const buildData = {
      template_id: id,
      component_json: JSON.stringify({
        pc: pcFloorList,
        h5: h5FloorList
      })
    }

    await loadScript('/postcss.js')
    let html_result = {}
    html_result = await getHtmlByConfig({ pcFloorList, h5FloorList, page_id: id })
    const { pcCode, h5Code } = html_result

    saveSchemes(id, {
      component_json: {
        pc: pcFloorList,
        h5: h5FloorList
      },
      pc_content: pcCode,
      h5_content: h5Code
    })
    return saveAndPackagePageData(buildData)
      .then(data => {
        const { html_content } = data.data
        if (!html_content) return

        const _html_content = this.getBody(html_content)
        this.setState({
          show: true,
          buildHtml: _html_content
        })

        saveSchemes(id, { html_content: _html_content }).then(data => { })

        toast('打包成功，复制打包内容到站内方案！', {
          type: 'success'
        })
      })
      .catch(e => {
        console.log(e)
        toast('打包失败，请稍后重试！', {
          type: 'error'
        })
      })
      .finally(() => {
        this.hideLoading()
      })
  }
  componentWillUnmount() {
    //更新数据 先清空
    const { resetFloorData } = this.props.createStore
    resetFloorData()
  }

  componentDidMount() {
    window.isEdit = true

    const id = this.props.match.params.id
    // this.props.createStore.setSiteId(this.props.location.search.split('=')[1])
    const user = getQueryVariable('user') || ''
    const { pcFloorList, h5FloorList, setType, setTemplate, emailFlag, pcFlag, changePcpage, emailFloorList, onPageSizeChange } = this.props.createStore
    const { setSiteView } = this.props.viewStore
    const { getSitesList } = this.props.siteStore

    const isTemplate = user === 'admin'
    setTemplate(isTemplate)

    getSitesList()

    if (isTemplate) {
      getTemplate(id)
        .then(data => {
          if (!data) return
          const { type, site_id, name } = data.data
          site_id && this.props.createStore.setSiteId(site_id)
          this.setState({
            type,
            site_id,
            name
          })
          setType(type || 1)

          const { component_json = {} } = data.data.content
          if (type == 3) {
            changePcpage(emailFlag)
            const { email = [] } = component_json
            emailFloorList.push(...email)
            return
          } else {
            changePcpage(pcFlag)
          }
          const { pc = [], h5 = [] } = component_json
          let pageSize = '100%'
          if (pc.length > 0) {
            if (pc[0].props.styles.maxWidth) {
              pageSize = pc[0].props.styles.maxWidth
            }
          }
          onPageSizeChange(pageSize)

          pcFloorList.push(...pc)
          h5FloorList.push(...h5)
        })
        .catch(e => {
          console.log(e)
          toast('获取 方案详情数据失败，请刷新重试!', {
            type: 'error'
          })
        })
        .finally(() => {
          this.hideLoading()
        })
      return
    }

    //获取 方案详情数据
    getSchemes(id)
      .then(data => {
        if (!data) return
        const { type, site_id, name } = data.data
        site_id && this.props.createStore.setSiteId(site_id)
        this.setState({
          type,
          site_id,
          name
        })
        if (type == 2) {
          setSiteView('image')
        }

        setType(type || 1)
        const { component_json = {} } = data.data.content
        if (type == 3) {
          changePcpage(emailFlag)
          const { email = [] } = component_json
          emailFloorList.push(...email)
          return
        } else {
          changePcpage(pcFlag)
        }
        const { pc = [], h5 = [] } = component_json
        //更新数据 先清空
        pc.forEach((element, index) => {
          element.children.forEach((arg, key) => {
            if (arg.name !== 'PcRowContent' && arg.name !== 'PcSwiperItem') {
              let _item = arg
              element.children[key] = {
                id: new Date().getTime() + index * key,
                name: 'PcRowContent',
                children: [_item],
                props: {
                  styles: { width: '100%' }
                }
              }
            }
          })
        })

        //更新数据 先清空
        h5.forEach((element, index) => {
          element.children.forEach((arg, key) => {
            if (arg.name !== 'H5RowContent' && arg.name !== 'H5SwiperItem') {
              let _item = arg
              element.children[key] = {
                id: new Date().getTime() + index * key,
                name: 'H5RowContent',
                children: [_item],
                props: {
                  styles: { width: '100%' }
                }
              }
            }
          })
        })

        let pageSize = '1200px'
        if (pc.length > 0) {
          if (pc[0].props.styles.maxWidth) {
            pageSize = pc[0].props.styles.maxWidth
          }
        }

        pcFloorList.push(...pc)
        h5FloorList.push(...h5)
        onPageSizeChange(pageSize)
      })
      .catch(e => {
        console.log(e)
        toast('获取 方案详情数据失败，请刷新重试!', {
          type: 'error'
        })
      })
      .finally(() => {
        this.hideLoading()
      })
  }
  render() {
    const { recoverFloorList, historyFloorList, pageFlag, changePcpage, pcFlag, h5Flag, typeName, type, emailFlag, isTemplate, goBackFloorData, recoverFloorData } = this.props.createStore
    const { buildHtml, loading, show } = this.state
    return (
      <Div>
        {/* 顶部 */}
        <BannerWraper>
          <Left>
            <p>
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  this.props.createStore.isTemplate ? (location.href = '/templates') : (location.href = '/')
                }}
              >
                {'返回列表 >'}
              </span>
              <span>
                {' '}
                {this.props.siteStore.getSiteName(this.state.site_id)} - {this.state.name}{' '}
              </span>
            </p>

            {pageFlag !== emailFlag && <ProductDetail />}
          </Left>
          <FlexLine>
            {pageFlag === emailFlag ? (
              <TopTxt>邮件</TopTxt>
            ) : (
              <Fragment>
                <TopTxt ac={pageFlag === pcFlag} onClick={() => changePcpage(pcFlag)}>
                  <PCSelect />
                </TopTxt>
                <TopTxt ac={pageFlag === h5Flag} onClick={() => changePcpage(h5Flag)}>
                  手机端
                </TopTxt>
              </Fragment>
            )}
          </FlexLine>
          <Right>
            <HeaderP>
              {isTemplate ? '模板' : '方案'}-类型：{typeName}
            </HeaderP>
            <SvgButton isActive={historyFloorList.length > 0} onClick={goBackFloorData}>
              <svg t='1682660140909' class='icon' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg' p-id='5222' width='32' height='32'>
                <path d='M396.8 200.533333l64 64L384 341.333333h298.666667c119.466667 0 213.333333 93.866667 213.333333 213.333334s-93.866667 213.333333-213.333333 213.333333H298.666667v-85.333333h384c72.533333 0 128-55.466667 128-128s-55.466667-128-128-128H170.666667l226.133333-226.133334z' fill='#26ABFF' p-id='5223'></path>
              </svg>
            </SvgButton>
            <SvgButton isActive={recoverFloorList.length > 0} onClick={recoverFloorData}>
              <svg version='1.1' id='图层_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 200' width='32' height='32'>
                <path
                  class='st0'
                  d='M175,83.3H75c-14.2,0-25,10.8-25,25s10.8,25,25,25h75V150H75c-23.3,0-41.7-18.3-41.7-41.7S51.7,66.7,75,66.7
	h58.3l-15-15l12.5-12.5C130.8,39.2,175,83.3,175,83.3z'
                  fill='#26ABFF'
                />
              </svg>
            </SvgButton>
            {type == 2 ? (
              <Link to='/alertPreview' target='_blank'>
                <SvgButton isActive={true} onClick={this.handleReview}>
                  <svg t='1682661035296' class='icon' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg' p-id='7268' width='32' height='32'>
                    <path d='M512 642.9c81.6 0 157.6-42.3 229.5-130.9-72-88.6-147.9-130.9-229.5-130.9S354.4 423.4 282.5 512c71.9 88.6 147.9 130.9 229.5 130.9z m0 62.3c-105.3 0-201.4-53.6-288.5-160.7-15.4-18.9-15.4-46 0-64.9C310.6 372.4 406.7 318.8 512 318.8s201.4 53.6 288.5 160.7c15.4 18.9 15.4 46 0 64.9C713.4 651.6 617.3 705.2 512 705.2z m0 0' fill='#26ABFF' p-id='7269'></path>
                    <path d='M512 540c15.5 0 28-12.5 28-28 0-15.4-12.5-28-28-28s-28 12.5-28 28c0 15.4 12.5 28 28 28z m0 71.9c-35.7 0-68.7-19-86.6-49.9-17.9-30.9-17.9-69 0-99.9 17.9-30.9 50.9-49.9 86.6-49.9 55.2 0 100 44.7 100 99.9 0 55.1-44.8 99.8-100 99.8z m0 0' fill='#26ABFF' p-id='7270'></path>
                    <path
                      d='M136 888V745c0-19.9-16.1-36-36-36s-36 16.1-36 36v155c0 33.1 26.9 60 60 60h155c19.9 0 36-16.1 36-36s-16.1-36-36-36H136zM136 136h143c19.9 0 36-16.1 36-36s-16.1-36-36-36H124c-33.1 0-60 26.9-60 60v155c0 19.9 16.1 36 36 36s36-16.1 36-36V136zM888 136v143c0 19.9 16.1 36 36 36s36-16.1 36-36V124c0-33.1-26.9-60-60-60H745c-19.9 0-36 16.1-36 36s16.1 36 36 36h143zM888 888H745c-19.9 0-36 16.1-36 36s16.1 36 36 36h155c33.1 0 60-26.9 60-60V745c0-19.9-16.1-36-36-36s-36 16.1-36 36v143z'
                      fill='#26ABFF'
                      p-id='7271'
                    ></path>
                  </svg>
                </SvgButton>
              </Link>
            ) : (
              <Link to={'/preview'} target='_blank'>
                <SvgButton isActive={true} style={{ marginLeft: '6px' }} onClick={this.handleNewReview}>
                  <svg t='1682661035296' class='icon' viewBox='0 0 1024 1024' version='1.1' xmlns='http://www.w3.org/2000/svg' p-id='7268' width='32' height='32'>
                    <path d='M512 642.9c81.6 0 157.6-42.3 229.5-130.9-72-88.6-147.9-130.9-229.5-130.9S354.4 423.4 282.5 512c71.9 88.6 147.9 130.9 229.5 130.9z m0 62.3c-105.3 0-201.4-53.6-288.5-160.7-15.4-18.9-15.4-46 0-64.9C310.6 372.4 406.7 318.8 512 318.8s201.4 53.6 288.5 160.7c15.4 18.9 15.4 46 0 64.9C713.4 651.6 617.3 705.2 512 705.2z m0 0' fill='#26ABFF' p-id='7269'></path>
                    <path d='M512 540c15.5 0 28-12.5 28-28 0-15.4-12.5-28-28-28s-28 12.5-28 28c0 15.4 12.5 28 28 28z m0 71.9c-35.7 0-68.7-19-86.6-49.9-17.9-30.9-17.9-69 0-99.9 17.9-30.9 50.9-49.9 86.6-49.9 55.2 0 100 44.7 100 99.9 0 55.1-44.8 99.8-100 99.8z m0 0' fill='#26ABFF' p-id='7270'></path>
                    <path
                      d='M136 888V745c0-19.9-16.1-36-36-36s-36 16.1-36 36v155c0 33.1 26.9 60 60 60h155c19.9 0 36-16.1 36-36s-16.1-36-36-36H136zM136 136h143c19.9 0 36-16.1 36-36s-16.1-36-36-36H124c-33.1 0-60 26.9-60 60v155c0 19.9 16.1 36 36 36s36-16.1 36-36V136zM888 136v143c0 19.9 16.1 36 36 36s36-16.1 36-36V124c0-33.1-26.9-60-60-60H745c-19.9 0-36 16.1-36 36s16.1 36 36 36h143zM888 888H745c-19.9 0-36 16.1-36 36s16.1 36 36 36h155c33.1 0 60-26.9 60-60V745c0-19.9-16.1-36-36-36s-36 16.1-36 36v143z'
                      fill='#26ABFF'
                      p-id='7271'
                    ></path>
                  </svg>
                </SvgButton>
              </Link>
            )}
            <Fragment>
              {isTemplate ? (
                <Fragment>
                  <ResponseButton
                    onClick={() => {
                      this.handleRecordTemplate(1)
                    }}
                    style={{ margin: '0 20px' }}
                  >
                    保存草稿
                  </ResponseButton>
                  <ResponseButton
                    onClick={() => {
                      this.handleRecordTemplate(2)
                    }}
                  >
                    发布
                  </ResponseButton>
                </Fragment>
              ) : (
                <ResponseButton onClick={this.handleRecord}>
                  保存
                </ResponseButton>
              )}
            </Fragment>

            <Fragment>
              {isTemplate ? null : (
                <Fragment>
                  {pageFlag === emailFlag ? (
                    <EmailPreBtn />
                  ) : (
                    <Fragment>
                      {window.location.search.indexOf('save=old') > -1 && <ResponseButton onClick={this.handleExport}>保存并导出</ResponseButton>}
                      {<ResponseButton onClick={this.handleNewExport}>保存并导出</ResponseButton>}
                      {
                        <ResponseSubButton
                          style={{ marginLeft: '10px' }}
                          onClick={() => {
                            this.handleNewExport('pc')
                          }}
                        >
                          导出(PC)
                        </ResponseSubButton>
                      }
                      {
                        <ResponseSubButton style={{ marginLeft: '10px' }} onClick={() => this.handleNewExport('h5')}>
                          导出(H5)
                        </ResponseSubButton>
                      }
                    </Fragment>
                  )}
                </Fragment>
              )}
            </Fragment>
          </Right>
        </BannerWraper>
        {/* 内容区 */}
        <CreatePage type={type} isTemplate={isTemplate} />
        <Modal visible={show} onClose={() => this.setState({ show: false })}>
          <div style={{ padding: '20px' }}>
            <Textarea
              styles={{ width: '50vw', height: '600px' }}
              onRef={cur => {
                this.textArea = cur
              }}
            >
              {buildHtml}
            </Textarea>
            <div style={{ textAlign: 'right' }}>
              <AntButton onClick={this.copyCode}>全选复制</AntButton>
            </div>
          </div>
        </Modal>
        {/* loadding */}
        <Modal hascloseBtn={false} visible={loading} onClose={() => this.setState({ loading: false })}>
          <Loading />
        </Modal>
        {/* 打包遇到老组件时展示的弹窗 */}
        <Modal hascloseBtn visible={this.state.deprecatedComponentNotice !== ''} onClose={() => this.setState({ deprecatedComponentNotice: '' })}>
          <DeprecatedComponentNotice>{this.state.deprecatedComponentNotice}</DeprecatedComponentNotice>
        </Modal>
        <ToastContainer />
      </Div>
    )
  }
}

export default Create

import { axiosDelete, axiosGet, axiosPost, axiosPut, S3_URL } from './axiosCore';

export async function savePageData (data = {}) {
  return axiosPost(`/apiproxy/schemes`, {
    ...data
  });
}

export async function saveSchemes (id, data = {}) {
  return axiosPut(`/apiproxy/schemes/${id}`, {
    ...data
  });
}

export async function getProductsDetail (id, site_id) {
  return axiosGet(`/apisaas/products/${id}`, {}, {
    'site-id': site_id,
    'language': 'en',
    'Accept-Language': 'en'
  });
}

export async function getSchemes (id) {
  return axiosGet(`/apiproxy/schemes/${id}`);
}


export function saveTemplate (id, data = {}) {
  return axiosPut(`/apiproxy/templates/${id}`, {
    ...data
  });
}

export function getTemplate (id) {
  return axiosGet(`/apiproxy/templates/${id}`);
}

export async function saveAndPackagePageData (data = {}) {
  return axiosPost('/template/package', {
    ...data
  });
}

export async function getPageDetail (template_id) {
  return axiosGet(`/apiproxy/schemes/history/store`, { template_id });
}

export async function exportPageHtml (template_id) {
  return axiosPost(`/apiproxy/schemes/export/${template_id}`, {
    template_id
  });
}

export async function getTemplateList (params) {
  return axiosGet(`/apiproxy/templates`, params)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error;
    });
}

export async function getSchemesList (params) {
  return axiosGet(`/apiproxy/schemes`, params)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error;
    });
}

export async function searchTemplate (params) {
  return axiosGet(`/apiproxy/templates`, params)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error;
    });
}

export async function searchSchemes (params) {
  return axiosGet(`/apiproxy/schemes`, params)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error;
    });
}

export async function deleteTemplate (params) {
  return axiosDelete(`/apiproxy/templates/${params.id}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error;
    });
}

export async function deleteSchemes (params) {
  return axiosDelete(`/apiproxy/schemes/${params.id}`)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error;
    });
}

export async function createTemplate (params) {
  return axiosPost(`/apiproxy/templates`, params)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error;
    });
}

export async function createSchemes (params) {
  return axiosPost(`/apiproxy/schemes`, params)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error;
    });
}

export async function copyTemplate (params) {
  return axiosPost(`/apiproxy/templates/${params.id}/copy`, params)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error;
    });
}

export async function copySchemes (params) {
  return axiosPost(`/apiproxy/schemes/${params.id}/copy`, params)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error;
    });
}

export async function renameTemplate (params) {
  return axiosPut(`/apiproxy/templates/${params.id}`, params)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error;
    });
}

export async function renameSchemes (params) {
  return axiosPut(`/apiproxy/schemes/${params.id}`, params)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error;
    });
}

export async function saveTemplateInfo (params) {
  return axiosPost(`/apiproxy/templates/history/store`, params)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error;
    });
}

export async function getTemplateInfo (params) {
  return axiosGet(`/apiproxy/templates/history/store`, params)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error;
    });
}

export async function exportScheme (id) {
  return axiosGet(`/apiproxy/schemes/export/${id}`, null)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error;
    });
}

export async function exportTemplate (id) {
  return axiosGet(`/apiproxy/templates/export/${id}`, null)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error;
    });
}

export async function getTranslateJson (lan, jsonName) {
  return axiosGet(`/localesTran`, { lan: lan, name: jsonName })
    .then(response => {
      return response;
    })
    .catch(error => {
      return error;
    });
}

export async function getTranslateData (jsonName) {
  const allData = await Promise.all([
    getTranslateJson('en', jsonName),
    getTranslateJson('es', jsonName),
    getTranslateJson('de', jsonName),
    getTranslateJson('pt', jsonName),
  ]);
  const [enJson, esJson, deJson,ptJson] = allData;
  return {
    enJson,
    esJson,
    deJson,
    ptJson
  }
}

export async function getPolicies () {
  return axiosGet(`/apiproxy/init/policies`)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error;
    });
}

export {
  S3_URL,
  axiosGet,
  axiosPost,
  axiosPut,
  axiosDelete
}

//编辑构建页面数据
import { observable, action } from "mobx";
//pc page floor list
let pcFloorList = observable([]);
// h5 page floor list
let h5FloorList = observable([]);

let emailFloorList = observable([]);

//历史数据,回退数组
let historyFloorList = observable([]);
//恢复数组
let recoverFloorList = observable([]);

class CreateStore {
  //构建pc/h5
  pcFlag = "pc";
  h5Flag = "h5";
  emailFlag = "email";
  //页面类型

  @observable type = 1; //1:方案，2:弹窗，3:邮件
  @observable typeName = "方案"; //1:方案，2:弹窗，3:邮件
  @action setType = (type) => {
    this.type = Number(type);
    this.typeName = this.parsTypeName;
  };

  @observable siteId = 1;
  @action setSiteId = (siteId) => {
    this.siteId = siteId;
  };

  @observable isTemplate = false;
  @action setTemplate = (isTemplate) => {
    this.isTemplate = isTemplate;
  };

  get parsTypeName() {
    switch (this.type) {
      case 1:
        return "页面";
      case 2:
        return "弹窗";
      case 3:
        return "邮件";
      case 4:
        return "楼层";
      default:
        return "页面";
    }
  }

  // 从树形的配置结构中找到指定id的元素
  getElById = (elId, list = this.floorList) => {
    for (const item of list) {
      if (item.id === elId) {
        return item;
      } else if (item.children) {
        const box = this.getElById(elId, item.children);
        if (box) {
          return box;
        }
      }
    }
  };

  getParent = (elId, list = this.floorList, parent) => {
    for (const item of list) {
      if (item.id === elId || (item.props && item.props.id === elId)) {
        // TODO 这里不知道为什么组件的id和组件的props的id不一致
        return parent;
      }
      if (item.children) {
        const parent = this.getParent(elId, item.children, item);
        if (parent) {
          return parent;
        }
      }
    }
  };

  getListContainingEl = (elId, list = this.floorList) => {
    for (const item of list) {
      if (item.id === elId) {
        return list;
      } else if (item.children) {
        const list = this.getListContainingEl(elId, item.children);
        if (list) {
          return list;
        }
      }
    }
  };

  //页面状态 pc/h5
  @observable pageFlag = this.pcFlag;
  @action changePcpage = (flag) => {
    this.pageFlag = flag;
  };

  @observable pageSize = "100%";
  @action onPageSizeChange = (pageSize) => {
    this.pageSize = pageSize;
    this.floorList.forEach((floor) => {
      const { styles = {} } = floor.props;
      floor.props.styles = { ...styles, maxWidth: pageSize };
    });
  };
  //
  // pc/h5页面数据列表
  pcFloorList = pcFloorList;
  h5FloorList = h5FloorList;
  emailFloorList = emailFloorList;
  historyFloorList = historyFloorList;
  recoverFloorList = recoverFloorList;
  @action resetFloorData = () => {
    this.pcFloorList.length = 0;
    this.h5FloorList.length = 0;
    this.emailFloorList.length = 0;
    this.historyFloorList.length = 0;
    this.recoverFloorList.length = 0;
  };

  get baseFloorItem() {
    let baseName = this.pageFlag == this.pcFlag ? "PcBaseBox" : "H5BaseBox";

    if (this.pageFlag === this.emailFlag) {
      baseName = "EmailBaseBox";
    }
    return {
      name: baseName,
      children: [],
      id: new Date().getTime(),
      props: {
        styles: {
          maxWidth: this.pageFlag === this.emailFlag ? "632px" : this.pageSize,
        },
      },
    };
  }

  get baseSwiperItem() {
    const baseName = this.pageFlag == this.pcFlag ? "PcSwiper" : "H5Swiper";
    return {
      name: baseName,
      children: [],
      id: new Date().getTime(),
      props: {
        styles: {},
      },
    };
  }

  get baseEmailItem() {
    const baseName = "EmailBaseBox";
    return {
      name: baseName,
      children: [],
      id: new Date().getTime(),
      props: {
        styles: {},
      },
    };
  }
  get baseAlertItem() {
    const baseName = this.pageFlag == this.pcFlag ? "PcAlert" : "H5Alert";
    return {
      name: baseName,
      children: [],
      id: new Date().getTime(),
      props: {
        styles: {},
      },
    };
  }
  //获取页面json数据
  get html_json() {
    if (this.pageFlag === this.emailFlag) {
      return {
        email: emailFloorList,
      };
    }
    return {
      h5: h5FloorList,
      pc: pcFloorList,
    };
  }
  // floordata
  get floorList() {
    if (this.pageFlag === this.pcFlag) {
      return pcFloorList;
    }

    if (this.pageFlag === this.h5Flag) {
      return h5FloorList;
    }

    if (this.pageFlag === this.emailFlag) {
      return emailFloorList;
    }
    return pcFloorList;
  }
  @action preview = () => {
    localStorage.setItem("html_json", JSON.stringify(this.html_json));
  };

  //设置当前数据为历史数据
  setHistoryFloorData = ({ pcFloorList, h5FloorList, emailFloorList }) => {
    this.historyFloorList.push({ pcFloorList, h5FloorList, emailFloorList });
  };

  //快速创建页面
  @action initFloorData = ({ h5_json, pc_json }) => {
    this.pcFloorList.splice(0);
    this.pcFloorList.push(...pc_json);
    this.h5FloorList.splice(0);
    this.h5FloorList.push(...h5_json);
    // this.floorList = this.pageFlag === this.pcFlag ? pc_json : h5_json;
  };

  //操作回退
  @action goBackFloorData = () => {
    if (this.historyFloorList.length <= 0) {
      return;
    }
    //将数据推入恢复数据的栈
    this.recoverFloorList.push({
      pcFloorList: JSON.parse(JSON.stringify(this.pcFloorList)),
      h5FloorList: JSON.parse(JSON.stringify(this.h5FloorList)),
      emailFloorList: JSON.parse(JSON.stringify(this.emailFloorList)),
    });
    let pre_data = this.historyFloorList.pop();
    //设置当前数据为上一步操作的数据
    this.pcFloorList.splice(0);
    this.pcFloorList.push(...pre_data.pcFloorList);
    this.h5FloorList.splice(0);
    this.h5FloorList.push(...pre_data.h5FloorList);
    this.emailFloorList.splice(0);
    this.emailFloorList.push(...pre_data.emailFloorList);
  };
  //恢复
  @action recoverFloorData = () => {
    if (this.recoverFloorList.length <= 0) {
      return;
    }
    //将数据推入历史数据的栈
    this.historyFloorList.push({
      pcFloorList: JSON.parse(JSON.stringify(this.pcFloorList)),
      h5FloorList: JSON.parse(JSON.stringify(this.h5FloorList)),
      emailFloorList: JSON.parse(JSON.stringify(this.emailFloorList)),
    });

    let recover_data = this.recoverFloorList.pop();
    //恢复当前数据为上一步操作的数据
    this.pcFloorList.splice(0);
    this.pcFloorList.push(...recover_data.pcFloorList);
    this.h5FloorList.splice(0);
    this.h5FloorList.push(...recover_data.h5FloorList);
    this.emailFloorList.splice(0);
    this.emailFloorList.push(...recover_data.emailFloorList);
  };
  //楼层数据 模块名 ，id，props
  //插入楼层 direction = up/down
  @action insertFloor = (direction = "up", inserFloorId) => {
    this.setHistoryFloorData({
      pcFloorList: JSON.parse(JSON.stringify(this.pcFloorList)),
      h5FloorList: JSON.parse(JSON.stringify(this.h5FloorList)),
      emailFloorList: JSON.parse(JSON.stringify(this.emailFloorList)),
    });
    const index = this.floorList.findIndex(
      (floor) => floor.id === inserFloorId
    );
    if (direction === "up") {
      //向上增加
      console.log("向上增加 --index", index);
      const insertFloorIdx = index;
      this.floorList.splice(insertFloorIdx, 0, this.baseFloorItem);
    } else {
      //向下增加
    }
  };
  //增加楼层
  @action addFloor = (floor_child = 1) => {
    this.setHistoryFloorData({
      pcFloorList: JSON.parse(JSON.stringify(this.pcFloorList)),
      h5FloorList: JSON.parse(JSON.stringify(this.h5FloorList)),
      emailFloorList: JSON.parse(JSON.stringify(this.emailFloorList)),
    });
    let baseFloorItem = this.baseFloorItem;
    let baseName =
      this.pageFlag === this.pcFlag ? "PcRowContent" : "H5RowContent";
    if (this.pageFlag === this.emailFlag) {
      baseName = "EmailRowContent";
    }

    for (let index = 0; index < floor_child; index++) {
      baseFloorItem.children.push({
        name: baseName,
        children: [],
        id: new Date().getTime() + index + 1,
        props: {
          styles: {
            width: `${(1 / floor_child) * 100}%`,
          },
        },
      });
    }
    this.floorList.push(baseFloorItem);
  };

  //向下新增楼层
  @action addFloorByActiveSelect = (floor_child = 1) => {
    this.setHistoryFloorData({
      pcFloorList: JSON.parse(JSON.stringify(this.pcFloorList)),
      h5FloorList: JSON.parse(JSON.stringify(this.h5FloorList)),
      emailFloorList: JSON.parse(JSON.stringify(this.emailFloorList)),
    });
    let baseFloorItem = this.baseFloorItem;
    let baseName =
      this.pageFlag === this.pcFlag ? "PcRowContent" : "H5RowContent";
    if (this.pageFlag === this.emailFlag) {
      baseName = "EmailRowContent";
    }

    for (let index = 0; index < floor_child; index++) {
      baseFloorItem.children.push({
        name: baseName,
        children: [],
        id: new Date().getTime() + index + 1,
        props: {
          styles: {
            width: `${(1 / floor_child) * 100}%`,
          },
        },
      });
    }
    const index = this.floorList.findIndex(
      (comp) => comp.id === this.activeFloorData.id
    );
    this.floorList.splice(index + 1, 0, baseFloorItem);
  };

  //粘贴楼层、分栏、组件信息
  @action copyContent = (copy_id, copy_info) => {
    if (!copy_id) {
      return;
    }
    this.setHistoryFloorData({
      pcFloorList: JSON.parse(JSON.stringify(this.pcFloorList)),
      h5FloorList: JSON.parse(JSON.stringify(this.h5FloorList)),
      emailFloorList: JSON.parse(JSON.stringify(this.emailFloorList)),
    });
    let copy_list = copyInfoByData(this.floorList, copy_id, copy_info);
  };

  //增加滑块
  @action addSwiper = (floor_child = 1, slidesPerView = 1) => {
    this.setHistoryFloorData({
      pcFloorList: JSON.parse(JSON.stringify(this.pcFloorList)),
      h5FloorList: JSON.parse(JSON.stringify(this.h5FloorList)),
      emailFloorList: JSON.parse(JSON.stringify(this.emailFloorList)),
    });
    let baseFloorItem = this.baseSwiperItem;
    baseFloorItem.props.slidesPerView = slidesPerView;

    const baseName =
      this.pageFlag == this.pcFlag ? "PcSwiperItem" : "H5SwiperItem";
    for (let index = 0; index < floor_child * slidesPerView; index++) {
      baseFloorItem.children.push({
        name: baseName,
        children: [],
        id: new Date().getTime() + index + 1,
        props: {
          styles: {
            width: `${(1 / slidesPerView) * 100}%`,
          },
        },
      });
    }
    this.floorList.push(baseFloorItem);
  };

  //增加弹窗楼层
  @action addAlert = (floor_child = 1) => {
    console.log("增加弹窗楼层");
    this.setHistoryFloorData({
      pcFloorList: JSON.parse(JSON.stringify(this.pcFloorList)),
      h5FloorList: JSON.parse(JSON.stringify(this.h5FloorList)),
      emailFloorList: JSON.parse(JSON.stringify(this.emailFloorList)),
    });
    let baseAlertItem = this.baseAlertItem;
    if (this.floorList.length > 0) {
      baseAlertItem.props.isHide = true;
    }
    const baseName =
      this.pageFlag == this.pcFlag ? "PcRowContent" : "H5RowContent";
    for (let index = 0; index < floor_child; index++) {
      baseAlertItem.children.push({
        name: baseName,
        children: [],
        id: new Date().getTime() + index + 1,
        props: {
          styles: {
            width: `${(1 / floor_child) * 100}%`,
          },
        },
      });
    }
    this.floorList.push(baseAlertItem);
  };

  //替换弹窗模板
  @action addAlertToFloor = (component_json) => {
    this.setHistoryFloorData({
      pcFloorList: JSON.parse(JSON.stringify(this.pcFloorList)),
      h5FloorList: JSON.parse(JSON.stringify(this.h5FloorList)),
      emailFloorList: JSON.parse(JSON.stringify(this.emailFloorList)),
    });
    let { pc = [], h5 = [] } = component_json;

    this.pcFloorList.splice(0, this.pcFloorList.length);
    this.h5FloorList.splice(0, this.h5FloorList.length);

    let _h5 = refreshFloorID(h5);
    let _pc = refreshFloorID(pc);

    this.pcFloorList.push(..._pc);
    this.h5FloorList.push(..._h5);
  };

  @action addTemplateToFloor = (component_json) => {
    this.setHistoryFloorData({
      pcFloorList: JSON.parse(JSON.stringify(this.pcFloorList)),
      h5FloorList: JSON.parse(JSON.stringify(this.h5FloorList)),
      emailFloorList: JSON.parse(JSON.stringify(this.emailFloorList)),
    });
    if (this.pageFlag === this.emailFlag) {
      let { email = [] } = component_json;
      let _email = refreshFloorID(email);
      this.emailFloorList.push(..._email);
      return;
    }

    let { pc = [], h5 = [] } = component_json;
    let _h5 = refreshFloorID(h5);
    let _pc = refreshFloorID(pc, this.pageSize);

    this.pcFloorList.push(..._pc);
    this.h5FloorList.push(..._h5);
  };

  //删除楼层
  @action delFloor = (floorId) => {
    this.setHistoryFloorData({
      pcFloorList: JSON.parse(JSON.stringify(this.pcFloorList)),
      h5FloorList: JSON.parse(JSON.stringify(this.h5FloorList)),
      emailFloorList: JSON.parse(JSON.stringify(this.emailFloorList)),
    });
    const floorList = this.getListContainingEl(floorId);
    const index = floorList.findIndex((floor) => floor.id === floorId);
    floorList.splice(index, 1);
  };
  //添加楼层子组件
  @action addFloorChild = ({ floorId, itemId, name }) => {
    this.setHistoryFloorData({
      pcFloorList: JSON.parse(JSON.stringify(this.pcFloorList)),
      h5FloorList: JSON.parse(JSON.stringify(this.h5FloorList)),
      emailFloorList: JSON.parse(JSON.stringify(this.emailFloorList)),
    });
    const child = getBaseChildItem(Date.now(), name);
    const box = this.getElById(itemId);
    box.children.push(child);
  };

  // 在分栏中添加楼层（子楼层）
  @action addSubFloor = ({ colNum, boxId }) => {
    this.setHistoryFloorData({
      pcFloorList: JSON.parse(JSON.stringify(this.pcFloorList)),
      h5FloorList: JSON.parse(JSON.stringify(this.h5FloorList)),
      emailFloorList: JSON.parse(JSON.stringify(this.emailFloorList)),
    });

    const baseFloorItem = this.baseFloorItem;
    const baseFloorItemName = baseFloorItem.name.toLowerCase();
    const name = baseFloorItemName.includes("email")
      ? "EmailRowContent"
      : baseFloorItemName.includes("pc")
      ? "PcRowContent"
      : "H5RowContent";
    let startId = Date.now() + 1000;
    for (let index = 0; index < colNum; index++) {
      baseFloorItem.children.push({
        name,
        children: [],
        id: startId + index + 1,
        props: {
          styles: {
            width: `${100 / colNum}%`,
            minHeight: "100px",
          },
        },
      });
    }

    const box = this.getElById(boxId);
    box.children.push(baseFloorItem);
  };
  //更新 数据props 属性
  @action updateDataProps = (key, newData) => {
    this.setHistoryFloorData({
      pcFloorList: JSON.parse(JSON.stringify(this.pcFloorList)),
      h5FloorList: JSON.parse(JSON.stringify(this.h5FloorList)),
      emailFloorList: JSON.parse(JSON.stringify(this.emailFloorList)),
    });
    const targetEl = this.getElById(this.seletedData.id);
    targetEl.props[key] = newData;
  };
  //移动楼层  direction = up/down
  @action moveFloor = (direction, floorId) => {
    this.setHistoryFloorData({
      pcFloorList: JSON.parse(JSON.stringify(this.pcFloorList)),
      h5FloorList: JSON.parse(JSON.stringify(this.h5FloorList)),
      emailFloorList: JSON.parse(JSON.stringify(this.emailFloorList)),
    });
    const floorList = this.getListContainingEl(floorId);
    const index = floorList.findIndex((floor) => floor.id === floorId);
    const floorLength = floorList.length;

    let inserIndex = "";
    if (direction === "up") {
      //上移动
      if (index <= 0) return; //第一个不可移动
      inserIndex = index - 1;
    } else {
      //下移动
      if (index >= floorLength) return;
      inserIndex = index + 1;
    }
    // 先删除
    const moveFloor = floorList.splice(index, 1);
    const insertFloorData = moveFloor[0];
    inserIndex !== undefined &&
      floorList.splice(inserIndex, 0, insertFloorData);
  };
  //选中楼层 id
  @observable slectedFloorId = "";
  @action setFloorId = (floorId) => {
    this.slectedFloorId = floorId;
    this.seletedRowId = "";
    this.seletedComponentId = "";
  };

  //选中当个组件id
  @observable seletedRowId = "";
  @action setRowId = (childId, floorId) => {
    this.slectedFloorId = floorId;
    this.seletedRowId = childId;
    this.seletedComponentId = "";
  };

  //选中当个组件id
  @observable seletedComponentId = "";
  @action setComponentId = (componentId) => {
    this.seletedComponentId = componentId;
  };
  @action historyFloorData = () => {
    this.setHistoryFloorData({
      pcFloorList: JSON.parse(JSON.stringify(this.pcFloorList)),
      h5FloorList: JSON.parse(JSON.stringify(this.h5FloorList)),
      emailFloorList: JSON.parse(JSON.stringify(this.emailFloorList)),
    });
  };
  //替换更新楼层数据
  @action updateFloorData = (floorData) => {
    const floorList = this.getListContainingEl(floorData.id);
    const index = floorList.findIndex((item) => item.id === floorData.id);
    floorList[index] = floorData;
  };

  @action updateRowData = (rowData) => {
    if (!this.seletedRowId || !this.slectedFloorId) {
      return;
    }

    const _floorData = { ...this.activeFloorData };
    _floorData.children.forEach((row, index) => {
      if (row.id === rowData.id) {
        _floorData.children[index] = rowData;
      }
    });

    this.updateFloorData(_floorData);
  };

  //获取选中楼层
  get activeFloorData() {
    return this.getElById(this.slectedFloorId) || {};
  }

  //获取选中分栏元素
  get activeRowData() {
    if (!this.slectedFloorId || !this.activeFloorData.children) return "";
    return this.getElById(this.seletedRowId) || "";
  }

  //获取选元素
  get activeComponentData() {
    if (!this.seletedComponentId || !this.activeRowData.children) return "";
    return this.getElById(this.seletedComponentId) || "";
  }

  //获取选中数据 选中楼层子组件，返回子组件数据，单单选中楼层，显示楼层数据
  get seletedData() {
    //选中了分栏元素
    if (this.seletedComponentId) {
      return this.activeComponentData;
    }

    //选中了分栏
    if (this.seletedRowId) {
      return this.activeRowData;
    }

    //选中了楼层
    if (this.slectedFloorId) {
      return this.activeFloorData;
    }

    return "";
  }
}

function refreshAlertFloorID(data) {
  data.forEach((flr, f_index) => {
    let f_num = f_index + 1;
    flr.props.styles.display = "flex";
    flr.id = parseInt(new Date().getTime() / 100) + f_num;
    flr.children.forEach((row, row_index) => {
      let row_num = f_num * (row_index + 1) * 10;
      row.id = parseInt(new Date().getTime() / 10) + row_num;
      row.children.forEach((comp, com_index) => {
        let com_num = (row_num + com_index + 1) * 100;
        comp.id = new Date().getTime() + com_num;
      });
    });
  });

  return data;
}

function getBaseChildItem(id, name) {
  return {
    name,
    id,
    props: {
      styles: {},
      id,
    },
  };
}

function refreshItemId(data) {
  data.forEach((item, index) => {
    item.id = Math.round(Math.random() * 10000000000);
    if (item.children && item.children.length > 0) {
      item.children = refreshItemId(item.children);
    }
  });

  return data;
}

function getRandomNumber() {
  return Math.floor(Math.random() * 9000000000) + 1000000000;
}

function refreshFloorID(data, pageSize) {
  data.forEach((flr, f_index) => {
    if (pageSize) {
      flr.props.styles.maxWidth = pageSize;
    }
    flr.id = getRandomNumber();
    flr.children.forEach((row, row_index) => {
      row.id = getRandomNumber();
      row.children.forEach((comp, com_index) => {
        comp.id = getRandomNumber();
      });
    });
  });

  return data;
}

//递归粘贴复制内容
function copyInfoByData(list, copy_id, copy_info) {
  list.forEach((item, index) => {
    if (item.id === copy_id) {
      list[index] = refreshItemId([copy_info])[0];
    } else {
      if (item.children && item.children.length > 0) {
        item.children = copyInfoByData(item.children, copy_id, copy_info);
      }
    }
  });

  return list;
}

export default CreateStore;

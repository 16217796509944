import componentMount from "../../lib/componentMount";

import EditContainer from "./EditPCAlert";
import Component from "./PcAlert";
import BaseStyle from "../../BaseBox/BaseStyle";
import staticRender from "./StaticRender.js";

const _Render = componentMount({
    Component:Component,
    Content:EditContainer,
    Style:BaseStyle,
    text:'弹窗'
})

export default {
    componentMount:_Render,
    name:'PcAlert',
    text:'弹窗',
    staticRender
}
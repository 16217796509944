import componentMount from "../../lib/componentMount";

import EditContainer from "./EditPcUserShow";
import Component from "./PcUserShow";
import EditBaseBox from "./EditPcUserShowStyles";
import staticRender from "./StaticRender.js";

const _Render = componentMount({
    Component:Component,
    Content:EditContainer,
    Style:EditBaseBox,
    text:'用户秀'
})

export default {
    componentMount:_Render,
    name:'PcUserShow',
    text:'用户秀',
    staticRender
}

import React from 'react';

export default function componentMount({Component,Content,Style,text}){
    return {
        renderComponent: (props) => <Component {...props} />,
        contentComponent: (props) => <Content {...props} />,
        styleComponent: (props) => <div>
            {Style ? <Style {...props} /> : ''}
        </div>,
        name:text
    }
}
import { toLine } from "../../../utils/transformCssPropertyName"

export default function staticRender ({ config, page_id = 0 }) {
  let { id, props = {} } = config
  id = `${page_id}-${id}`
  let {
    styles = {},
    isHide = false
  } = props

  const result = {
    styles: [
      {
        name: `.pt-h5-alert-${page_id}`,
        content: `
          .pt-h5-alert-inner-${page_id} {
            margin: 0 auto;
            box-sizing: border-box;
            background: #fff;
            max-width: 100%;
            display: flex;
          }
          .H5_Alert_By_Tool + .H5_Alert_By_Tool {
            display: none;
          }
        `
      },
      {
        name: `.pt-h5-alert-${id}`,
        content: `
          .pt-h5-alert-${id} {
            display: ${isHide && !window.isEdit ? 'none' : 'block'}
          }
          .pt-h5-alert-inner-${id} {
            ${Object.keys(styles).map(css => `${toLine(css)}:${styles[css]};`).join(' ')}
            background: ${styles.backgroundColor || '#fff'};
          }
        `
      }
    ],
    html: `
      <div class="pt-h5-alert-${page_id} pt-h5-alert-${id} H5_Alert_By_Tool">
        <div class="pt-h5-alert-inner-${page_id} pt-h5-alert-inner-${id}">
          {{CHILDREN_PLACEHOLDER}}
        </div>
      </div>
    `,
  }
  return result
}

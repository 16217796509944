import React, { Component, Fragment } from 'react';

import { inject, observer } from 'mobx-react';
import styled from 'styled-components';


@inject('createStore', 'viewStore')
@observer
class StyleProduct extends Component{
  render() {
    return (
       <Fragment>
        
       </Fragment>
    );
  }
}

export default StyleProduct
// pc 文本
import React from 'react';

function PcButton({ text = '按钮文本', styles = {}, align = '', href = "", hoverTextColor, hoverBgColor }) {
    return (
        <div
            style={
                {
                    display: 'flex',
                    width: '100%',
                    boxSizing: 'border-box',
                    justifyContent: align
                }
            }>
            <a
                href={href ? href : ''}
                style={{
                    cursor: href ? 'pointer' : 'default',
                    textDecoration: 'none',
                    color: styles.color ? styles.color : '#fff',

                }}
                onClick={(e) => { !href && e.preventDefault() }}
                target='_blank'
            >
                <div
                    style={{
                        width: '180px',
                        height: '35px',
                        lineHeight: '35px',
                        fontSize: '14px',
                        textAlign: 'center',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        background: '#26ABFF',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#fff',
                        ...styles,
                    }} dangerouslySetInnerHTML={{ __html: text }}></div>
            </a>
        </div>
    )
}
export default PcButton;


import React, { Fragment, Component } from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';

import { Modal, Radio } from 'antd';
import pageBasePars from "../../pageComponents/pageBasePars";

import RowContent from "./RowContent";
import Icon from '../Icon';
import { onContentCopy, onContentPaste } from '../../utils/copy';

@inject('createStore')
@observer
class FloorContent extends Component {
  state = {
    hoverId: 0,
    isAddSubFloorModalVisible: false,
    subFloorNum: 2
  }

  onAddSubFloorModalOk = () => {
    this.props.createStore.addFloorByActiveSelect(this.state.subFloorNum);
    this.setState({
      subFloorNum: 2,
      isAddSubFloorModalVisible: false,
    })
  }

  onAddSubFloorModalCancel = () => {
    this.setState({
      subFloorNum: 2,
      isAddSubFloorModalVisible: false,
    })
  }

  get HoverOperationButtons(){
    const { setFloorId } = this.props.createStore;
    return [
      {
        tex: <Fragment>
          <Icon icon={'icon-ellypsis'} style={{ fontSize: '16px', fontWeight: '800' }} />
          <OperationText>编辑楼层</OperationText>
        </Fragment>,
        click: (floor) => {
          setFloorId(floor.id);
        }
      }
    ]
  }

  //选中，删除，上/下移动，向上增加
  get OperationButtons() {
    const { setFloorId, pageFlag, delFloor, copyContent,moveFloor, isTemplate, type } = this.props.createStore;
    //弹窗模版没有其他操作
    if (type === 2 && !isTemplate){
      return []
    }
    const list = [
      {
        tex: <Fragment>
          <OperationText>复制</OperationText>
        </Fragment>,
        click: (floor) => {
          onContentCopy(floor)
        }
      }, {
        tex: <Fragment>
          <OperationText>粘贴</OperationText>
        </Fragment>,
        click: (floor) => {
          onContentPaste(floor, copyContent)
        }
      },
      {
        tex: <Fragment>
          <Icon icon={'icon-arrow-down2'} style={{ fontSize: '16px', fontWeight: '800' }} />
          <OperationText>下移</OperationText>
        </Fragment>,
        click: (floor) => {
          moveFloor('down', floor.id);
        }
      },
      {
        tex: <Fragment>
          <Icon icon={'icon-arrow-up'} style={{fontSize:'16px',fontWeight:'800'}} />
          <OperationText>上移</OperationText>
        </Fragment>,
        click: (floor) => {
          moveFloor('up', floor.id);
        }
      },
      {
        tex: <Fragment>
          <Icon icon={'icon-plus'} style={{ fontSize: '16px', fontWeight: '800' }} />
        </Fragment>,
        click: () => {
          this.setState({
            isAddSubFloorModalVisible: true,
          })
        }
      },
      {
        tex: <Fragment>
          <Icon icon={'icon-delete'} style={{ fontSize: '16px', fontWeight: '800' }} />
          <OperationText>删除</OperationText>
        </Fragment>,
        click: (floor) => {
          delFloor(floor.id);
        }
      },
      {
        tex: <Fragment>
          <OperationText>取消</OperationText>
        </Fragment>,
        click: () => {
          setFloorId('')
        }
      }
    ]
    return list;
  }


  floorHover = (id) => {
    this.setState({
      hoverId: id
    })
  }

  floorHoverLeave = () => {
    this.setState({
      hoverId: 0
    })
  }

  render() {

    const { floorData, showOperation = true } = this.props

    const {
      pageFlag,
      h5Flag,
      seletedData,
      setFloorId,
      activeFloorData
    } = this.props.createStore;

    let { name, id, props, children } = floorData;

    const BaseBox = pageBasePars.render(name)

    const floorId = id;
    props = { ...props, isEdit: true }

    return (
      <Floor
        {...props}
        style={{
          width: props.styles.width ? props.styles.width : '100%',
          maxWidth: props.styles.maxWidth ? props.styles.maxWidth : '100%',
          margin: '0 auto',
        }}
        // onClick={(e) => {
        //   e.stopPropagation();
        //   e.preventDefault();
        //   setFloorId(floorId)
        // }}
        active={id === seletedData.id}
        onDragOver={(e) => {
          e.preventDefault();
        }}
        onMouseEnter={() => {
          this.floorHover(floorId)
        }}
        onMouseLeave={this.floorHoverLeave}
        hasChildren={children.length > 0}
      >
        <Operation
          isShow={(this.state.hoverId === floorId) && (id !== seletedData.id) && activeFloorData.id != floorId }
          // isTop={slectedFloorId === floorId}
          isH5={pageFlag === h5Flag}
        >
          {this.HoverOperationButtons.map((item, idx) => {
            const { tex, click } = item;
            return (
              <OperationButton
                key={idx}
                onClick={() => click(floorData)}>
                <OperationButtonInner>
                  {tex}
                </OperationButtonInner>
              </OperationButton>
            )
          })}
        </Operation>
        <Operation 
          isShow={id === seletedData.id} 
          isH5={pageFlag === h5Flag}
        >
          <Fragment>
            {this.OperationButtons.map((item, idx) => {
              const { tex, click } = item;
              return (
                <OperationButton
                  key={idx}
                  onClick={() => click(floorData)}>
                  <OperationButtonInner>
                    {tex}
                  </OperationButtonInner>
                </OperationButton>
              )
            })}
          </Fragment>
        </Operation>
        <BaseBox {...props}>
          {
            children.map((row, index) => (
              <RowContent key={index} rowData={row} floorData={floorData} />
            ))
          }
        </BaseBox>
        <Modal
          centered
          getContainer="body"
          title="向下新增楼层"
          okText="确认"
          cancelText="取消"
          visible={this.state.isAddSubFloorModalVisible}
          onOk={this.onAddSubFloorModalOk}
          onCancel={this.onAddSubFloorModalCancel}
        >
          <p>请选择分栏数量：</p>
          <Radio.Group
            name="radiogroup"
            value={this.state.subFloorNum}
            onChange={e => this.setState({ subFloorNum: e.target.value })}
          >
            {[1, 2, 3, 4, 5, 6].map(val => (
              <Radio value={val} key={val}>{val}</Radio>
            ))}
          </Radio.Group>
        </Modal>
      </Floor>
    );
  }
}

export default FloorContent



const Floor = styled.div(({ style = {}, active, hasChildren }) => ({
  minHeight: hasChildren ? 'unset' : '50px',
  border: active ? '2px solid red' : '2px dashed #efefef',
  position: 'relative',
  cursor: 'pointer',
  background: '#fff',
  width: '100%',
  ...style

}))

//楼层操作
const Operation = styled.div({
  position: 'absolute',
  display:'flex',
  alignItems: 'center',
  right: 0,
  top: props => props.isTop ? '-50px': '0',
  height: '42px',
  transform: 'translateY(-100%)',
  zIndex: 99,
  backgroundColor: 'rgba(70, 79, 96, .95)',
  borderRadius:'8px',
  boxShadow:'0 0 0 1px rgba(255,255,255,.4), 4px 4px 6px rgba(0,0,0,.5)',
  visibility: props => props.isShow ? 'visible' : 'hidden'
});
const OperationText = styled.div({
  marginLeft:'5px'
})

const OperationButtonInner = styled.div({
  borderRight: '1px solid rgba(255, 255, 255, .3)',
  padding: '2px 10px',
  display: 'flex',
  alignItems: 'center',
  height:'25px',
})
const OperationButton = styled.div({
  cursor: 'pointer',
  padding: '10px 0',
  color: '#fff',
  borderRadius: '8px',
  background:'unset',
  ":hover": {
    background: 'rgba(0,0,0,0.2)',
    color: '#fff'
  }
})
import React from 'react';

function EmailImg({ imgSrc, href, boxWidth = 100, align, styles, imgH, imgW }) {
    if (!imgSrc) {
        imgSrc = 'https://df5apg8r0m634.cloudfront.net/images/4ca7c2e83a08d533a1d3997c514b232c.jpg'
    }
    let imgStyle = { width: imgW || '100%', height: imgH || '' }
    return (
        <div style={
            {
                display: 'flex',
                width: '100%',
                boxSizing: 'border-box',
                justifyContent: align
            }
        }>
            {href ? <a
                style={{
                    fontSize: 0,
                    width: `${boxWidth}%`,
                    cursor: href ? 'pointer' : 'default',
                    ...styles
                }}
                href={href ? href : ''}
                onClick={(e) => { !href && e.preventDefault() }}
                target='_blank'
                rel="noreferrer">
                <img src={imgSrc} style={imgStyle} alt='' />
            </a> : <div
                style={{
                    fontSize: 0,
                    width: `${boxWidth}%`,
                    ...styles
                }}>
                <img src={imgSrc} style={imgStyle} alt='' />
            </div>}
        </div>
    )
}

export default EmailImg;
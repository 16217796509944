import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { ContentTitle, FlexLine } from '../../../style';
import styled from 'styled-components';

import { Input, WhiteSpace } from '../../../components/index';

const Lable = styled.label({
    margin:'0 10px'
})

@inject('createStore')
@observer

class EditH5AlertCouponStyles extends Component {

    handleRefreshStyle = (key, value) => {
        //更新某个组件，或则楼层样式属性
        const { seletedData, updateDataProps } = this.props.createStore;
        const { props } = seletedData;
        const { styles } = props;
        let updataData = {
            ...styles,
            [key]: value
        };
        updateDataProps('styles', updataData);
    }
    
    editBgColor (backgroundColor) {
        return (
            <Fragment>
                <ContentTitle>按钮颜色:</ContentTitle>
                <div style={{ padding: '0 10px' }}>
                    <FlexLine justify='space-between'>
                        <Input value={backgroundColor} type="color" name='backgroundColor' onChange={(e) => this.handleRefreshStyle('backgroundColor', `${e.target.value}`)} style={{ width: '28px', padding: '2px' }} />
                        <Input type="text" name='backgroundColor' placeholder={'选中颜色/输入颜色'} value={backgroundColor || ''} onChange={(e) => this.handleRefreshStyle('backgroundColor', `${e.target.value}`)} />
                    </FlexLine>
                </div>
            </Fragment>
        )
    }

    editTextColor (color) {
        return (
            <Fragment>
                <ContentTitle>按钮文字颜色:</ContentTitle>
                <div style={{ padding: '0 10px' }}>
                    <FlexLine justify='space-between'>
                        <Input value={color} type="color" name='color' onChange={(e) => this.handleRefreshStyle('color', `${e.target.value}`)} style={{ width: '28px', padding: '2px' }} />
                        <Input type="text" name='color' placeholder={'选中颜色/输入颜色'} value={color || ''} onChange={(e) => this.handleRefreshStyle('color', `${e.target.value}`)} />
                    </FlexLine>
                </div>
            </Fragment>
        )
    }

    render() {
        const { styles =  {} } = this.props;
        const {
            backgroundColor,
            color,
        } = styles;

        return (
            <Fragment>
                {
                    this.editBgColor(backgroundColor)
                }
                {
                    this.editTextColor(color)
                }
            </Fragment>
        )
    }
}
export default EditH5AlertCouponStyles;
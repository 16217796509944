import React, { Fragment, Component } from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { Modal } from '../../../pageComponents/pc/PcComponent';
import { FlexLine, Input } from '../../../style';
import AlertList from './AlertList';

import { getTemplate, getTemplateList, searchTemplate } from '../../../api';
import { Loading } from '../..';

import Pagination from '../../Main/Pagination';


@inject(({ createStore }) => ({
  addAlertToFloor: createStore.addAlertToFloor
}))
@observer
class TemplateModal extends Component {
  state = {
    activeId:0,
    page:1,
    per_page:15,
    loading:false,
    searchStr:"",
    list:[],
    total:0
  }

  componentDidMount(){
    this.getList()
  }

  getList = () => {
    this.setState({
      loading: true
    })
    getTemplateList({
      page: this.state.page,
      per_page: this.state.per_page,
      type:2,
      status: 2
    }).then((arg) => {
      const { data, meta  } = arg
      this.setState({
        list: data,
        total: meta.total,
        loading: false
      })
    }).catch((e) => {
      this.setState({
        loading: false
      })
      console.log('获取列表失败', e);
    })
  }

  onChange = (e) => {
    this.setState({
      searchStr: e.target.value
    })
  }


  onSearch = () => {
    searchTemplate({
      page: 1,
      per_page: this.state.per_page,
      type: 2,
      name: this.state.searchStr
    }).then((arg) => {
      const { data, meta } = arg
      this.setState({
        list: data,
        total: meta.total,
        loading: false
      })
    }).catch((e) => {
      this.setState({
        loading: false
      })
      console.log('获取列表失败', e);
    })
  }


  pageChange = (page) => {
    this.setState({
      page
    }, () => {
      this.getList()
    })
  }


  onSelect = (id) => {
    getTemplate(id)
      .then((data) => {
        if (!data) return;
        const { component_json = {} } = data.data.content;
        this.props.addAlertToFloor(component_json)
        this.props.onClose()
      })
      .catch(e => {
        console.log(e);
      })
  }

  render () {
    const { visible, onClose } = this.props
    return (
      <Modal show={visible} onclose={onClose} width={'70%'}>
        <div style={{ padding: '40px 20px', position: 'relative' }}>
          <FlexLine justify="space-between">
            <ModalTitle>弹窗库</ModalTitle>
            <div style={{paddingRight:'10px',marginBottom:'20px'}}>
              <div style={{ position: 'relative' }}>
                <form onSubmit={this.onSubmit}>
                  <Input placeholder='输入方案名称' value={this.state.searchStr} onChange={this.onChange} />
                  <SerachBtn onClick={this.onSearch}>
                    <svg t="1591261719889" className="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4010" width="20" height="20"><path d="M465.454545 861.090909c-218.763636 0-395.636364-176.872727-395.636363-395.636364S246.690909 69.818182 465.454545 69.818182s395.636364 176.872727 395.636364 395.636363-176.872727 395.636364-395.636364 395.636364z m0-744.727273C272.290909 116.363636 116.363636 272.290909 116.363636 465.454545s155.927273 349.090909 349.090909 349.09091 349.090909-155.927273 349.09091-349.09091S658.618182 116.363636 465.454545 116.363636zM930.909091 954.181818c-6.981818 0-11.636364-2.327273-16.290909-6.981818l-186.181818-186.181818c-9.309091-9.309091-9.309091-23.272727 0-32.581818s23.272727-9.309091 32.581818 0l186.181818 186.181818c9.309091 9.309091 9.309091 23.272727 0 32.581818-4.654545 4.654545-9.309091 6.981818-16.290909 6.981818z" p-id="4011"></path></svg>
                  </SerachBtn>
                </form>
              </div>
            </div>
          </FlexLine>
          {
            this.state.loading ? <FlexLine style={{minHeight:'400px'}}>
              <Loading />
            </FlexLine> :
              <AlertList onSelect={this.onSelect} data={this.state.list}/>
          }
          {
            this.state.total > this.state.per_page && <Pagination
            page={this.state.page}
            total={this.state.total}
            per_page={this.state.per_page}
            onChange={this.pageChange}
            ></Pagination>}
        </div>
      </Modal>
    );
  }
}

export default TemplateModal

const TabItem = styled.div(({active})=>({
  fontSize:'16px',
  padding:'5px 15px',
  position:'relative',
  cursor:'pointer',
  "&::after": !active ? {} : {
    content:'""',
    position:"absolute",
    width:'30px',
    height:'2px',
    background: '#26ABFF',
    left:0,
    right:0,
    bottom:'-5px',
    margin:'auto'
  }
}))

const ModalTitle = styled.div({
  fontSize: '16px',
  position: 'absolute',
  top: '10px',
  left: '10px'
})

const SerachBtn = styled.div({
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  padding: '10px',
  margin: 'auto',
  paddingTop: '13px',
  cursor: 'pointer'
})

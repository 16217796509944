import { toLine } from "../../../utils/transformCssPropertyName"

export default function staticRender ({ config, page_id = 0 }) {
  let { id, props = {} } = config
  id = `${page_id}-${id}`
  let {
    ruleText = '规则说明',
    ruleTitle = '',
    hasNoNumList = false,
    ruleList = [1, 2],
    styles = {}
  } = props

  if (!ruleText) {
    return {
      styles: [],
      html: ''
    }
  }

  const result = {
    styles: [
      {
        name: `.pt-h5-rule-title-${page_id}`,
        content: `
          .pt-h5-rule-title-${page_id} {
            font-size: 18px;
            cursor: pointer;
            text-decoration: underline;
          }
          .dialog-h5-rule-title {
            text-align: center;
            font-size: 18px;
            line-height: 1;
            padding: 10px 0;
          }
          .dialog-h5-rule-list {
            margin: 0;
            padding: 0;
            padding-left: 20px;
            margin-top: 10px;
          }
          .dialog-h5-rule-item {
            color: #333;
            font-size: 14px;
            line-height: 20px;
            text-align: left;
          }
        `
      },
      {
        name: `.pt-h5-rule-title-${id}`,
        content: `
          .pt-h5-rule-title-${id} {
            ${Object.keys(styles).map(css => `${toLine(css)}:${styles[css]};`).join(' ')}
          }
          .dialog-h5-rule-list-${id} {
            list-style: ${hasNoNumList ? '' : 'decimal'};
          }
        `
      },
    ],
    html: `
      <div class="pt-h5-rule-title-${page_id} pt-h5-rule-title-${id}">
        <span>${ruleText}</span>
      </div>
      <div class="dialog-h5-box pt-h5-rule-dialog-h5-box-${id}">
        <div class="dialog-h5">
          <div class="dialog-h5-body">
            <svg viewBox="0 0 1000 1000" class="dialog-h5-btn-close dialog-h5-btn-close-${id}">
              <path d="
                        M 0 0 L 60 0 L 500 440 L 940 0 L 1000 0
                        L 1000 60 L 560 500 L 1000 940 L 1000 1000
                        L 940 1000 L 500 560 L 60 1000 L 0 1000
                        L 0 940 L 440 500 L 0 60 Z
                      " fill="#333" />
            </svg>
            <div class="dialog-h5-user-body">
              <div class="dialog-h5-rule-title">
                ${ruleTitle ? `<b>${ruleTitle}</b>` : ''}
                <ul class="dialog-h5-rule-list dialog-h5-rule-list-${id}">
                  ${ruleList.map(it => {
      return `<li class="dialog-h5-rule-item">${it}</li>`
    }).join(' ')}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <script>
        document.querySelector('.pt-h5-rule-title-${id} span').onclick = function() {
          document.querySelector('.pt-h5-rule-dialog-h5-box-${id}').classList.add('open');
        }
        document.querySelector('.dialog-h5-btn-close-${id}').onclick = function() {
          document.querySelector('.pt-h5-rule-dialog-h5-box-${id}').classList.remove('open');
        }
      </script>
    `,
  }
  return result
}

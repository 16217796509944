import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { ContentTitle } from '../../../style';
import {Loading, WhiteSpace} from '../../../components/index';

import Editor from '../../../components/Editor';
import Percentage from '../../EditComponents/Percentage';
import UnderLine from '../../EditComponents/UnderLine';
import ContentAlign from '../../EditComponents/ContentAlign';

@inject('createStore')
@observer
class EditPcTitle extends Component {
    state = {
        isAddEditor:false,
        title:'',
        component_id:''
    }
    title = ''
    componentDidMount (){
        this.title = this.props.title || '请输入标题'
        this.setState({
            title: this.props.title || '请输入标题',
            isAddEditor:true,
            component_id:this.props.id
        })
    }

    componentDidUpdate (prevProps, prevState) {
        
        if (prevProps.id !== this.props.id){
            this.setState({
                title: this.props.title || '请输入标题',
                isAddEditor: false
            },()=>{
                setTimeout(() => {
                    this.setState({
                        isAddEditor: true
                    })
                }, 500);
            })
        }
    }

    handleEditorChange = (value) => {
        const { activeRowData, seletedRowId, updateDataProps } = this.props.createStore;
        if (activeRowData && seletedRowId) {//选中楼层子组件，优先更新子组件
            updateDataProps('title',value);
        }
    }

    proportionChange = (value) => {
        const { updateDataProps } = this.props.createStore;
        updateDataProps('boxWidth', value);
    }

    alignChange = (value) => {
        const { updateDataProps } = this.props.createStore;
        updateDataProps('align', value);
    }
    render() {
        const { 
            fitContent, 
            align, 
            boxWidth = '100', 
            one_underLine = {},
            is_one_underLine = false,
            two_underLine = {},
            is_two_underLine = false
        } = this.props;
        const { updateDataProps } = this.props.createStore;
        return (
            <div>
                 <ContentTitle>标题:</ContentTitle>
                 <div style={{height:'550px'}}>
                 <Editor
                    value={this.props.title}
                    placeholder={'请输入标题'}
                    onChange={this.handleEditorChange}
                    style={{
                        width: "100%",
                        height: "400px",
                    }}
                />
                 </div>
                <WhiteSpace />
                <UnderLine 
                    underLine={one_underLine} 
                    is_underLine={is_one_underLine} 
                    updateDataProps={updateDataProps}
                    name={'1'}
                    is_underLine_name={'is_one_underLine'}
                    underLine_name={'one_underLine'}
                    isCloseFitContent={!is_two_underLine}
                 />
                <UnderLine 
                    underLine={two_underLine} 
                    is_underLine={is_two_underLine} 
                    updateDataProps={updateDataProps}
                    name={'2'}
                    is_underLine_name={'is_two_underLine'}
                    underLine_name={'two_underLine'}
                    isCloseFitContent={!is_one_underLine}
                />
                <WhiteSpace />
                {!fitContent && <Percentage percent={boxWidth} onChange={this.proportionChange} />}
                <ContentAlign align={align} onChange={this.alignChange} />
            </div>
        )
    }
}

export default EditPcTitle;
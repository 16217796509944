import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
const Wrap = styled.div(({ show, styles }) => {
    return {
        position: 'relative',
        margin:'0 auto',
        height: show ? 'auto' : '0',
        ...styles,
        "@media screen and (max-width: 768px)": {
            display: 'none'
        }
    }
})
const SwiperBox = styled.div({
    width: '100%',
    paddingBottom: '40px !important'
});


const PageDiv = styled.div({
    left: '50%',
    paddingTop: '20px',
    transform: 'translate3d(-50%,0,0) !important',
    '>span': {
        width: '16px',
        height: '3px',
        marginRight: '16px',
        borderRadius: '2px',
        ':focus': {
            outline: 'none'
        }
    },
    ' .swiper-pagination-bullet-active': {
        width: '24px'
    }
})
function inistSwiper ({ autoplay, slidesPerView = 1, randomId}, cb) {
    const script = document.createElement('script');
    script.src = 'https://df5apg8r0m634.cloudfront.net/react/swiper.js';
    script.onload = function () {
        var mySwiper = new Swiper(`.pc-swiper-box.${randomId}`, {
            autoplay: autoplay ? {
                delay: 3000,
                stopOnLastSlide: false,
                disableOnInteraction: false,

            } : false,
            loop: false,
            slidesPerView: slidesPerView,
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            }
        })
        cb && cb();
    }
    document.body.appendChild(script);
}

function PcSwiper ({ children = '', autoPlay, slidesPerView,styles = {} ,isEdit}) {
    const [randomId] = useState('random-' + Math.random().toString(36).substring(2, 15))
    const [initFlag, setInitFlag] = useState(0);
    const [show, setShow] = useState(false);
    useEffect(() => {
        if (!initFlag && slidesPerView && randomId) {
            inistSwiper({ autoPlay, slidesPerView, randomId}, () => {
                setShow(true)
            });
            setInitFlag(1);
        }
    }, [initFlag, autoPlay, slidesPerView, randomId])
    return (
        <Wrap show={show} styles={styles}>
            <SwiperBox className={`swiper-container pc-swiper-box ${randomId}`} style={isEdit ? { overflow: 'unset' } : {}}>
                <link rel="stylesheet" href="https://df5apg8r0m634.cloudfront.net/react/swiper.css"></link>
                {/* <!-- Additional required wrapper --> */}
                <div className="swiper-wrapper" style={isEdit ? {flexWrap:'wrap'} : {}} >
                    {children}
                </div>
                <div className="swiper-pagination"></div>
            </SwiperBox>
            <PageDiv className="swiper-pagination pc-swiper-page"></PageDiv>
        </Wrap>
    )
}
export default PcSwiper;

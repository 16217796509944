import React from 'react';
import styled from 'styled-components';
import { FlexLine } from '../../style';


import { Modal } from 'antd';

const SelectContent = styled.div({
  display:'flex',
  marginRight: '10px',
  '&:hover div': {
    background: 'rgba(0,0,0,0.8)'
  },
  cursor: 'pointer',
})

const SelectContainer = styled.div(({ style = {} }) => ({
  background: '#F0F0F0',
  marginRight:'1px',
  height:'38px',
  ...style
}))


export default function SelectLayout ({ addFloor, isH5}){
  const baseNum = isH5 ? 60 : 120
  return (
    <FlexLine>
      <SelectContent onClick={() => { addFloor(1)}}>
        <SelectContainer style={{ width: `${baseNum}px` }}></SelectContainer>
      </SelectContent>

      <SelectContent onClick={() => { addFloor(2) }}>
        <SelectContainer style={{ width: `${baseNum / 2}px` }}></SelectContainer>
        <SelectContainer style={{ width: `${baseNum / 2}px`  }}></SelectContainer>
      </SelectContent>

      <SelectContent onClick={() => { addFloor(3) }}>
        <SelectContainer style={{ width: `${baseNum / 3}px` }}></SelectContainer>
        <SelectContainer style={{ width: `${baseNum / 3}px` }}></SelectContainer>
        <SelectContainer style={{ width: `${baseNum / 3}px` }}></SelectContainer>
      </SelectContent>

      { !isH5 && <React.Fragment>
        <SelectContent onClick={() => { addFloor(4) }}>
          <SelectContainer style={{ width: `${baseNum / 4}px` }}></SelectContainer>
          <SelectContainer style={{ width: `${baseNum / 4}px` }}></SelectContainer>
          <SelectContainer style={{ width: `${baseNum / 4}px` }}></SelectContainer>
          <SelectContainer style={{ width: `${baseNum / 4}px` }}></SelectContainer>
        </SelectContent>
        <SelectContent onClick={() => { addFloor(5) }}>
          <SelectContainer style={{ width: `${baseNum / 5}px` }}></SelectContainer>
          <SelectContainer style={{ width: `${baseNum / 5}px` }}></SelectContainer>
          <SelectContainer style={{ width: `${baseNum / 5}px` }}></SelectContainer>
          <SelectContainer style={{ width: `${baseNum / 5}px` }}></SelectContainer>
          <SelectContainer style={{ width: `${baseNum / 5}px` }}></SelectContainer>
        </SelectContent>
        <SelectContent onClick={() => { addFloor(6) }}>
          <SelectContainer style={{ width: `${baseNum / 6}px` }}></SelectContainer>
          <SelectContainer style={{ width: `${baseNum / 6}px` }}></SelectContainer>
          <SelectContainer style={{ width: `${baseNum / 6}px` }}></SelectContainer>
          <SelectContainer style={{ width: `${baseNum / 6}px` }}></SelectContainer>
          <SelectContainer style={{ width: `${baseNum / 6}px` }}></SelectContainer>
          <SelectContainer style={{ width: `${baseNum / 6}px` }}></SelectContainer>
        </SelectContent>
     </React.Fragment>}
    </FlexLine>
  )
}
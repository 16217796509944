//内容 对齐方式

import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import Icon from '../../components/Icon';
import { ContentTitle, FlexLine } from '../../style';
class ColAlign extends Component {
  render () {

    const { align = '', onChange = () => { }, isShowSpace = false} = this.props
    return (
      <Fragment>
        <ContentTitle>垂直对齐方式:</ContentTitle>
        <FlexLine justify={"space-between"} style={{ padding: '0 10px', margin: '20px 0' }}>
          <FlexLine>
            <IconButton 
              active={align === 'flex-start'}
              onClick={()=>{
                onChange('flex-start')
              }}
            >
              <Icon icon="icon-on" />
            </IconButton>
            <IconButton 
              active={align === 'center'}
              onClick={() => {
                onChange('center')
              }}
            >
              <Icon icon="icon-centre1"/>
            </IconButton>
            <IconButton 
              active={align === 'flex-end'}
              onClick={() => {
                onChange('flex-end')
              }}
            >
              <Icon icon="icon-down"/>
            </IconButton>
            {isShowSpace && <IconButton
              active={align === 'space-between'}
              onClick={() => {
                onChange('space-between')
              }}
            >
              <Icon icon="icon-centerhorizontally" />
            </IconButton>}
          </FlexLine>
        </FlexLine>
      </Fragment>
    );
  }
}

export default ColAlign




const IconButton = styled.div(({ active = false }) => ({
  padding: '3px',
  border: active ? '1px solid #26ABFF' : '1px solid #7D7D7D',
  color: active ? '#fff' : '#333',
  background: active ? '#26ABFF' : '#fff',
  marginLeft: '10px',
  borderRadius: '4px',
  cursor: 'pointer',
  ':hover': {
    border: '1px solid #26ABFF',
    color: '#26ABFF'
  }
}))
const defaultItem = {
  imgSrc: 'https://df5apg8r0m634.cloudfront.net/images/0d5faf98e226c01377ab4a8cc529a504.png',
  href: '',
};

export default function staticRender ({ config, page_id = 0 }) {
  let { id, props = {} } = config
  id = `${page_id}-${id}`
  let { list, autoPlay } = props
  if (!list) {
    list = [[defaultItem, defaultItem, defaultItem], [defaultItem, defaultItem, defaultItem]]
  }

  const result = {
    styles: [
      {
        name: `.pt-swiper-img-${page_id}`,
        content: `
          .pt-swiper-img-${page_id} {
            position: relative;
            overflow: hidden;
            padding-bottom: 0px;
            height: 0px;
          }
          .pt-swiper-img-${page_id} .pc-img-swiper-box {
            width: 100%;
            max-width: 1200px;
          }
          .pt-swiper-img-${page_id} .swiper-slide {
            display: flex;
          }
          .pt-swiper-img-${page_id} .swiper-item {
            flex: 1;
            flex-shrink: 0;
            margin-right: 20px;
          }
          .pt-swiper-img-${page_id} .swiper-item:last-child {
            margin-right: 0px;
          }
          .pt-swiper-img-${page_id} .no-href {
            width: 100%;
          }
          .pt-swiper-img-${page_id} .img-box {
            display: block;
            cursor: pointer;
          }
          .pt-swiper-img-${page_id} .swiper-button-prev {
            background: rgba(0,0,0,0.3) !important;
            width: 20px !important;
            height: 100px !important;
            left: 0px !important;
            right: auto;
            margin-top: -50px !important;
          }
          .pt-swiper-img-${page_id} .swiper-button-prev::after {
            color: #fff;
            font-size: 23px !important;
          }
          .pt-swiper-img-${page_id} .swiper-button-next {
            background: rgba(0,0,0,0.3) !important;
            width: 20px !important;
            height: 100px !important;
            left: auto;
            right: 0px !important;
            margin-top: -50px !important;
          }
          .pt-swiper-img-${page_id} .swiper-button-next::after {
            color: #fff;
            font-size: 23px !important;
          }
          .pt-swiper-img-${page_id} .pc-swiper-page {
            left: 50%;
            padding-top: 20px;
            transform: translate3d(-50%, 0, 0) !important;
          }
          .pt-swiper-img-${page_id} .pc-swiper-page > span {
            width: 16px;
            height: 3px;
            margin-right: 16px;
            border-radius: 2px;
          }
          .pt-swiper-img-${page_id} .pc-swiper-page > span:focus {
            outline: none;
          }
          .pt-swiper-img-${page_id} .pc-swiper-page .swiper-pagination-bullet-active {
            width: 24px;
          }
        `
      },
    ],
    html: `
      <link rel="stylesheet" href="https://df5apg8r0m634.cloudfront.net/react/swiper.css"></link>
      <div class="pt-swiper-img-${page_id} pt-swiper-img-${id}">
        <div class="swiper-container pc-img-swiper-box">
          <div class="swiper-wrapper">
            ${list.reduce((pre1, curr1) => {
      return pre1 + `
                <div class="swiper-slide">
                  ${curr1.reduce((pre2, curr2) => {
        const { imgSrc, href } = curr2
        return pre2 + `
                      <div class="swiper-item">
                        ${href ? `
                          <a href="${href}" target="_blank">
                            <img class="no-href" src="${imgSrc || defaultItem.imgSrc}" />
                          </a>
                        ` : `
                          <img class="no-href" src="${imgSrc || defaultItem.imgSrc}" />
                        `}
                      </div>
                    `
      }, '')}
                </div>
              `
    }, '')}
          </div>
          <div className="swiper-pagination"></div>
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </div>
        <div class="swiper-pagination pc-swiper-page"></div>
      </div>
      <script>
        (function () {
          var script = document.createElement('script')
          script.onload = function() {
            new Swiper('.pt-swiper-img-${id} .pc-img-swiper-box', {
              autoplay: ${autoPlay ? `{ delay: 3000, stopOnLastSlide: false, disableOnInteraction: false }` : 'false'},
              loop: true,
              pagination: {
                el: '.pt-swiper-img-${id} .pc-swiper-page'
              },
              navigation: {
                nextEl: '.pt-swiper-img-${id} .swiper-button-next',
                prevEl: '.pt-swiper-img-${id} .swiper-button-prev'
              }
            })
            var pcSwiperImg = document.getElementsByClassName('pt-swiper-img-${id}')[0]
            pcSwiperImg.style.paddingBottom = '40px'
            pcSwiperImg.style.height = 'auto'
          }
          script.src = 'https://df5apg8r0m634.cloudfront.net/react/swiper.js'
          document.body.appendChild(script)
        })()
      </script>
    `,
  }
  return result
}

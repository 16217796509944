import { toLine } from "../../../utils/transformCssPropertyName"

function flex2textAlign(align) {
    const map = {
        'flex-start': 'left',
        'center': 'center',
        'flex-end': 'right',
    }
    return map[align] || 'left'
}

export default function staticRender({ config }) {
    const { id, props = {} } = config
    let {
        text = '按钮文本',
        styles = {},
        align = '',
        href = "",
    } = props
    const emailBtnStyle = {
        width: '180px',
        height: '35px',
        lineHeight: '35px',
        fontSize: '14px',
        textAlign: 'center',
        borderRadius: '4px',
        cursor: 'pointer',
        backgroundColor: '#26abff',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
        ...styles
    }
    const emailBtnStyleStr = Object.keys(emailBtnStyle)
        .map(css => (`${toLine(css)}:${emailBtnStyle[css]};`))
        .join(' ')
    const aStyle = `text-decoration:none; display:inline-block; cursor:${href ? 'pointer' : 'default'}; color:${styles.color || 'unset'}`
    return {
        styles: [],
        html: `
            <div style="box-sizing:border-box; text-align:${flex2textAlign(align)}">
                <div style="${emailBtnStyleStr} display: inline-block;">
                    <a
                        ${href ? `href="${href}" target="_blank" rel="noreferrer"` : ''}
                        style="${aStyle}"
                    >
                        ${text}
                    </a>
                </div>
            </div>
        `
    }
}

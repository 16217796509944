import componentMount from "../../lib/componentMount";

import EditContainer from "./EditH5AlertCoupon";
import Component from "./H5AlertCoupon";
import Style from "./EditH5AlertCouponStyles";
import staticRender from "./StaticRender.js";

const _Render = componentMount({
    Component:Component,
    Content:EditContainer,
    Style:Style,
    text:'弹窗code'
})

export default {
    componentMount:_Render,
    name:'H5AlertCoupon',
    text:'弹窗code',
    staticRender
}
import { observable, action, computed } from 'mobx';

class TemplateStore {
  @computed
  get templateTypes() {
    return this.isTemplate ? [
      {
        value: 1,
        label: '页面'
      },
      {
        value: 2,
        label: '弹窗'
      },
      {
        value: 3,
        label: '邮件'
      },
      {
        value: 4,
        label: '楼层'
      }
    ] : [
      {
        value: 1,
        label: '页面'
      },
      {
        value: 2,
        label: '弹窗'
      },
      {
        value: 3,
        label: '邮件'
      }
    ];
  }

  @computed
  get templateStatus() {
    return [
      {
        value: 1,
        label: '草稿'
      },
      {
        value: 2,
        label: '已发布'
      }
    ]
  }

  @observable
  isTemplate = false;

  @action
  setTemplateType = (isTemplate) => {
    this.isTemplate = isTemplate
  }

  @observable
  html_json = {
    pc: [],
    h5: []
  };

  @action
  setHtmlJson = (data) => {
    this.html_json = data
  }

  @observable
  siteNav = 'page';

  @action
  setSiteView = (data) => {
    this.siteNav = data
  }

  @action
  getTypeName = (type) => {
    const t = this.templateTypes.find(v => v.value === type);
    return t ? t.label : '未知';
  }

  @action
  isEmailTpl = (type) => {
    return type === 3
  }

  @action
  getStatusName = (status) => {
    const t = this.templateStatus.find(v => v.value === status);
    return t ? t.label : '未知';
  }
}

export default TemplateStore;

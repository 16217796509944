import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { ContentTitle, FlexLine, Input,TextArea } from '../../../style';
import {Select, WhiteSpace} from '../../../components/index';

import ContentAlign from '../../EditComponents/ContentAlign';

import styled from 'styled-components';
import { Fragment } from 'react';
@inject('createStore')
@observer
class EditH5Button extends Component {

    state = {
        isAddEditor: false
    }
    get btnSizeOptions () {
        return [
            {
                value: 'ms',
                label: '小按钮'
            },
            {
                value: 'md',
                label: '中按钮'
            },
            {
                value: 'lg',
                label: '大按钮'
            },
            {
                value: 'full',
                label: '全按钮'
            },
            {
                value: 'auto',
                label: '自适应'
            },
            {
                value: 'self',
                label: '自定义'
            }
        ]
    }

    
    text = ''
    componentDidMount () {
        this.text = this.props.text || '请输入文本'
        this.setState({
            isAddEditor: true
        })
    }


    getNumFromPx = (val) => {
        return `${val}`.split('px')[0];
    }
    getFontStyle = (btnSize) => {
        switch (btnSize) {
            case 'lg':
                return {
                    width: '177px',
                    height: '36px',
                    fontSize: '14px'
                }
            case 'md':
                return {
                    width: '115px',
                    height: '38px',
                    fontSize: '14px'
                }
            case 'full':
                return {
                    width: '100%',
                    height: '48px',
                    padding: '0',
                    fontSize: '16px'
                }
            case 'ms':
                return {
                    width: '60px',
                    height: '30px',
                    fontSize: '12px'
                }
            case 'auto':
                return {
                    width: 'fit-content',
                    height: 'fit-content',
                    padding: '5px 15px',
                    fontSize: '13px',
                    lineHeight: '13px',
                }
            default:
                return {}
        }
    }


    handleEditorChange = (e) => {
        const { activeRowData, seletedRowId, updateDataProps } = this.props.createStore;
        if (activeRowData && seletedRowId) {//选中楼层子组件，优先更新子组件
            updateDataProps('text', e.target.value);
        }
    }

    handleHrefChange = (e) => {
        const { activeRowData, seletedRowId, updateDataProps } = this.props.createStore;
        if (activeRowData && seletedRowId) {//选中楼层子组件，优先更新子组件
            updateDataProps('href', e.target.value);
        }
    }

    handleSizeChange = (btnSize) => {
        let btnStyle = this.getFontStyle(btnSize)

        const { seletedData, updateDataProps } = this.props.createStore;
        const { props } = seletedData;
        const { styles } = props;
        let updataData = {
            ...styles,
            ...btnStyle
        };
        updateDataProps('styles', updataData);
        updateDataProps('btnSize', btnSize);
    }


    alignChange = (value) => {
        const { updateDataProps } = this.props.createStore;
        updateDataProps('align', value);
    }

    handleRefreshStyle = (key, value) => {
        //更新某个组件，或则楼层样式属性
        const { seletedData, updateDataProps } = this.props.createStore;
        const { props } = seletedData;
        const { styles } = props;
        let updataData = {
            ...styles,
            [key]: value
        };
        updateDataProps('styles', updataData);
    }


    render() {
        const {text = '按钮文本', align = '', btnSize = '', href = '', styles} = this.props;
        const {width, height} = styles
        return (
            <div style={{paddingRight:'10px'}}>
                <WhiteSpace />
                <ContentTitle>按钮文本:</ContentTitle>
                <Input
                    value={text}
                    placeholder={'请输入按钮文本'}
                    onChange={this.handleEditorChange}
                    style={{ width: '100%', boxSizing: 'border-box' }}
                />
                <WhiteSpace />
                <ContentTitle>按钮链接:</ContentTitle>
                <TextArea
                    value={href}
                    placeholder={'请输入按钮链接'}
                    onChange={this.handleHrefChange}
                    style={{ width: '100%', boxSizing: 'border-box'}}
                />
                <WhiteSpace />
                <ContentTitle>按钮尺寸:</ContentTitle>
                <div style={{fontSize:'14px'}}>
                    <Select
                        value={btnSize}
                        placeholder={'请选择按钮尺寸'}
                        onChange={(data) => {
                            this.handleSizeChange(data.value)
                        }}
                        options={this.btnSizeOptions}
                    />
                </div>
                {btnSize === 'self' && <Fragment>
                    <FlexLine>
                        <ContentTitle>固定宽度</ContentTitle>
                        <div style={{ position: 'relative', width: '120px' }}>
                            <Input
                                type='number'
                                name={'width'}
                                style={{ width: '120px', margin: '5px', paddingRight: '0' }}
                                onChange={(e) => this.handleRefreshStyle('width', `${e.target.value}px`)}
                                value={this.getNumFromPx(width)}
                            />
                            <SpanText>px</SpanText>
                        </div>
                    </FlexLine>
                    <WhiteSpace />
                    <FlexLine>
                        <ContentTitle>固定高度</ContentTitle>
                        <div style={{ position: 'relative', width: '120px' }}>
                            <Input
                                type='number'
                                name={'height'}
                                style={{ width: '120px', margin: '5px', paddingRight: '0' }}
                                onChange={(e) => {
                                    this.handleRefreshStyle('height', `${e.target.value}px`)
                                }}
                                value={this.getNumFromPx(height)}
                            />
                            <SpanText>px</SpanText>
                        </div>
                    </FlexLine>
                </Fragment>}
                <WhiteSpace />
                <ContentAlign align={align} onChange={this.alignChange}/>
            </div>
        )
    }
}

export default EditH5Button;

const SpanText = styled.div({
    color: '#999',
    fontSize: '14px',
    textAlign: 'center',
    position: 'absolute',
    top: '10px',
    right: '25px'
})
import React, { Component, Fragment } from 'react';
import { ContentTitle, FlexLine, Input, Button } from '../../../style';
import { inject, observer } from 'mobx-react';
import Percentage from '../../EditComponents/Percentage';
import ContentAlign from '../../EditComponents/ContentAlign';
import Icon from '../../../components/Icon';
import { CheckBox, WhiteSpace } from '../../../components/index';
import styled from 'styled-components';

import { DatePicker } from 'antd';
import moment from 'moment';

@inject('createStore','viewStore')
@observer
class EditH5Time extends Component {


    
    changeBoxWidth = (val) => {
        const { updateDataProps } = this.props.createStore;
        updateDataProps('boxWidth', val);
    }

    alignChange = (value) => {
        const { updateDataProps } = this.props.createStore;
        updateDataProps('align', value);
    }


    cleanVideo = () => {
        const { updateDataProps } = this.props.createStore;
        updateDataProps('videoSrc', '');
        updateDataProps('videoName', '');
    }

    selectVideo = () => {
        this.props.viewStore.setSiteView('video')
    }

    handleChange = (date) => {
        const { updateDataProps } = this.props.createStore;
        updateDataProps("endTime", date.valueOf())
    }

    rederBgColor = ()=>{
        const {
            bgColor = "#000"
        } = this.props
        const { updateDataProps } = this.props.createStore;
        return (
            <div style={{marginBottom:'20px'}}>
                <ContentTitle style={{ margin: 0 }}>圈圈背景颜色</ContentTitle>
                <WhiteSpace></WhiteSpace>
                <FlexLine justify='flex-start'>
                    <Input 
                        value={bgColor} 
                        type="color" 
                        name='bgColor' 
                        onChange={(e) => updateDataProps('bgColor', `${e.target.value}`)}
                        style={{ width: '28px', padding: '2px' }} />
                    <Input 
                        type="text" 
                        name='bgColor'
                        placeholder={'选中颜色/输入颜色'} 
                        value={bgColor || ''} 
                        style={{  padding: '5px 10px' }}
                        onChange={(e) => updateDataProps('bgColor', `${e.target.value}`)} />
                </FlexLine>
            </div>
        )
    }

    rederTextColor = () => {
        const {
            textColor = "#fff"
        } = this.props
        const { updateDataProps } = this.props.createStore;
        return (
            <div style={{ marginBottom: '20px' }}>
                <ContentTitle style={{ margin: 0 }}>圈圈文字颜色</ContentTitle>
                <WhiteSpace></WhiteSpace>
                <FlexLine justify='flex-start'>
                    <Input
                        value={textColor}
                        type="color"
                        name='textColor'
                        onChange={(e) => updateDataProps('textColor', `${e.target.value}`)}
                        style={{ width: '28px', padding: '2px' }} />
                    <Input
                        type="text"
                        name='textColor'
                        placeholder={'选中颜色/输入颜色'}
                        value={textColor || ''}
                        style={{ padding: '5px 10px' }}
                        onChange={(e) => updateDataProps('textColor', `${e.target.value}`)} />
                </FlexLine>
            </div>
        )
    }

    render() {

        let { 
            boxWidth = '100',
            endTime = "", 
            align,
            day = "Day",
            hours = 'Hours',
            minutes = 'Minutes',
            seconds = 'Seconds'
         } = this.props;
        const { updateDataProps } = this.props.createStore;
        let default_end_time = endTime ? moment(endTime).format('YYYY-MM-DD HH:mm:ss') : ''
        return (
            <div style={{paddingRight:'10px'}}>
                <WhiteSpace></WhiteSpace>
                <WhiteSpace></WhiteSpace>
                <CheckBoxTitle>选择结束时间：</CheckBoxTitle>
                <WhiteSpace></WhiteSpace>
                <DatePicker
                    defaultValue={default_end_time ? moment(default_end_time, 'YYYY-MM-DD hh:mm:ss') : ''}
                    format={'YYYY-MM-DD hh:mm:ss'}
                    showTime={true}
                    onChange={this.handleChange}

                />
                <WhiteSpace></WhiteSpace>
                <WhiteSpace></WhiteSpace>
                {
                    this.rederBgColor()
                }
                <WhiteSpace></WhiteSpace>
                {
                    this.rederTextColor()
                }
                <ContentTitle style={{ margin: 0 }}>倒计时文案</ContentTitle>
                <WhiteSpace></WhiteSpace>
                <FlexLine>
                    <ContentTitle style={{ width: "60px" }}>天:</ContentTitle>
                    <Input
                        value={day}
                        onChange={(e) => updateDataProps('day', e.target.value)}
                        style={{ width: '100%', boxSizing: 'border-box' }}
                    />
                </FlexLine>
                <FlexLine>
                    <ContentTitle style={{ width: "60px" }}>小时:</ContentTitle>
                    <Input
                        value={hours}
                        onChange={(e) => updateDataProps('hours', e.target.value)}
                        style={{ width: '100%', boxSizing: 'border-box' }}
                    />
                </FlexLine>
                <FlexLine>
                    <ContentTitle style={{ width: "60px" }}>分:</ContentTitle>
                    <Input
                        value={minutes}
                        onChange={(e) => updateDataProps('minutes', e.target.value)}
                        style={{ width: '100%', boxSizing: 'border-box' }}
                    />
                </FlexLine>
                <FlexLine>
                    <ContentTitle style={{ width: "60px" }}>秒:</ContentTitle>
                    <Input
                        value={seconds}
                        onChange={(e) => updateDataProps('seconds', e.target.value)}
                        style={{ width: '100%', boxSizing: 'border-box' }}
                    />
                </FlexLine>
                <Percentage percent={boxWidth} onChange={this.changeBoxWidth}/>
                <ContentAlign align={align} onChange={this.alignChange} />
            </div>
        )
    }
}

export default EditH5Time;

const CheckBoxTitle = styled.div({
    fontSize:'14px',
    marginLeft:'5px',
    color:"#333"
})
import { toLine } from '../../../utils/transformCssPropertyName'

function getUnderLineStyle (underLine = {}) {
  const { width = 50, color = "#000000", position = 'bottom', height = 2 } = underLine;
  let absolute
  switch (position) {
    case 'top':
      absolute = `
        left: -100%;
        right: -100%;
        top: 0;
      `
      break;
    case 'left':
      absolute = `
        left: -${Number(width || 0) + 10}px;
        bottom: 0;
        top: 0;
      `
      break;
    case 'right':
      absolute = `
        right: -${Number(width || 0) + 10}px;
        bottom: 0;
        top: 0;
      `
      break;
    default:
      absolute = `
        left: -100%;
        right: -100%;
        bottom: 0;
      `
      break;
  }
  return `
    content: '';
    width: ${width}px;
    height: ${height}px;
    background: ${color};
    position: absolute;
    margin: auto;
    ${absolute}
  `
}

export default function staticRender ({ config, page_id = 0 }) {
  let { id, props = {} } = config
  id = `${page_id}-${id}`
  let { align, fitContent, boxWidth = 100, is_one_underLine, one_underLine = {}, is_two_underLine, two_underLine, title, styles = {} } = props

  if (!title) {
    title = ''
  }

  const result = {
    styles: [
      {
        name: `.pt-title-${page_id}`,
        content: `
          .pt-title-${page_id} {
            display: flex;
            width: 100%;
            box-sizing: border-box;
          }
        `
      },
      {
        name: `.pt-title-text-${page_id}`,
        content: `
          .pt-title-text-${page_id} {
            font-size: 28px;
            line-height: 1.5;
            text-align: center;
            color: #333;
            font-family: Roboto-Medium;
            position: relative;
          }
          .pt-title-text-${page_id} > p {
            margin: 0;
          }
        `
      },
      {
        name: `.pt-title-text-${id}`,
        content: `
          .pt-title-text-${id} {
            ${Object.keys(styles).map(css => {
          return `${toLine(css)}:${styles[css]};`
        }).join(' ')}
            width: ${fitContent ? 'fit-content' : `${boxWidth}%`};
          }
        `
      },
    ],
    html: `
      <div class="pt-title-${page_id} pt-title-${id}">
        <div class="pt-title-text-${page_id} pt-title-text-${id}">${title}</div>
      </div>
    `,
  }
  if (align) {
    result.styles.push(
      {
        name: `.pt-title-${id}`,
        content: `
          .pt-title-${id} { justify-content: ${align}; }
        `
      }
    )
  }
  if (is_one_underLine) {
    result.styles.push(
      {
        name: `.pt-title-text-${id}::after`,
        content: `
          .pt-title-text-${id}::after {
            ${getUnderLineStyle(one_underLine)}
          }
        `
      }
    )
  }
  if (is_two_underLine) {
    result.styles.push(
      {
        name: `.pt-title-text-${id}::before`,
        content: `
          .pt-title-text-${id}::before {
            ${getUnderLineStyle(two_underLine)}
          }
        `
      }
    )
  }
  return result
}

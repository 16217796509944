import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import scriptLoader from "react-async-script-loader";
import styled from "styled-components";
import { getTranslateData } from "../api";

import Loading from "../components/Loading";

import { TranslationDataPars, convertToJson } from "../utils/helps";

import { FlexLine, Button } from "../style";

const LoadingContaier = styled.div({
  width: "100%",
  height: "500px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const SelectTran = styled.select({
  width: "400px",
  height: "32px",
  borderRadius: "8px",
  background: "#fff",
  lineHeight: "32px",
  padding: "0 10px",
  color: "#333",
  border: "1px solid #e4e4e4",
});
const Title = styled.h3({
  lineHeight: "30px",
  width: "100%",
  textAlign: "center",
});
const SelectContainer = styled.div({
  padding: "0 40px",
});

const TransResultContainer = styled.table({
  width: "100%",
  borderLeft: "1px solid #e4e4e4",
});

const TransResultLine = styled.tr(({ style }) => ({
  width: "100%",
  ...style,
}));

const TransResultItem = styled.td({
  width: "25%",
  padding: "10px 15px",
  fontSize: "12px",
  overflowX: "scroll",
  borderBottom: "1px solid #e4e4e4",
  borderRight: "1px solid #e4e4e4",
  "&::-webkit-scrollbar": {
    display: "none",
  },
});

const DownLoadBtn = styled.div({
  margin: "20px 0",
  marginTop: "40px",
});

@inject("translationStore")
@observer
class Translation extends Component {
  state = {
    transData: {},
    current: [],
    loading: true,
    currentTitle: "",
  };
  async componentDidMount() {
    this.addTable();
    const { transList } = this.props.translationStore;
    let transData = {};
    for (let index = 0; index < transList.length; index++) {
      const element = transList[index];
      const data = await getTranslateData(element);
      transData[element] = data;
    }
    this.setState({
      transData: transData,
      loading: false,
    });
  }

  addTable = () => {
    this.timer = setInterval(() => {
      if (window.jQuery) {
        clearInterval(this.timer);
        const body = document.getElementsByTagName("body")[0];
        let label = document.createElement("script");
        label.type = "text/javascript";
        label.src =
          "https://cdn.rawgit.com/rainabba/jquery-table2excel/1.1.0/dist/jquery.table2excel.min.js";
        body.appendChild(label);
      }
    }, 1000);
  };

  onChange = (e) => {
    const val = e.target.value;
    const { transData } = this.state;
    const data = transData[val];
    this.initData(data);
    this.setState({
      currentTitle: val,
    });
  };

  initData = (info) => {
    this.setState({
      current: TranslationDataPars(info),
    });
  };

  downTable = () => {
    const body = document.getElementsByTagName("body")[0];
    let label = document.createElement("script");
    label.type = "text/javascript";
    label.innerHTML = `
          $("#exceltable").table2excel({            
              //exceltable为存放数据的table
              // 不被导出的表格行的CSS class类
              // exclude: ".noExl",
              // 导出的Excel文档的名称
              name: "${this.state.currentTitle}-${new Date().toDateString()}",
              // Excel文件的名称
              filename: "${this.state.currentTitle
      }-${new Date().toDateString()}" + ".xls",
              bootstrap: false
          });
        `;
    body.appendChild(label);
  };

  downJson = () => {
    const input = document.getElementById("input-excel");
    if (input.files.length === 0) return alert("请上传文件");
    convertToJson(input.files);
  };

  render() {
    if (this.state.loading) {
      return (
        <LoadingContaier>
          <Loading />
        </LoadingContaier>
      );
    }
    const { transList } = this.props.translationStore;
    return (
      <SelectContainer>
        <Title>Firmoo 前端翻译汇总</Title>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <div style={{ marginRight: 20 }}>
            <p>请选择你需要查看的翻译文件</p>
            <SelectTran name="" id="" onChange={this.onChange}>
              <option value={""}>请选择</option>
              {transList.map((arg, key) => (
                <option value={arg} key={key}>
                  {arg}
                </option>
              ))}
            </SelectTran>
          </div>
          <div style={{ margin: "20px 0" }}>
            <input
              type="file"
              id="input-excel"
              accept=".xlsx, .xls"
              style={{ marginBottom: "10px" }}
              multiple
            />
            <div style={{ fontWeight: "bold", marginBottom: "10px" }}>
              *将本地化修改好的多语言上传转为json，支持传入多个excel文件，注意最后一列为修改/增加的本地化语言列
            </div>
            <DownLoadBtn>
              <Button onClick={this.downJson}>上传excel转为json文件</Button>
            </DownLoadBtn>
          </div>
        </div>

        {this.state.current.length > 0 && (
          <div>
            <DownLoadBtn>
              <Button onClick={this.downTable}>下载当前文件为excel表格</Button>
            </DownLoadBtn>
            <p>翻译文件最新更新时间：2024-8-4</p>

            <div style={{ borderTop: "1px solid #e4e4e4", margin: "40px 0" }}>
              <TransResultContainer id="exceltable" border={1}>
                <tbody>
                  <TransResultLine>
                    <TransResultItem style={{ fontSize: "16px" }}>
                      <b>Key值</b>
                    </TransResultItem>
                    <TransResultItem style={{ fontSize: "16px" }}>
                      <b>英语</b>
                    </TransResultItem>
                    {/* <TransResultItem style={{fontSize:'16px'}}><b>西语</b></TransResultItem> */}
                    {/* <TransResultItem style={{ fontSize: '16px' }}><b>德语</b></TransResultItem> */}
                    {/* <TransResultItem style={{ fontSize: "16px" }}>
                      <b>葡萄牙语</b>
                    </TransResultItem> */}
                    {/* <TransResultItem style={{ fontSize: '16px' }}><b>法语</b></TransResultItem> */}
                  </TransResultLine>
                  {this.state.current.map((arg, key) => (
                    <TransResultLine key={key}>
                      <TransResultItem>{arg.name}</TransResultItem>
                      <TransResultItem>{arg.en}</TransResultItem>
                      {/* <TransResultItem>{arg.es}</TransResultItem> */}
                      {/* <TransResultItem>{arg.de}</TransResultItem> */}
                      {/* <TransResultItem>{arg.pt}</TransResultItem> */}
                      {/* <TransResultItem>{arg.fr}</TransResultItem> */}
                    </TransResultLine>
                  ))}
                </tbody>
              </TransResultContainer>
            </div>
          </div>
        )}
      </SelectContainer>
    );
  }
}

export default scriptLoader(
  "https://ajax.googleapis.com/ajax/libs/jquery/2.2.4/jquery.min.js"
)(Translation);

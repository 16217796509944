import componentMount from "../../lib/componentMount";

import EditContainer from "./EditPcAlertCoupon";
import Component from "./PcAlertCoupon";
import Style from "./EditPcAlertCouponStyles";
import staticRender from "./StaticRender.js";

const _Render = componentMount({
    Component:Component,
    Content:EditContainer,
    Style:Style,
    text:'弹窗code'
})

export default {
    componentMount:_Render,
    name:'PcAlertCoupon',
    text:'弹窗code',
    staticRender
}
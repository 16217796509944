import componentMount from "../../lib/componentMount";

import EditContainer from "./EditPcImgAndText";
import Component from "./PcImgAndText";
import BaseStyle from "../../BaseBox/BaseStyle";

const _Render = componentMount({
    Component:Component,
    Content:EditContainer,
    Style:BaseStyle,
    text:'左图右文'
})

export default {
    componentMount:_Render,
    name:'PcImgAndText',
    text:'左图右文'
}
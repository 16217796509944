import componentMount from "../../lib/componentMount";

import EditContainer from "../../EditComponents/EditProduct";
import Component from "./EmailProduct";
import BaseStyle from "./StyleProduct";
import staticRender from "./StaticRender.js";

const _Render = componentMount({
    Component: Component,
    Content: EditContainer,
    Style: BaseStyle,
    text: '产品'
})

export default {
    componentMount: _Render,
    name: 'EmailProduct',
    text: '产品',
    staticRender
}
import React from 'react';
import styled from 'styled-components';

const HrefBox = styled.a(({styles={}})=>{
    return{
        cursor: 'pointer',
        ...styles
    }
})
const Img = styled.img(({ styles = {}}) => ({
    width: '100%',
    minHeight:'70px',
    ...styles
}))

const Container = styled.div(({ styles }) => ({
    display: 'flex',
    flexDirection:'column',
    alignItems:'center',
    width: '100%',
    padding:'30px 10px',
    boxSizing: 'border-box',
    ...styles
}))



const PriceLine = styled.div({
    display:'flex',
    justifyContent:'center',
    alignItems: 'center'
})

const PrePrice = styled.s({
    color:'#666666',
    fontSize:'16px',
    marginRight:'5px'
})

const Price = styled.span({
    color: '#333',
    fontSize: '16px',
    fontFamily:'Roboto-Medium'
})

const ProductName = styled.div({
    fontSize:'16px',
    marginTop:'10px',
    textAlign:'center'
})

const Button = styled.div(({ btnStyle={},btn_hover_color="#000"}) => {
    return {
        borderRadius:'4px',
        textAlign: 'center',
        marginTop: '10px',
        lineHeight:'36px',
        cursor:'pointer',
        color:'#fff',
        fontSize:'14px',
        ...btnStyle
    }
})

function H5Product ({ 
    imgSrc,
    price,
    pre_price,
    product_name,
    href,
    btn_size='md',
    btn_color='#000',
    btn_text = 'Shop Now >',
    isShowPrice = true,
    isShowName = true,
    isShowBtn = true,
    product_style,
    isBorderBtn = false
}){
    if(!imgSrc){
        imgSrc = 'https://df5apg8r0m634.cloudfront.net/images/2023/0428/10f199161ca9a9605534e4211763c1af.jpg'
    }

    let btnStyle = {
        width: '220px',
        lineHeight: '52px',
        fontSize: '20px',
        backgroundColor: isBorderBtn ? '#fff' : btn_color,
        color: !isBorderBtn ? '#fff' : btn_color,
        border: isBorderBtn ? `1px solid ${btn_color}` : 'unset'
    }
    if (btn_size === 'md') {
        btnStyle = {
            ...btnStyle,
            width: '192px',
            lineHeight: '40px',
            fontSize: '18px'
        }
    }
    if (btn_size === 'ms') {
        btnStyle = {
            ...btnStyle,
            width: 'unset',
            lineHeight: 'unset',
            fontSize: '16px',
            padding: '5px 15px'
        }
    }
    if (btn_size === 'auto') {
        btnStyle = {
            ...btnStyle,
            width: 'unset',
            lineHeight: 'unset',
            fontSize: '16px',
            padding: '10px 40px'
        }
    }
    return (
        <Container>
            <HrefBox
                href={href ? href : ''}
                onClick={(e) => { !href && e.preventDefault() }} target='_blank'>
                <Img src={imgSrc} />
            </HrefBox>
            {isShowPrice && <PriceLine>
                {pre_price && <PrePrice>{pre_price}</PrePrice>}
                {price && <Price>{price}</Price>}
            </PriceLine>}
            {isShowName && <ProductName>
                {product_style}<span style={{ fontFamily: 'Roboto-Medium' }}>{product_name}</span>
            </ProductName>}
            {isShowBtn && <HrefBox
                href={href ? href : ''}
                onClick={(e) => { !href && e.preventDefault() }} target='_blank'>
                <Button
                    btnStyle={btnStyle}
                >{btn_text}</Button>
            </HrefBox>}
        </Container>
    )
}

export default H5Product;

import React, { Fragment, Component } from 'react';
import styled from 'styled-components';

class TemplateList extends Component{


  render() {
    const { data, onSelect } = this.props;
    return (
      <ListContainer>
        {
          data.map((item,index)=>(
            <div key={index} style={{margin:'20px 10px'}}>
              <PreViewContainer>
                <PreViewIframe src={`/templateView/${item.id}`} style={{ width: '900px' }} />
                <SelectContainer onClick={() => {onSelect(item.id)}}></SelectContainer>
              </PreViewContainer>
              <ViewName>{item.name}</ViewName>
            </div>
          ))
        }
      </ListContainer>
    );
  }
}
const ViewName = styled.div({
  fontSize:'14px',
  marginTop:'10px',
  textAlign:'center'
})

const ListContainer = styled.div({
  display:'flex',
  flexWrap:'wrap',
  maxHeight: '50vh',
  overflowY: 'scroll'
})

const PreViewContainer = styled.div({
  width: '300px',
  height: '100px',
  position:'relative',
  border:'1px solid #e4e4e4',
  overflow: 'hidden'
})

const PreViewIframe = styled.iframe({
  border: 'none',
  width: '900px',
  height: '300px',
  transformOrigin: '0 0',
  transform: `scale(${1 / 3})`,
  position: 'absolute'
})

const SelectContainer=styled.div({
  width: '300px',
  height: '100px',
  position: 'absolute',
  top:0,
  right:0,
  cursor:"pointer"
})
export default TemplateList
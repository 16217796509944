import componentMount from "../../lib/componentMount";

import EditContainer from "./EditPcImgTextColumn";
import Component from "./PcImgTextColumn";
import BaseStyle from "../../BaseBox/BaseStyle";

const _Render = componentMount({
    Component:Component,
    Content:EditContainer,
    Style:BaseStyle,
    text:'图文栏'
})

export default {
    componentMount:_Render,
    name:'PcImgTextColumn',
    text:'图文栏'
}
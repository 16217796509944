// H5 图片
import React from 'react';
import styled from 'styled-components';

const HrefBox = styled.a(({ styles = {}, boxWidth, href }) => {
    return {
        fontSize: 0,
        width: `${boxWidth}%`,
        // padding:'20px 0',
        cursor: href ? 'pointer' : 'default',
        ...styles
    }
})
const Img = styled.img(({ styles = {} }) => ({
    width: '100%',
    ...styles
}))

const Container = styled.div(({ styles }) => ({
    display: 'flex',
    width: '100%',
    boxSizing: 'border-box',
    ...styles
}))
const Box = styled.div(({ boxWidth }) => {
    return {
        width: `${boxWidth}%`
    }
})

function H5Img ({ imgSrc, src,href, boxWidth=100, align, styles }) {
    if (!imgSrc) {
        imgSrc = src || 'https://df5apg8r0m634.cloudfront.net/images/0d5faf98e226c01377ab4a8cc529a504.png'
    }
    return (
        <Container styles={align ? { justifyContent: align } : {}}>
            {href ? <HrefBox
                href={href ? href : ''}
                boxWidth={boxWidth}
                onClick={(e) => { !href && e.preventDefault() }} target='_blank'>
                <Img src={imgSrc } styles={styles} />
            </HrefBox> : <Box boxWidth={boxWidth}>
                    <Img src={imgSrc} styles={styles} />
            </Box>}
        </Container>
    )
}

export default H5Img;
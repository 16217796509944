import PcBaseBox from "./pc/PcBaseBox/PcBaseBox";
import PcAlert from "./pc/PcAlert/PcAlert";
import PcRowContent from "./pc/PcRowContent/PcRowContent";
import PcSwiper from "./pc/PcSwiper/PcSwiper";
import PcSwiperItem from "./pc/PcSwiperItem/PcSwiperItem";

import H5Alert from "./h5/H5Alert/H5Alert";
import H5BaseBox from "./h5/H5BaseBox/H5BaseBox";
import H5RowContent from "./h5/H5RowContent/H5RowContent";
import H5Swiper from "./h5/H5Swiper/H5Swiper";
import H5SwiperItem from "./h5/H5SwiperItem/H5SwiperItem";

import EmailBaseBox from "./email/EmailBaseBox/EmailBaseBox";
import EmailRowContent from "./email/EmailRowContent/EmailRowContent";

class PageBasePars {

    get componentsMap() {


        return {
            'PcBaseBox':PcBaseBox,
            'PcAlert': PcAlert,
            'PcRowContent':PcRowContent,
            'PcSwiper':PcSwiper,
            'PcSwiperItem':PcSwiperItem,
            'H5Swiper':H5Swiper,
            'H5SwiperItem':H5SwiperItem,
            'H5Alert': H5Alert,
            'H5BaseBox': H5BaseBox,
            'H5RowContent': H5RowContent,
            'EmailBaseBox': EmailBaseBox,
            'EmailRowContent': EmailRowContent
        }
    }

    render(name) {
        return this.componentsMap[name];
    }
}

export default new PageBasePars();

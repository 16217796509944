import React, { Fragment, Component } from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';

import FloorContent from "./FloorContent";
import { FlexLine } from '../../style';
import Icon from '../Icon';
import AlertModal from '../Template/AlertModal';

import PcAlert from "../../pageComponents/pc/PcAlert/PcAlert";

//增加，删除，移动，编辑楼层
@inject('createStore')
@observer
class PageAlert extends Component {
    state = {
        hoverId: 0,
        is_show_select:false,
        isShowModal:false,
        showHideAlert:false
    }

    handleSeletedFloorChild = (id, floorId) => {
        if (!id) return;
        const { setRowId } = this.props.createStore;;
        setRowId(id, floorId);
    }

    
    selectLayout = ()=>{
        this.setState({
            is_show_select:true
        })
    }

    render() {
        const { 
            floorList,
            pageFlag,
            h5Flag
        } = this.props.createStore;
        
        return (
            <Fragment>
                <Content>
                    <Container
                        style={{ width: '100%' }}
                    >
                        <AlertWrap style={{ height: '100%' }}>
                            {/* <AlertWrap style={{ height: `${floorList.length * 600}px` }}> */}
                                {floorList.map((floor, idx) => {
                                    return <Fragment key={idx}>
                                        <p style={{ textAlign: 'center', color: '#fff', margin: '20px 0', fontSize: '20px' }}>第{idx + 1}页</p>
                                        {/* <PcAlert> */}
                                            <div style={{margin:'0 auto', maxWidth: pageFlag === h5Flag ? '375px' : '100%'}}>
                                                <FloorContent floorData={floor} />
                                            </div>
                                        {/* </PcAlert> */}
                                    </Fragment>
                                })}
                        </AlertWrap>
                        {floorList.length <= 0 ? <AddFloor>
                            <FlexLine>
                                <OptionButton onClick={() => {
                                    this.setState({
                                        isShowModal: true 
                                    })
                                }}>
                                    <Icon icon="icon-copy" style={{ fontSize: '42px' }} />
                                </OptionButton>
                                <p>添加弹窗模板</p>
                            </FlexLine>
                        </AddFloor> : <AddFloor>
                                <FlexLine>
                                    <OptionButton onClick={() => {
                                        this.setState({
                                            isShowModal: true
                                        })
                                    }}>
                                        <Icon icon="icon-copy" style={{ fontSize: '42px' }} />
                                    </OptionButton>
                                    <p>重新选择</p>
                                </FlexLine>
                        </AddFloor>}
                    </Container>
                </Content>
                {this.state.isShowModal && <AlertModal visible={this.state.isShowModal} onClose={()=>{
                    this.setState({
                        isShowModal:false
                    })
                }}></AlertModal>}
            </Fragment>
        );
    }
}

export default PageAlert;

const OptionButton = styled.div(()=>({
    color: '#000', 
    margin: '0 10px', 
    cursor: 'pointer',
    position:'relative',
    '&:hover':{
        color:'#26ABFF'
    }
}))

const AlertWrap = styled.div({
    width:'100%',
    height:'800px',
    paddingTop:'30px',
    background:'rgba(0,0,0,0.8)'
})

const Content = styled.div(({ style = {} }) => ({
    flex: 1,
    padding: '20px 20px 0',
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: '#F5F5F5',
    ...style
}))

const Container = styled.div(({ style = {} }) => ({
    ...style
}))

const AddFloor = styled.div({
    width: '100%',
    height: '220px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '20px 0',
    color: '#666',
    background: "#fff",
    cursor: 'pointer'
})
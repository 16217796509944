import React from 'react';
import styled from 'styled-components';
const Box = styled.div(({ styles }) => {
    return {
        width: '100%',
        maxWidth: '100%',
        margin: '0 auto',
        boxSizing: 'border-box',
        display:'flex',
        ...styles,
        "@media screen and (max-width: 768px)": {
            display: 'none'
        }
    }
});
function PcBaseBox({ children = '', styles = {} }) {
    return <Box className={'floor_content'} styles={{  ...styles, background: styles.backgroundColor }}>{children}</Box>
}

export default PcBaseBox;
import componentMount from "../../lib/componentMount";

import EditContainer from "./EditH5CodeContainer";
import Component from "./H5CodeContainer";
import BaseStyle from "../../BaseBox/BaseStyle";
import staticRender from "./StaticRender.js";

const _Render = componentMount({
    Component:Component,
    Content:EditContainer,
    Style:BaseStyle,
    text:'新用户code展示'
})

export default {
    componentMount:_Render,
    name:'H5CodeContainer',
    text:'新用户code展示',
    staticRender
}
import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import PageComponentsMachine from '../pageComponents/pageComponentsMachine';
import H5Alert from "../pageComponents/h5/H5Alert/H5Alert";
import PcAlert from "../pageComponents/pc/PcAlert/PcAlert";


import PcRowContent from "../pageComponents/pc/PcRowContent/PcRowContent";
import H5RowContent from "../pageComponents/h5/H5RowContent/H5RowContent";

@inject('viewStore')
@observer
class Preview extends Component{

    state ={
        isH5:false
    }
    componentWillMount(){
        const width = document.body.clientWidth
        this.setState({
            isH5:width < 500
        })
        
    }

    componentDidMount (){
        let html_json = localStorage.getItem('html_json')
        const { setHtmlJson } = this.props.viewStore
        if(html_json){
            setHtmlJson(JSON.parse(html_json))
        }
        window.isEdit = false
        // const Alert_By_Tool = document.getElementsByClassName("Alert_By_Tool")
        // if (Alert_By_Tool) {
        //     if (Alert_By_Tool.length > 1) {
        //         Alert_By_Tool[1].style.display = 'none'
        //         Alert_By_Tool[0].style.display = 'block'
        //     }
        // }
    }
    render() {
        // const {html_json } = this.props.viewStore
        // console.log('html_json',JSON.parse(JSON.stringify(html_json)));
        let _html_json = localStorage.getItem('html_json')
        let html_json = JSON.parse(_html_json)
        const {pc = [],h5 = []} = html_json
        return (
            <div style={{
                width:'100vw',
                height:'100vh',
                background:'rgba(0,0,0,0.8)',
                display:'flex',
                justifyContent:'center',
                alignItems:'center'
                }}>
                {
                    this.state.isH5 ?
                        <div style={{width:'95%'}}>
                            {
                                h5.map((arg, key) => {
                                    return (
                                        <H5Alert key={key} {...arg.props}>
                                            {
                                                arg.children.map((item, idx) => {
                                                    const { name, props = {}, id } = item;
                                                    if (!name) return '';
                                                    return (
                                                        <H5RowContent key={idx}  {...arg.props}>
                                                            {
                                                                item.children.map((ele, _indx) => (<div key={_indx}>
                                                                    {ele.name && PageComponentsMachine.render(ele.name).renderComponent(ele.props || {}) || ''}
                                                                </div>))
                                                            }
                                                        </H5RowContent>
                                                    )
                                                })
                                            }
                                        </H5Alert>
                                    )
                                })
                            }
                        </div>
                        :
                        <React.Fragment>
                            {
                                pc.map((arg, key) => (
                                    <PcAlert key={key} {...arg.props}>
                                        {
                                            arg.children.map((item, idx) => {
                                                const { name, props = {}, id } = item;
                                                if (!name) return '';
                                                return (
                                                    <PcRowContent key={idx} styles={props.styles}>
                                                        {
                                                            item.children.map((ele, _indx) => (<div key={_indx}>
                                                                {ele.name && PageComponentsMachine.render(ele.name).renderComponent(ele.props || {}) || ''}
                                                            </div>))
                                                        }
                                                    </PcRowContent>
                                                )
                                            })
                                        }
                                    </PcAlert>
                                ))
                            }
                        </React.Fragment>
                }
            </div>
        );
    }
}

export default Preview;
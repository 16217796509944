import * as XLSX from "xlsx";

export const TranslationDataPars = (obj) => {
  if (!obj) {
    return false;
  }
  const { enJson, esJson, deJson, ptJson } = obj;
  if (!enJson || !esJson || !deJson) {
    return false;
  }
  let parseEnJson = initTransationJson(enJson);
  let parseEsJson = initTransationJson(esJson);
  let parseDeJson = initTransationJson(deJson);
  let parsePtJson = initTransationJson(ptJson);

  let result = [];

  for (let key in parseEnJson) {
    let val = parseEnJson[key];
    let item = {
      name: key,
      en: val,
      es: parseEsJson[key],
      de: parseDeJson[key],
      pt: parsePtJson[key],
    };
    result.push(item);
  }
  return result;
};

function initTransationJson(jsonInfo) {
  if (!jsonInfo) {
    return {};
  }

  return ObjectForKeys(jsonInfo);
}

function ObjectForKeys(obj, parentKey = "") {
  let result = {};
  parentKey = parentKey ? `${parentKey}.` : "";
  for (let key in obj) {
    let val = obj[key];
    if (typeof val === "object") {
      const arg = ObjectForKeys(val, parentKey + key);
      result = { ...arg, ...result };
    } else {
      result[parentKey + key] = val;
    }
  }

  return result;
}

export function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
}

// 得到html代码中(嵌套)标签中的文字 <li><span><em>2333</em></span></li>
export function getContentOfTag(str) {
  while (true) {
    // 不停的去掉外层标签，如果去不掉就是找到了内容
    const result = str.match(/<([a-z]+)(?:.*?)>(.*?)<\/\1>/);
    if (result) {
      str = result[2];
    } else {
      return str;
    }
  }
}

// 下载为Json文件
export function downloadObjectAsJson(exportObj, exportName) {
  const dataStr =
    "data:text/json;charset=utf-8," +
    encodeURIComponent(JSON.stringify(exportObj));
  const downloadAnchorNode = document.createElement("a");
  downloadAnchorNode.setAttribute("href", dataStr);
  downloadAnchorNode.setAttribute("download", exportName + ".json");
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}

export async function convertToJson(files) {
  Array.from(files).forEach((file) => {
    const reader = new FileReader();
    reader.onload = async function (e) {
      const buffer = new Uint8Array(e.target.result);
      const workbook = XLSX.read(buffer, { type: "array" });
      const sheet_name_list = workbook.SheetNames;
      sheet_name_list.forEach(function (y) {
        const worksheet = workbook.Sheets[y];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);
        const langArr = [];
        jsonData.forEach(function (row) {
          if (row["Key值"] && row["Key值"].startsWith("data.")) {
            const values = Object.values(row);
            langArr.push([
              values[0].replace("data.", ""),
              values[values.length - 1],
            ]);
          }
        });
        const temp = arrayToJSON(langArr);
        downloadObjectAsJson(temp, file.name.split("-")[0]);
      });
    };
    reader.readAsArrayBuffer(file);
  });
}

const arrayToJSON = (array) => {
  const json = {};
  array.forEach((item) => {
    const [key, value] = item;
    const pathArray = key.split(".");
    let currentObj = json;
    for (let i = 0; i < pathArray.length - 1; i++) {
      const pathKey = pathArray[i];
      if (!currentObj[pathKey]) {
        currentObj[pathKey] = {};
      }
      currentObj = currentObj[pathKey];
    }
    currentObj[pathArray[pathArray.length - 1]] = value;
  });
  return json;
};

import React from 'react';
import styled from 'styled-components';
const Box = styled.div(({ styles }) => {
    return {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100px',
        ...styles,
    }
});
function H5RowContent({ children = '', styles = {} }) {
    return <Box styles={{ ...styles, background: styles.backgroundColor }}>{children}</Box>
}

export default H5RowContent;
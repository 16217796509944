import { toLine } from '../../../utils/transformCssPropertyName'

export default function staticRender ({ config, page_id = 0 }) {
  let { id, props = {} } = config
  id = `${page_id}-${id}`
  const { align, boxWidth = 100, videoSrc, autoPlay = true, loop = true, styles = {} } = props

  if (!videoSrc) {
    return {
      styles: [],
      html: ''
    }
  }

  const result = {
    styles: [
      {
        name: `.pt-h5-video-${page_id}`,
        content: `
          .pt-h5-video-${page_id} {
            display: flex;
            width: 100%;
            box-sizing: border-box;
          }
        `
      },
      {
        name: `pt-h5-video-${id} video`,
        content: `
          .pt-h5-video-${id} video {
            width: ${boxWidth}%;
            ${Object.keys(styles).reduce((pre, css) => `${pre} ${toLine(css)}:${styles[css]};`, '')}
          }
        `
      }
    ],
    html: `
      <div class="pt-h5-video-${page_id} pt-h5-video-${id}">
        <video
          ${autoPlay ? '' : 'controls'}
          ${autoPlay ? 'autoplay' : ''}
          ${loop ? 'loop' : ''}
          src="${videoSrc}"
          playsInline
          muted
        >
          您的浏览器不支持 video 标签。
        </video>
      </div>
    `,
  }
  if (align) {
    result.styles.push(
      {
        name: `.pt-h5-video-${id}`,
        content: `
          .pt-h5-video-${id} { justify-content: ${align}; }
        `
      }
    )
  }
  return result
}

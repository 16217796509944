
import * as React from 'react';
export default function Icon ({ icon, className, style }) {
  // const { icon, className, style } = props;
  return (
    <span
      className={`iconfont icon ${icon} ${className || ''}`}
      style={{ fontSize: '20px', verticalAlign: 'middle', ...style }}
    ></span>
  );
}
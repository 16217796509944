import React, { Fragment, Component } from 'react';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import SelectLayout from "./SelectLayout";

import FloorContent from "./FloorContent";
import { FlexLine } from '../../style';
import Icon from '../Icon';
import TemplateModal from '../Template/TemplateModal';
import AddSwiper from './AddSwiper';

//增加，删除，移动，编辑楼层
@inject('createStore')
@observer
class PageMid extends Component {
    state = {
        hoverId: 0,
        is_show_select:false,
        isShowModal:false,
        isAlert:false
    }


    handleSeletedFloorChild = (id, floorId) => {
        if (!id) return;
        const { setRowId } = this.props.createStore;;
        setRowId(id, floorId);
    }

    
    selectLayout = ()=>{
        this.setState({
            is_show_select:true
        })
    }

    renderTemplateOptions = ()=>{
        const { type, addSwiper } = this.props.createStore;

        return <Fragment>
            <OptionButton title="添加楼层" onClick={() => {
                this.setState({
                    is_show_select: true,
                    isAlert: type == 2
                })
            }}>
                <Icon icon="icon-add" style={{ fontSize: '42px' }} />
            </OptionButton>
            {type != 2 && type != 3 && <AddSwiper addSwiper={addSwiper} />}
        </Fragment>
    }


    renderSchemeseOptions = ()=>{
        const { type, addSwiper } = this.props.createStore;

        return <Fragment>
            {
                type == 2 ? <OptionButton title="添加弹窗" onClick={() => {
                    this.setState({
                        is_show_select: true,
                        isAlert: true
                    })
                }}>
                    <Icon icon="icon-add" style={{ fontSize: '42px' }} />
                </OptionButton> : <Fragment>
                        <OptionButton title="添加楼层" onClick={() => {
                            this.setState({
                                is_show_select: true,
                                isAlert: false
                            })
                        }}>
                            <Icon icon="icon-add" style={{ fontSize: '42px' }} />
                        </OptionButton>
                        {
                            type != 3 && <AddSwiper addSwiper={addSwiper} />
                        }
                        <OptionButton title="添加模版" onClick={() => {
                            this.setState({
                                isShowModal: true,
                                isAlert: false
                            })
                        }}>
                            <Icon icon="icon-copy" style={{ fontSize: '42px' }} />
                        </OptionButton>
                </Fragment>
            }
        </Fragment>
    }

    addFloor = ()=>{
        
    }

    render() {
        const { 
            floorList, 
            addFloor, 
            baseFloorItem, 
            pageFlag, 
            h5Flag,
            emailFlag,
            addAlert,
            isTemplate
        } = this.props.createStore;

        const isH5 = pageFlag === h5Flag
        const isEmail = pageFlag === emailFlag
        let _style = { width: '100%' }
        if (isH5){
            _style = { width: '375px', border: '1px solid #e4e4e4', marginTop: '60px' }
        }
        if(isEmail){
            _style = { width: '632px' }
        }
        return (
            <Fragment>
                <Content>
                    <Container
                        style={_style}
                    >
                        <div style={{backgroundColor:'#fff'}}>
                            {floorList.map((floor, idx) => (
                                <FloorContent floorData={floor} key={idx} />
                            ))}
                        </div>
                        <AddFloor isH5={isH5}>
                            {
                                !this.state.is_show_select ? <React.Fragment >
                                    <FlexLine>
                                        {
                                            isTemplate ? this.renderTemplateOptions() : this.renderSchemeseOptions()
                                        }
                                    </FlexLine>
                                </React.Fragment> : 
                                    <SelectLayout 
                                        isH5={pageFlag === h5Flag} 
                                        baseFloorItem={baseFloorItem} 
                                        addFloor={(_floor)=>{
                                            this.state.isAlert ? addAlert(_floor) : addFloor(_floor)
                                            this.setState({
                                                is_show_select: false
                                            })
                                        }}
                                    />
                            }
                        </AddFloor>
                    </Container>
                </Content>
                {this.state.isShowModal && <TemplateModal visible={this.state.isShowModal} onClose={()=>{
                    this.setState({
                        isShowModal:false
                    })
                }}></TemplateModal>}
            </Fragment>
        );
    }
}

export default PageMid;

const OptionButton = styled.div(({title})=>({
    color: '#000', 
    margin: '0 10px', 
    cursor: 'pointer',
    position:'relative',
    '&:hover':{
        color:'#26ABFF'
    },
    '&:hover::after':{
        content: `"${title}"`,
        width:"60px",
        textAlign:'center',
        position:'absolute',
        bottom:'-40px',
        right:'-10px',
        zIndex:99,
        backgroundColor:'rgba(0,0,0,0.8)',
        fontSize:'12px',
        color:'#fff',
        padding:'10px 0'
    }
}))

const Content = styled.div(({ style = {} }) => ({
    flex: 1,
    padding: '20px 20px 0',
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: '#F5F5F5',
    paddingTop:'60px',
    ...style
}))

const Container = styled.div(({ style = {} }) => ({
    paddingTop:'50px',
    ...style
}))

const AddFloor = styled.div({
    width: '100%',
    height: props => props.isH5 ? '120px' : '220px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '20px 0',
    color: '#666',
    background: "#fff",
    cursor: 'pointer'
})
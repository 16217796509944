// H5 文本
import React from 'react';
import styled from 'styled-components';

const Text = styled.div(({ styles, boxWidth }) => {
    return {
        fontSize: '14px',
        lineHeight: '1.35',
        textAlign: 'left',
        color: '#333',
        width: `${boxWidth}%`,
        "&>p": {
            margin: 0
        },
        ...styles
    }
})

const Container = styled.div(({ styles }) => ({
    display: 'flex',
    width: '100%',
    boxSizing: 'border-box',
    ...styles
}))


function H5Text ({ text = '请输入文本', styles = {}, boxWidth = '100', align = '' }) {

    return (
        <Container styles={align ? { justifyContent: align } : {}}>
            <Text styles={styles} boxWidth={boxWidth} dangerouslySetInnerHTML={{ __html: text }}></Text>
        </Container>
    )
}

export default H5Text;
import { toLine } from "../../../utils/transformCssPropertyName"

export default function staticRender ({ config, page_id = 0 }) {
  let { id, props = {} } = config
  id = `${page_id}-${id}`
  let {
    text = 'Code:',
    styles = {},
    align = '',
    boxWidth = 100
  } = props

  const result = {
    styles: [
      {
        name: `.pt-code-container-${page_id}`,
        content: `
          .pt-code-container-${page_id} {
            display: flex;
            width: 100%;
            box-sizing: border-box;
          }
          .pt-code-container-inner {
            font-size: 28px;
            font-weight: 800;
          }
          .pt-code-container-text {
            margin-right: 5px;
          }
        `
      },
      {
        name: `.pt-code-container-${id}`,
        content: `
          ${align ? `
            .pt-code-container-${id} {
              justify-content: ${align};
            }
          ` : ''}
          .pt-code-container-inner-${id} {
            width: ${boxWidth}%;
            ${Object.keys(styles).map(css => `${toLine(css)}:${styles[css]};`).join(' ')}
          }
        `
      }
    ],
    html: `
      <div class="pt-code-container-${page_id} pt-code-container-${id}" id="new_user_code_container">
        <div class="pt-code-container-inner pt-code-container-inner-${id}">
          ${text ? `<span class="pt-code-container-text">${text}</span>` : ''}
          <span id="new_user_alert_code"></span>
        </div>
      </div>
    `,
  }
  return result
}

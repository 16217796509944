import React, { Fragment } from 'react';
import styled from 'styled-components';
import PageLeft from '../create/PageLeft';
import PageMid from './PageMid';
import PageAlert from './PageAlert';

import PageRight from '../create/PageRight';
const ContentWrap = styled.div({
    display: 'flex',
    height: '100%'
})
function CreateEmailTplPage({ type, isTemplate }) {
    return (
        <Fragment>
            <ContentWrap>
                <PageLeft />
                {(type === 1 || type === 3 || isTemplate) && <PageMid />}
                {type === 2 && !isTemplate && <PageAlert />}
                <PageRight />
            </ContentWrap>
        </Fragment>
    );
}

export default CreateEmailTplPage;
import { getContentOfTag } from "../../../utils/helps"
import { toLine } from "../../../utils/transformCssPropertyName"

export default function staticRender({ config }) {
    const { id, props = {} } = config
    let {
        text = "请输入文本",
        styles = {},
        boxWidth = '100',
        align = ''
    } = props
    styles = {
        fontSize: '14px',
        lineHeight: '1.5',
        textAlign: 'center',
        color: '#333',
        width: `${boxWidth}%`,
        ...styles
    }
    const emailTextInnerStyle = Object.keys(styles).map(css => (`${toLine(css)}:${styles[css]};`)).join(' ')
    // 给p标签加上指定样式
    // text = text.replace(/<p([^>]+)>/g, (match, p1, offset, str) => {
    //     return `<p${p1.substring(0, p1.length - 1)}margin:0;word-break:break-word${p1[p1.length - 1]}>`
    // }).replace(/(<p>)/g, (match, p1, offset, str) => {
    //     return `<p style="margin:0;word-break:break-word;">`
    // })

    return {
        styles: [],
        html: `
            <div class="email_text" style="display:flex; width:100%; box-sizing:border-box; ${align ? `justify-content:${align};` : ''}">
                <div class="email_text_inner" style="${emailTextInnerStyle}">${text}</div>
            </div>
        `
    }
}

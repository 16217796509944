// 合并多个stroe

import HomeStore from './homeStore';
import CreateStore from './createStore';
import ViewStore from './viewStore';
import TranslationStore from './translationStore';
import TemplateStore from "./templateStore";
import FileStore from './fileStore';
import SiteStore from './siteStore';
const homeStore = new HomeStore();
const createStore = new CreateStore();
const viewStore = new ViewStore();
const translationStore = new TranslationStore();
const templateStore = new TemplateStore();
const fileStore = new FileStore();
const siteStore = new SiteStore();


const stores = {
    createStore,
    homeStore,
    viewStore,
    translationStore,
    templateStore,
    fileStore,
    siteStore
}

export default stores;
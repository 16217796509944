import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { ContentTitle, Input, TextArea, Button, FlexLine } from '../../../style';
import Percentage from "../../EditComponents/Percentage";
import ContentAlign from '../../EditComponents/ContentAlign';
import styled from 'styled-components';
import { WhiteSpace } from '../../../components/index';
const MySelect = styled.select({
    border: '1px solid #e4e4e4',
    padding: '10px',
    background: '#fff',
    borderRadius: '4px',
    flex: 1,
    margin: '10px 0'
})

@inject('createStore')
@observer
class EditPcAlertCoupon extends Component {

    proportionChange = (value) => {
        const { updateDataProps } = this.props.createStore;
        updateDataProps('boxWidth', value);
    }

    alignChange = (value) => {
        const { updateDataProps } = this.props.createStore;
        updateDataProps('align', value);
    }
    render() {
        const {
            placeholder,
            buttonText,
            lang = "en",
            align,
            error_color = '#d25151',
            boxWidth = '100'
        } = this.props;
        const { updateDataProps } = this.props.createStore;
        return (
            <Fragment>
                <FlexLine justify="flex-start">
                    <ContentTitle>多语言：</ContentTitle>
                    <MySelect
                        onChange={(e) => {
                            updateDataProps('lang', e.target.value);
                        }}
                        value={lang}>
                        <option value="en">英语</option>
                        <option value="es">西语</option>
                        <option value="de">德语</option>
                        <option value="it">意大利语</option>
                        <option value="fr">法语</option>
                        <option value="pt">葡萄牙语</option>
                        <option value="jp">日语</option>
                        <option value="ro">罗马利亚语</option>
                        <option value="pl">波兰语</option>
                        <option value="sa">阿拉伯语</option>
                        <option value="nl">荷兰语</option>
                        <option value="sv">瑞典语</option>
                        <option value="el">希腊语</option>
                    </MySelect>
                </FlexLine>
                <ContentTitle>输入框提示文本：</ContentTitle>
                <Input
                    value={placeholder}
                    placeholder={'输入框提示文本'}
                    onChange={(e) => updateDataProps('placeholder', e.target.value)}
                    style={{ width: '100%', boxSizing: 'border-box' }}
                />
                <ContentTitle>领取按钮文本:</ContentTitle>
                <Input
                    value={buttonText}
                    placeholder={'输入领取按钮文本'}
                    onChange={(e) => updateDataProps('buttonText', e.target.value)}
                    style={{ width: '100%', boxSizing: 'border-box' }}
                />
                <WhiteSpace />
                <p style={{ fontSize: "12px", color: '#666' }}>选择错误提示文案颜色:</p>
                <div style={{ paddingRight: '10px' }}>
                    <FlexLine justify='space-between'>
                        <Input
                            value={error_color}
                            type="color"
                            onChange={(e) => updateDataProps('error_color', e.target.value)}
                            style={{ width: '28px', padding: '2px' }}
                        />
                        <Input
                            type="text"
                            placeholder={'选中颜色/输入颜色'}
                            value={error_color || ''}
                            style={{ flex: 1, padding: '2px 10px' }}
                            onChange={(e) => updateDataProps('error_color', e.target.value)}
                        />
                    </FlexLine>
                </div>
                <WhiteSpace />
                <Percentage percent={boxWidth} onChange={this.proportionChange} />
                <WhiteSpace />
                <ContentAlign align={align} onChange={this.alignChange} />
            </Fragment>
        )
    }
}

export default EditPcAlertCoupon;
import axios from 'axios';

export const {
  REACT_APP_BASE_URL = '',
  PRODUCT_ENV,
  S3_URL = 'https://s3-us-west-1.amazonaws.com/imgtest.firmoo.com/'
} = process.env;

console.log('PRODUCT_ENV', PRODUCT_ENV);

const instance = axios.create({
  baseURL: REACT_APP_BASE_URL,
  withCredentials: true
});

const sendTicket = instance.interceptors.request.use(function (config) {
  // Do something before request is sent
  const localUrl = new URL(location.href);
  if (localUrl.searchParams.has('ticket')) {
    history.replaceState(null, null, localUrl.pathname);
    const ticket = localUrl.searchParams.get('ticket');
    if (config.params) {
      config.params.ticket = ticket;
    } else {
      config.params = {
        ticket: ticket
      };
    }
  }
  instance.interceptors.request.eject(sendTicket);
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

const redirectLogin = instance.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  instance.interceptors.response.eject(redirectLogin);
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (error.response.status === 401) {
    location.href = `${error.response.data.location}&redirect_url=${encodeURIComponent(location.href)}`;
  }
  return Promise.reject(error);
});

export async function axiosGet (url, params, headers = {}) {
  return instance.get(url, {
    params: params,
    headers: headers
  }).then(res => {
    return res.data;
  });
}

export async function axiosPost (url, params) {
  return instance.post(url, params).then(res => {
    return res.data;
  });
}

export async function axiosDelete (url, params) {
  return instance.delete(url, {
    params: params
  }).then(res => {
    return res.data;
  });
}

export async function axiosPut (url, params) {
  return instance.put(url, params).then(res => {
    return res.data;
  });
}

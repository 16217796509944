

import Img from "./Img";
import Text from "./Text";
import Title from "./Title";
import EmailBaseBox from "./EmailBaseBox";
import EmailRowContent from "./EmailRowContent";
import Button from "./Button";
import EmailProduct from "./EmailProduct";

export default [
  Img,
  Text,
  Title,
  EmailBaseBox,
  EmailRowContent,
  Button,
  EmailProduct
]
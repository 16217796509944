//翻译数据管理

import { observable,action } from "mobx";
class translationStore {
    @observable
    transList = [  
    'common',
    'account',
    'specialSub',
    'productDetail',
    'lens',
    'review',
    'checkout',
    'basket',
    'login',
    'rx',
    'unsub',
    'sortByFace',
    'ztPage',
    'notice',
    'refund'
  ]

  @observable
  transData = {
      'common':{},
      'account':{},
      'specialSub':{},
      'productDetail':{},
      'lens':{},
      'review':{},
      'checkout':{},
      'basket':{},
      'login':{},
      'rx':{},
      'unsub':{},
      'sortByFace':{},
      'ztPage':{},
      'notice':{},
      'refund':{}
  }

  @action 
  setTransJson =(name,data)=> {
    this.transData[name] = data
  }
}
export default translationStore;
import React from 'react';
import styled from 'styled-components';

const Box = styled.div(({ styles }) => {
  return {
    margin: '0 auto',
    boxSizing: 'border-box',
    background:'#fff',
    maxWidth:'100%',
    display:'flex',
    ...styles,
    "@media screen and (min-width: 768px)": {
      display: window && window.isEdit ? 'flex' : 'none'
    }

  }
});


function H5Alert ({ children = '', styles = {}, isHide=false }) {
  return <div className="H5_Alert_By_Tool" style={{ display: isHide && !window.isEdit ? 'none' : 'block' }}>
    <Box styles={{ ...styles, background: styles.backgroundColor || '#fff' }}>{children}</Box>
  </div>
}

export default H5Alert;
import React, { Component,Fragment } from 'react';
import styled from 'styled-components';
import Icon from '../Icon';
import { Modal } from '../../pageComponents/pc/PcComponent';
import { Button, ContentTitle,Input } from '../../style';


class AddSwiper extends Component{

  state = {
    visible:false,
    number:1,
    slider:1
  }

  toggerVisible = ()=>{
    this.setState((preState)=>({
      visible: !preState.visible
    }))
  }

  sliderChang = (e)=>{
    this.setState({
      slider:e.target.value
    })
  }
  numberChang = (e)=>{
    this.setState({
      number: e.target.value
    })
  }

 onSubmit = ()=>{
   if(this.state.number <1 || this.state.slider < 1){
     return
   }

   this.props.addSwiper(this.state.number, this.state.slider)

   this.toggerVisible()

 }

  render() {

    return (
      <Fragment>
        <OptionButton title="添加滑块" onClick={this.toggerVisible}>
          <Icon icon="icon-shuffling" style={{ fontSize: '42px' }} />
        </OptionButton>
        <Modal show={this.state.visible} onclose={this.toggerVisible} width={'600px'}>
          <div style={{padding:'40px'}}>
            <ContentTitle style={{marginBottom:'20px',textAlign:'center',fontSize:'18px'}}>轮播设置</ContentTitle>
            <ContentTitle>每屏数量(1-4)：</ContentTitle>
            <Input 
              style={{ width: '100%' }} 
              value={this.state.slider} 
              onChange={this.sliderChang} 
              type={'number'}
            />
            <ContentTitle style={{ marginTop: '20px' }}>总数量（>=1）：</ContentTitle>
            <Input 
              style={{ width: '100%' }} 
              value={this.state.number} 
              onChange={this.numberChang}
              type={'number'}/>
            <Button style={{ marginTop: '40px' }} onClick={this.onSubmit}>确定</Button>
          </div>
        </Modal>
      </Fragment>
    );
  }
}

export default AddSwiper;



const OptionButton = styled.div(({ title }) => ({
  color: '#000',
  margin: '0 10px',
  cursor: 'pointer',
  position: 'relative',
  '&:hover': {
    color: '#26ABFF'
  },
  '&:hover::after': {
    content: `"${title}"`,
    width: "60px",
    textAlign: 'center',
    position: 'absolute',
    bottom: '-40px',
    right: '-10px',
    zIndex: 99,
    backgroundColor: 'rgba(0,0,0,0.8)',
    fontSize: '12px',
    color: '#fff',
    padding: '10px 0'
  }
}))
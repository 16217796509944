import { toLine } from '../../../utils/transformCssPropertyName'

export default function staticRender ({ config, page_id = 0 }) {
  let { id, props = {} } = config
  id = `${page_id}-${id}`
  const {
    price,
    pre_price,
    product_name,
    href,
    btn_size = 'md',
    btn_color = '#000',
    btn_text = 'Shop Now >',
    imgSrc = "https://df5apg8r0m634.cloudfront.net/images/2023/0428/10f199161ca9a9605534e4211763c1af.jpg",
    styles = {},
    isShowPrice = true,
    isShowName = true,
    isShowBtn = true,
    product_style = '',
    isBorderBtn = false
  } = props


  let btnStyle = {
    width: '120px',
    lineHeight: '36px',
    fontSize: '14px',
    backgroundColor: isBorderBtn ? '#fff' : btn_color,
    color: !isBorderBtn ? '#fff' : btn_color,
    border: isBorderBtn ? `1px solid ${btn_color}` : 'unset'
  }
  if (btn_size === 'md') {
    btnStyle = {
      ...btnStyle,
      width: '100px',
      lineHeight: '28px',
      fontSize: '12px'
    }
  }
  if (btn_size === 'ms') {
    btnStyle = {
      ...btnStyle,
      width: 'unset',
      lineHeight: 'unset',
      fontSize: '12px',
      padding: '5px 15px'
    }
  }
  if (btn_size === 'auto') {
    btnStyle = {
      ...btnStyle,
      width: 'unset',
      lineHeight: 'unset',
      fontSize: '14px',
      padding: '10px 30px'
    }
  }

  const result = {
    styles: [
      {
        name: `.pt-h5-product-${page_id}`,
        content: `
          .pt-h5-product-${page_id} {
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                -webkit-flex-direction: column;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-align-items: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                width: 100%;
                padding: 30px 10px;
                box-sizing: border-box;
                font-family:Roboto-Regular;
          }
          .pt-h5-product-${page_id} img {
                width: 100%;
                min-height: 70px;
          }

          .pt-h5-product-${page_id} .price-line {
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: center;
                -webkit-justify-content: center;
                -ms-flex-pack: center;
                justify-content: center;
                -webkit-align-items: center;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
          }
          .pt-h5-product-${page_id} .pre-price{
              color:#666666;
              font-size:14px;
              margin-right:5px;
          }
          .pt-h5-product-${page_id} .price{
              color: #333;
              font-size: 14px;
              font-family:'Roboto-Medium';
          }          
          .pt-h5-product-${page_id} .product-name{
              margin-top:5px;
              font-size:14px;
              text-align:center;
          }
          .pt-h5-product-${page_id} a{
              cursor:pointer;
          }
        `
      },
      {
        name: `.pt-h5-product-${id}`,
        content: `
          .pt-h5-product-${id} .product-btn{
              margin-top:5px;
              border-radius:4px;
              text-align:center;
              line-height:36px;
              cursor:pointer;
              color:#fff;
              font-size:14px;
              ${Object.keys(btnStyle).map(css => `${toLine(css)}:${btnStyle[css]};`).join(' ')}
          }
        `
      }
    ],
    html: `
      <div class="pt-h5-product-${page_id} pt-h5-product-${id}">
        <a href="${href ? href : ''}" target="_blank"><img src="${imgSrc}"/></a>
        ${isShowPrice ? `<div class="price-line">
          ${pre_price ? `<s class="pre-price">${pre_price}</s>` : ``}
          ${price ? `<span class="price">${price}</span>` : ``}
        </div>` : ''
      }
        ${product_name && isShowName ? `<div class="product-name">${product_style ? product_style : ''}<span style="font-family: Roboto-Medium;">${product_name}</span> </div>` : ''}
        ${isShowBtn ? `<a href="${href ? href : ''}" target="_blank">
            <div class="product-btn">${btn_text}</div>
          </a>` : ''
      }
      </div>
    `,
  }

  return result
}

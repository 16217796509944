import { Button as AntButton } from 'antd';
import React, { Component } from 'react';
import { Button, TextArea } from '../../style';
import { observer, inject } from 'mobx-react';
import { Modal } from '../../pageComponents/pc/PcComponent';
import PageComponentsMachine from '../../pageComponents/pageComponentsMachine';

import { toast } from 'react-toastify';
import getEmailHtmlByConfig from '../../utils/getEmailHtmlByConfig';
import loadScript from '../../utils/loadScript';
@inject(({ createStore }) => ({
  emailFloorList: createStore.emailFloorList
}))
@observer
class EmailPreBtn extends Component {
  state = {
    isShowModal: false,
    emailHtml: ''
  }

  closeModal = () => {
    this.setState({
      emailHtml: '',
      isShowModal: false
    })
  }

  openMoadl = async () => {
    if (!this.state.isShowModal && this.props.emailFloorList.length < 1) {
      toast("没有内容，请添加邮件内容", {
        type: 'error'
      });
      return
    }

    await loadScript('/postcss.js')
    const { code } = await getEmailHtmlByConfig({ emailFloorList: this.props.emailFloorList })
    this.setState({
      isShowModal: true
    }, () => {
      this.setState({
        emailHtml: code
      })
    })

  }

  textArea
  copyCode = () => {
    this.textArea.select();
    if (document.execCommand('copy')) {
      toast("复制成功", {
        type: 'success'
      });
    } else {
      toast("复制失败", {
        type: 'error'
      });
    }
  }

  render() {
    const { emailFloorList } = this.props;
    return (
      <div>
        <Button onClick={this.openMoadl}>导出(新)</Button>
        <Modal show={this.state.isShowModal} onclose={this.closeModal} width={'60%'}>
          <div style={{ padding: '20px' }}>
            <TextArea
              value={this.state.emailHtml}
              style={{ width: '100%', height: '400px' }}
              ref={element => this.textArea = element}
            ></TextArea>
            <div style={{ textAlign: 'right' }}>
              <AntButton onClick={this.copyCode}>全选复制</AntButton>
            </div>
          </div>
          <div id="email-container" style={{ visibility: 'hidden', height: 0 }}>
            <div style={{ width: '100%', maxWidth: '632px', margin: '0 auto' }} >
              {
                emailFloorList.map((arg, key) => (
                  <div
                    key={key}
                    style={{
                      boxSizing: 'border-box',
                      display: 'flex',
                      ...arg.props.styles
                    }}
                  >
                    {
                      arg.children.map((item, idx) => {
                        const { name, props = {}, id } = item;
                        if (!name) return '';
                        return (
                          <div
                            key={idx}
                            style={{
                              boxSizing: 'border-box',
                              display: 'flex',
                              flexDirection: 'column',
                              minHeight: '100px',
                              ...props.styles
                            }}>
                            {
                              item.children.map((ele, _indx) => (<div key={_indx}>
                                {ele.name && PageComponentsMachine.render(ele.name).renderComponent(ele.props || {}) || ''}
                              </div>))
                            }
                          </div>
                        )
                      })
                    }
                  </div>
                ))
              }
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default EmailPreBtn
import { toLine } from "../../../utils/transformCssPropertyName"

export default function staticRender ({ config, page_id = 0 }) {
  let { id, props = {} } = config
  id = `${page_id}-${id}`
  let {
    align,
    styles,
    endTime = '',
    day = 'Day',
    hours = 'Hours',
    minutes = 'Minutes',
    seconds = 'Seconds',
    bgColor = "#000",
    textColor = "#fff",
  } = props

  const result = {
    styles: [
      {
        name: `.pt-time-${page_id}`,
        content: `
          .pt-time-${page_id} {
            display: flex;
            width: 100%;
            box-sizing: border-box;
          }
          .pt-time-flex {
            display: flex;
            align-items: center;
          }
          .pt-time-content {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            background: #000;
            color: #fff;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
          .pt-time-text {
            font-size: 32px;
            font-weight: 800px;
            margin-bottom: 5px;
          }
          .pt-time-text-sub {
            font-size: 18px;
            font-weight: 800px;
          }
          .pt-text-min {
            margin: 0 15px;
            font-size: 28px;
            font-weight: 800px;
          }
        `
      },
      {
        name: `.pt-time-flex-${id}`,
        content: `
          .pt-time-flex-${id} {
            ${Object.keys(styles).map(css => {
          return `${toLine(css)}:${styles[css]};`
        }).join(' ')}
          }
          .pt-time-content-${id} {
            background: ${bgColor || 'initial'};
            color: ${textColor || 'initial'};
          }
          .pt-text-min-${id} {
            color: ${bgColor};
          }
        `
      }
    ],
    html: `
      <div class="pt-time-${page_id} pt-time-${id}">
        <div class="pt-time-flex pt-time-flex-${id}">
          <div class="pt-time-content pt-time-content-${id}">
            <div class="pt-time-text">0</div>
            <div class="pt-time-text-sub">${day || ''}</div>
          </div>
          <div class="pt-text-min pt-text-min-${id}"></div>
          <div class="pt-time-content pt-time-content-${id}">
            <div class="pt-time-text">00</div>
            <div class="pt-time-text-sub">${hours || ''}</div>
          </div>
          <div class="pt-text-min pt-text-min-${id}"></div>
          <div class="pt-time-content pt-time-content-${id}">
            <div class="pt-time-text">00</div>
            <div class="pt-time-text-sub">${minutes || ''}</div>
          </div>
          <div class="pt-text-min pt-text-min-${id}"></div>
          <div class="pt-time-content pt-time-content-${id}">
            <div class="pt-time-text">00</div>
            <div class="pt-time-text-sub">${seconds || ''}</div>
          </div>
        </div>
      </div>
      ${(() => {
        if (!endTime) return ''
        let now = Math.floor(Date.now() / 1000)
        let end = Math.floor(new Date(endTime).getTime() / 1000)
        if (now > end) return ''
        return `
          <script>
            (function() {
              function addZero(num) {
                if (num < 10) return '0' + num
                return num
              }
              var end = ${end};
              var elements = document.querySelectorAll('.pt-time-${id} .pt-time-text')
              var intervalId = setInterval(() => {
                var now = Math.floor(Date.now() / 1000)
                if (now >= end) {
                  elements[0].innerText = '0'
                  elements[1].innerText = '00'
                  elements[2].innerText = '00'
                  elements[3].innerText = '00'
                  clearInterval(intervalId)
                } else {
                  var lastTime = end - now
                  if (lastTime < 0) lastTime = 0
                  var day = lastTime > 86400 ? Math.floor(lastTime / 86400) : 0
                  elements[0].innerText = addZero(day)
                  lastTime = lastTime - day * 86400
                  var hour = lastTime > 3600 ? Math.floor(lastTime / 3600) : 0
                  elements[1].innerText = addZero(hour)
                  lastTime = lastTime - hour * 3600
                  var min = lastTime > 60 ? Math.floor(lastTime / 60) : 0
                  elements[2].innerText = addZero(min)
                  lastTime = lastTime - min * 60
                  elements[3].innerText = addZero(lastTime)
                }
              }, 1000)
            })()
          </script>
        `
      })()}
    `,
  }
  if (align) {
    result.styles.push(
      {
        name: `.pt-time-${id}`,
        content: `
          .pt-time-${id} {
            justify-content: ${align};
          }
        `
      }
    )
  }
  return result
}

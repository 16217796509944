


import { toast } from 'react-toastify';

export function onContentCopy (data) {
  localStorage.setItem("copy_info", JSON.stringify(data))
  toast("已复制", {
    type: 'success'
  });
}

export function onContentPaste (copy_data, copyContent) {
  let copy_info = localStorage.getItem('copy_info')
  if (!copy_info) {
    toast("粘贴失败，请先复制内容！", {
      type: 'error'
    });
    return
  }

  let copy_info_json = JSON.parse(copy_info)
  if (copy_info_json.name != copy_data.name) {
    toast("粘贴失败，复制内容属性不同！", {
      type: 'error'
    });
    return
  }
  copyContent && copyContent(copy_data.id, copy_info_json)
}
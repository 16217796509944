import { toLine } from "../../../utils/transformCssPropertyName";

export default function staticRender({ config, page_id = 0 }) {
  let { id, props = {} } = config;
  id = `${page_id}-${id}`;
  const { styles = {} } = props;
  return {
    styles: [
      {
        name: `.pt-h5-floor-${page_id}`,
        content: `
          .pt-h5-floor-${page_id} {
            box-sizing: border-box;
            display: flex;
          }
        `,
      },
      {
        name: `.pt-h5-floor-${id}`,
        content: `
          .pt-h5-floor-${id} {
            ${Object.keys(styles)
              .map((css) => {
                return `${toLine(css)}:${styles[css]};`;
              })
              .join(" ")}
          }
        `,
      },
    ],
    html: `
      <div class="pt-h5-floor-${page_id} pt-h5-floor-${id}">
        {{CHILDREN_PLACEHOLDER}}
      </div>
    `,
  };
}


import React, { useState, useEffect, Fragment } from 'react';
import styled from 'styled-components';



const FormWrap = styled.div({
    // padding: '40px 0'
})
const InputEmail = styled.input({
    display: 'block',
    width: '100%',
    border: '1px solid rgba(228, 228, 228, 1)',
    boxSizing: 'border-box',
    // padding: '20px 16px',
    lineHeight: 1,
    color: '#333',
    fontSize: '14px',
    margin: '0 auto 20px',
    fontFamily: 'Roboto-Regular',
    placeContent: {
        color: "#999"
    },
    textAlign: 'center',
    borderRadius: '4px',
    height: '40px'

});
const SubmitBtn = styled.button(({ btnBgColor }) => {
    return {
        border: 'none',
        fontSize: '18px',
        color: '#fff',
        lineHeight: 1,
        width:'100%',
        height:'40px',
        margin: '0 auto',
        background: '#26ABFF',
        borderRadius: '4px',
        display: 'block',
        backgroundColor: btnBgColor,

    }
})
// 输入邮箱领取优惠券
// 弹窗领取
/**
 * 
 * @param {*} notNeedEmail 直接领取 
 * @param {*} hoverButtonText 悬浮按钮文本
 * @param {*} couponCode 固定优惠券
 *  
 */
function H5AlertCoupon({
    buttonText = 'Get Code',
    placeholder = 'Enter your email address to get code',
    lang="en",
    styles={},
    boxWidth = '100',
    align = '',
    error_color = '#d25151',
    btnBgColor = '#26ABFF' //按钮颜色
}) {
    const [email, setEmail] = useState('');
    //接口错误提示弹窗
    const [msg, setMsg] = useState('');
    return (
        <div style={{ display: 'flex', justifyContent: align ? align : 'flex-start' }}>
            <div style={{ width: `${boxWidth}%` }}>
                <form onSubmit={(e) => {
                    e.preventDefault()
                    handleSubmit(lang, email, e.target.querySelector('button'), (data) => {
                        const { coupon_code = '', expire_date = '', special_code, special_expire_date, status, msg } = data;
                        //提示msg
                        if (coupon_code && expire_date) {
                            AlertCopy(coupon_code)
                            const H5_Alert_By_Tool = document.getElementsByClassName("H5_Alert_By_Tool")
                            if (H5_Alert_By_Tool) {
                                if (H5_Alert_By_Tool.length > 1) {
                                    H5_Alert_By_Tool[0].style.display = 'none'
                                    H5_Alert_By_Tool[1].style.display = 'block'

                                    if (document.getElementById('h5_new_user_alert_code')) {
                                        document.getElementById('h5_new_user_alert_code').innerText = coupon_code
                                    }
                                }
                            }
                            setEmail('');
                        } else if (status == 0 && special_code && special_expire_date) {

                            AlertCopy(special_code)
                            const H5_Alert_By_Tool = document.getElementsByClassName("H5_Alert_By_Tool")
                            if (H5_Alert_By_Tool) {
                                if (H5_Alert_By_Tool.length > 1) {
                                    H5_Alert_By_Tool[0].style.display = 'none'
                                    H5_Alert_By_Tool[1].style.display = 'block'

                                    if (document.getElementById('h5_new_user_alert_code')) {
                                        document.getElementById('h5_new_user_alert_code').innerText = special_code
                                    }
                                }
                            }

                            setEmail('');
                        } else {
                            //提示msg
                            msg && setMsg(msg)
                        }
                    });
                }}>
                    <InputEmail
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        type='email'
                        placeholder={placeholder} />
                    <SubmitBtn style={{ ...styles}} type='submit'>{buttonText}</SubmitBtn>
                </form>
                <div style={{ marginTop: '10px', textAlign: 'center', color: error_color }}>{window.isEdit ? "Prompt copy of code failure" : msg}</div>
            </div>
        </div>
    )
}

function handleSubmit(lang,email, elBtn, cb) {
    //是否需要增加邮箱验证
    // var emailReg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,6})$/;
    if (!email) return false;

    window.gtag && window.gtag('event', 'New user pop-up_Click', {});

    const oldBtnText = elBtn.innerText;
    const oldBg = getComputedStyle(elBtn)['background-color'];
    function disabledButton(el) {
        el.setAttribute('disabled', 'true');
        el.style.background = '#ccc';
        el.innerText = 'loading...';
    }
    function activeButton(el) {
        el.removeAttribute('disabled');
        el.style.background = oldBg;
        el.innerText = oldBtnText;
    }
    //改变 button loading状态
    let scheme_id = '';
    let group_scheme_id = '';

    const dataDiv = document.querySelector('.UP_config_html');
    
    if (dataDiv) {
        scheme_id = dataDiv.getAttribute('data-scheme_id');
        group_scheme_id = dataDiv.getAttribute('data-group_scheme_id');
    }

    if (window.UnicodeTransverter) {
        if (document.querySelector('#UP' + window.UnicodeTransverter(window.location.hash.substr(1)))){
            var popup_data_save = document.querySelector('#UP' + window.UnicodeTransverter(window.location.hash.substr(1))).upalert.config.popup_data_save;
            group_scheme_id = popup_data_save.group_scheme_id
            scheme_id = popup_data_save.scheme.id
        }
    }


    const postUrl = '/remoteapi/common/get/coupon';
    var xhr = new XMLHttpRequest();
    xhr.open('POST', postUrl);
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");  //否则数据无法被正常接收
    xhr.setRequestHeader("accept-language", lang);  //否则数据无法被正常接收

    xhr.send(`email_address=${email}&scheme_id=${scheme_id}&group_scheme_id=${group_scheme_id}`);
    disabledButton(elBtn);
    xhr.onreadystatechange = function () {

        if (xhr.readyState == 4) {
            activeButton(elBtn);
            if (xhr.status === 200) {
                const data = JSON.parse(xhr.responseText);
                cb && cb(data);
            }
            //清空邮箱
        };

    }
}

export default H5AlertCoupon;

function AlertCopy (code) {
    var textarea = document.createElement('p')
    textarea.style = "opacity:0;top:0;z-index:-999;left:0;position:absolute"
    textarea.innerHTML = code
    var $textarea = textarea


    document.body.appendChild($textarea)

    let range = document.createRange();
    range.selectNodeContents($textarea);
    let selection = document.getSelection();

    selection.removeAllRanges();
    selection.addRange(range);
    document.execCommand('Copy');

    var timer = setTimeout(function () {
        document.body.removeChild($textarea)
        clearTimeout(timer);
    }, 1500);
    return true;
}

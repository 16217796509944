import componentMount from "../../lib/componentMount";

import EditContainer from "./EditPcText";
import Component from "./PcText";
import BaseStyle from "../../BaseBox/BaseStyle";
import staticRender from "./StaticRender.js";

const _Render = componentMount({
    Component:Component,
    Content:EditContainer,
    Style:BaseStyle,
    text:'文本'
})

export default {
    componentMount:_Render,
    name:'PcText',
    text:'文本',
    staticRender
}
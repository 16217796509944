import { observable, action, runInAction } from 'mobx';
import { getPolicies } from '../api';

class HomeStore {
  @observable
  isAuth = false;

  @observable
  allKeys = {};

  @observable
  userKeys = {};

  @action
  getPolicies = async () => {
    const polices = await getPolicies();
    runInAction(() => {
      this.allKeys = polices.data.allKeys;
      this.userKeys = polices.data.userKeys;
      this.isAuth = true;
    });
  }

  @action
  hasPolicies = (key) => {
    return Object.values(this.userKeys).includes(key);
  }
}

export default HomeStore;

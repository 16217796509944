import React from 'react';

function EmailBaseBox({ children = '', styles = {} }) {
    return <div 
            className={'floor_content'} 
            style={{
                maxWidth: '649px',
                margin: '0 auto',
                boxSizing: 'border-box',
                display: 'flex', 
                ...styles
            }}>
            {children}
        </div>
}

export default EmailBaseBox;
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { ContentTitle, FlexLine,Input,TextArea } from '../../../style';
import {WhiteSpace} from '../../../components/index';

import Percentage from '../../EditComponents/Percentage';
import ContentAlign from '../../EditComponents/ContentAlign';

@inject('createStore')
@observer
class EditPCCodeContainer extends Component {

    state = {
        isAddEditor: false
    }
    text = ''
    componentDidMount () {
        this.text = this.props.text || '请输入文本'
        this.setState({
            isAddEditor: true
        })
    }
    handleEditorChange = (e) => {
        const { activeRowData, seletedRowId, updateDataProps } = this.props.createStore;
        if (activeRowData && seletedRowId) {//选中楼层子组件，优先更新子组件
            updateDataProps('text', e.target.getContent());
        }
    }

    proportionChange = (value)=>{
        const { updateDataProps } = this.props.createStore;
        updateDataProps('boxWidth',value);
    }

    alignChange = (value) => {
        const { updateDataProps } = this.props.createStore;
        updateDataProps('align', value);
    }

    render() {
        const { text = 'code:', boxWidth = 100, align = '' } = this.props;
        const { updateDataProps } = this.props.createStore;
        return (
            <div>
                 <ContentTitle>文本:</ContentTitle>
                <Input
                    value={text}
                    placeholder={'code文案'}
                    onChange={(e) => updateDataProps('text', e.target.value)}
                    style={{ width: '100%', boxSizing: 'border-box' }}
                />
                <WhiteSpace />
                <Percentage percent={boxWidth}  onChange={this.proportionChange}/>
                <ContentAlign align={align} onChange={this.alignChange}/>
            </div>
        )
    }
}

export default EditPCCodeContainer;
import { observer, inject } from 'mobx-react'
import React, { Component } from 'react'
import pageBasePars from '../../pageComponents/pageBasePars'
import PageComponentsMachine from '../../pageComponents/pageComponentsMachine'

function FloorWrapper({ arg }) {
  const BaseBox = pageBasePars.render(arg.name)
  return (
    <BaseBox {...arg.props}>
      {
        arg.children.map((item, idx) => {
          const { name, props = {}, id } = item;
          if (!name) return '';
          const RowContent = pageBasePars.render(name)
          return (
            <RowContent key={idx} {...props}>
              {
                item.children.map((ele, _indx) => (
                  <div key={_indx}>
                    {(() => {
                      if (!ele.name) return null;
                      if (ele.name === arg.name) return <FloorWrapper arg={ele} />
                      return PageComponentsMachine.render(ele.name).renderComponent(ele.props || {})
                    })()}
                  </div>
                ))
              }
            </RowContent>
          )
        })
      }
    </BaseBox>
  )
}

@inject('viewStore')
@observer
class OldPreview extends Component {
  state = {
    isH5: false
  }

  componentWillMount() {
    const width = document.body.clientWidth
    this.setState({
      isH5: width < 500
    })

  }

  componentDidMount() {
    let html_json = localStorage.getItem('html_json')
    const { setHtmlJson } = this.props.viewStore
    if (html_json) {
      setHtmlJson(JSON.parse(html_json))
    }
  }

  render() {
    const { html_json } = this.props.viewStore
    const { pc = [], h5 = [], email = [] } = html_json

    if (email.length > 0) {
      return (
        <div>
          {
            email.map((arg, key) => {
              const BaseBox = pageBasePars.render(arg.name)
              return (
                <BaseBox key={key} {...arg.props}>
                  {
                    arg.children.map((item, idx) => {
                      const { name, props = {}, id } = item;
                      if (!name) return '';
                      const RowContent = pageBasePars.render(name)
                      return (
                        <RowContent key={idx} {...props}>
                          {
                            item.children.map((ele, _indx) => (<div key={_indx}>
                              {ele.name && PageComponentsMachine.render(ele.name).renderComponent(ele.props || {}) || '123'}
                            </div>))
                          }
                        </RowContent>
                      )
                    })
                  }
                </BaseBox>
              )
            })
          }
        </div>
      )
    }
    return (
      <div>
        {
          this.state.isH5 ?
            <React.Fragment>
              {
                h5.map((arg, key) => {
                  const BaseBox = pageBasePars.render(arg.name)
                  return <FloorWrapper arg={arg} key={key} />
                })
              }
            </React.Fragment>
            :
            <React.Fragment>
              {
                pc.map((arg, key) => {
                  return <FloorWrapper arg={arg} key={key} />
                })
              }
            </React.Fragment>
        }
      </div>
    );
  }
}

export default OldPreview

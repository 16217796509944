import { toLine } from "../../../utils/transformCssPropertyName"

export default function staticRender ({ config, page_id = 0 }) {
  let { id, props = {} } = config
  id = `${page_id}-${id}`
  let { title = '规则说明', ruleTitle = '', ruleTexts = [], hasNoNumList, styles = {} } = props

  if (!title) {
    return {
      styles: [],
      html: ''
    }
  }

  const result = {
    styles: [
      {
        name: `.pt-rule-${page_id}`,
        content: `
          .pt-rule-${page_id} {
            font-size: 16px;
            cursor: pointer;
            text-decoration: underline;
          }
          .dialog-rule-title {
            text-align: center;
            font-size: 24px;
            line-height: 1;
            padding: 50px 0 32px;
            color: #333;
          }
          .dialog-rule-list {
            margin: 20px 0;
          }
          .dialog-rule-item {
            color: #333;
            font-size: 16px;
            line-height: 1.3;
            text-align: left;
          }
          .dialog-rule-item.is-num {
            list-style: decimal;
          }
        `
      }
    ],
    html: `
      <div class="pt-rule-${page_id} pt-rule-${id}">
        <span class="pt-rule-title-${id}">${title}</span>
        <div class="dialog-box pt-rule-dialog-box-${id}">
          <div class="dialog">
            <div class="dialog-body">
              <svg viewBox="0 0 1000 1000" class="dialog-btn-close">
                <path d="
                          M 0 0 L 60 0 L 500 440 L 940 0 L 1000 0
                          L 1000 60 L 560 500 L 1000 940 L 1000 1000
                          L 940 1000 L 500 560 L 60 1000 L 0 1000
                          L 0 940 L 440 500 L 0 60 Z
                        " fill="#333" />
              </svg>
              <div class="dialog-user-body">
                <div class="dialog-rule-title">
                  ${ruleTitle ? `<b>${ruleTitle}</b>` : ''}
                  <ul class="dialog-rule-list">
                    ${ruleTexts.map(it => {
      return `<li class="dialog-rule-item ${hasNoNumList ? '' : 'is-num'}">${it}</li>`
    }).join(' ')}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <script>
        document.querySelector('.pt-rule-title-${id}').onclick = function() {
          document.querySelector('.pt-rule-dialog-box-${id}').classList.add('open');
        }
        document.querySelector('.pt-rule-${id} .dialog-btn-close').onclick = function() {
          document.querySelector('.pt-rule-dialog-box-${id}').classList.remove('open');
        }
      </script>
    `,
  }
  if (!Array.isArray(styles)) {
    result.styles.push(
      {
        name: `.pt-rule-${id}`,
        content: `
          .pt-rule-${id} {
            ${Object.keys(styles).map(css => `${toLine(css)}:${styles[css]};`).join(' ')}
          }
        `
      }
    )
  }
  return result
}

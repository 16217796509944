import  componentMount from "../../lib/componentMount";

import EditH5Text from "./EditH5Text";
import H5Text from "./H5Text";
import BaseStyle from  "../../BaseBox/BaseStyle";
import staticRender from "./StaticRender.js";

const H5TextRender = componentMount({
    Component:H5Text,
    Content:EditH5Text,
    Style:BaseStyle,
    text:'文本'
})

export default {
    componentMount:H5TextRender,
    name:'H5Text',
    text:'文本',
    staticRender
}
import componentMount from "../../lib/componentMount";

import EditContainer from "./EditEmailRowBaseBox";
import Component from "./EmailRowContent";
import BaseStyle from "../../BaseBox/BaseStyle";
import staticRender from "./StaticRender.js";

const _Render = componentMount({
    Component:Component,
    Content:EditContainer,
    Style:BaseStyle,
    text:'分栏'
})

export default {
    componentMount:_Render,
    name:'EmailRowContent',
    text:'分栏',
    staticRender
}
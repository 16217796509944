import React, {Component, Fragment} from 'react';
import styled from 'styled-components';
import {inject, observer} from 'mobx-react';
import PageComponentsMachine from '../../pageComponents/pageComponentsMachine';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Loading from '../Loading';

const Div = styled.div({
  width: '15%',
  minWidth: '200px',
  background: '#fff',
  overflow: 'hidden',
  overflowY: 'scroll'
});
const TabTxt = styled.span({
  cursor: 'pointer',
  paddingRight: '10px',
  overflowY: 'scroll'
})

const HeaderP = styled.p({
  textAlign: 'center'
})

@inject(({ createStore }) => ({
  seletedData: createStore.seletedData
}))
@observer
class PageRight extends Component {
  state = {
    isRefresh: false
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.seletedData.id !== this.props.seletedData.id) {
      this.setState({
        isRefresh: true
      }, () => {
        setTimeout(() => {
          this.setState({
            isRefresh: false
          })
        }, 300);
      })
    }
  }

  render() {
    const {seletedData} = this.props;
    const {name, props} = seletedData;
    //如果楼层 children 有选中，那么就显示children 编辑内容

    const componentsObj = PageComponentsMachine.render(name) || {};
    const {contentComponent, styleComponent,} = componentsObj;
    return (
      <Fragment>
        <Div>
          <HeaderP>编辑【{componentsObj.name || '请选中模块'}】</HeaderP>
          <Tabs>
            <TabList className='firmoo-tab-list'>
              <Tab className='firmoo-tab' selectedClassName='firmoo-tab-select' style={{corsur: 'pointer'}}>
                <TabTxt>内容</TabTxt>
              </Tab>
              <Tab className='firmoo-tab' selectedClassName='firmoo-tab-select'>
                <TabTxt>样式</TabTxt>
              </Tab>
            </TabList>
            <TabPanel>
              {this.state.isRefresh ? <Loading/> : <div style={{paddingLeft: '10px', paddingBottom: '40px'}}>
                {contentComponent && contentComponent(props)}
              </div>}
            </TabPanel>
            <TabPanel>
              {this.state.isRefresh ? <Loading /> : <div style={{paddingLeft: '10px'}}>
                {styleComponent && styleComponent(props)}
              </div>}
            </TabPanel>
          </Tabs>
        </Div>
      </Fragment>
    );
  }
}


export default PageRight;
import componentMount from "../../lib/componentMount";

import EditContainer from "./EditPcIconColumn";
import Component from "./PcIconColumn";
import Style from "../../BaseBox/BaseStyle";

const _Render = componentMount({
    Component:Component,
    Content:EditContainer,
    Style:Style,
    text:'图标栏'
})

export default {
    componentMount:_Render,
    name:'PcIconColumn',
    text:'图标栏'
}
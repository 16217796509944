import React, {Component, Fragment} from 'react';
import {Select} from '../../style';
import {inject, observer} from 'mobx-react';

import FontAlign from "./FontAlign";

function getNumFromPx (val){
  return `${val}`.split('px')[0];
}

function TextFontSizeInput(props) {
  return (
    <Fragment>
      <p style={{ fontSize: '14px', marginTop: '20px', marginBottom: '10px', color: '#666'}}>输入字体大小</p>
      <div style={{position: 'relative', width: '90%'}}>
        <input
          onChange={e => props.onChange(e.target.value + 'px')}
          type='number'
          defaultValue={getNumFromPx(props.fontSize)}
          style={{
            height: '30px',
            width: '100%',
            boxSizing: 'border-box',
            padding: '5px',
            border: '1px solid #ddd',
            outline: 'none',
            position: 'relative',
            margin: 0
          }}
        />
        <div style={{
          color: '#999',
          fontSize: '14px',
          textAlign: 'center',
          position: 'absolute',
          top: '3px',
          right: '25px'
        }}>
          px
        </div>
      </div>
    </Fragment>
  )
}

@inject('createStore')
@observer
class Font extends Component {
  get fontOptions() {
    return [
      {
        value: 'Roboto-Regular',
        label: 'Ubuntu-Regular'
      },
      {
        value: 'Roboto-Light',
        label: 'Ubuntu-Light'
      },
      {
        value: 'Roboto-Medium',
        label: 'Ubuntu-Medium'
      },
      {
        value: 'Poppins-Bold',
        label: 'Poppins-Bold'
      }, {
        value: 'Poppins-Light',
        label: 'Poppins-Light'
      }, {
        value: 'Poppins-Medium',
        label: 'Poppins-Medium'
      }, {
        value: 'Poppins-Regular',
        label: 'Poppins-Regular'
      }
    ]
  }


  get fontSizeOptions() {
    return [
      {
        value: '12px',
        label: '12px'
      },
      {
        value: '14px',
        label: '14px'
      },
      {
        value: '16px',
        label: '16px'
      },
      {
        value: '18px',
        label: '18px'
      }, {
        value: '20px',
        label: '20px'
      }, {
        value: '22px',
        label: '22px'
      }, {
        value: '24px',
        label: '24px'
      }, {
        value: '28px',
        label: '28px'
      }, {
        value: '32px',
        label: '32px'
      }, {
        value: '36px',
        label: '36px'
      }, {
        value: '48px',
        label: '48px'
      }
    ]
  }

  fontAlignChange = (val)=>{
    this.props.handleRefreshStyle('textAlign',val)
  }

  render() {
    const {fontFamily, fontSize,textAlign="", handleRefreshStyle} = this.props;
    const selectedComponentName = this.props.createStore.seletedData.name;
    return (
      <Fragment>
        <p style={{fontSize: '14px', marginBottom: '10px', color: '#666'}}>选择字体</p>
        <Select
          placeholder={'选择字体'}
          value={fontFamily}
        style={{
            width: '90%',
            paddingRight: '10px'
          }}
          onChange={(e) => {
            handleRefreshStyle('fontFamily', e.target.value)
          }}
        >
          <option value={''}>请选择</option>
          {
            this.fontOptions.map((item, index) => (
              <option key={index} value={item.value}>{item.label}</option>
            ))
          }
        </Select>
        <Fragment>
          <p style={{ fontSize: '14px', marginTop: '20px', marginBottom: '10px', color: '#666' }}>选择字体大小</p>
          <Select
            placeholder={'选择字体大小'}
            value={fontSize}
            style={{
              width: '90%',
              paddingRight: '10px'
            }}
            onChange={(e) => {
              handleRefreshStyle('fontSize', e.target.value)
            }}
          >
            <option value={''}>请选择</option>
            {
              this.fontSizeOptions.map((item, index) => (
                <option key={index} value={item.value}>{item.label}</option>
              ))
            }
          </Select>
        </Fragment>
        <TextFontSizeInput fontSize={fontSize} onChange={fs => handleRefreshStyle('fontSize', fs)} />
        <FontAlign align={textAlign} onChange={this.fontAlignChange}/>
      </Fragment>
    );
  }
}

export default Font;
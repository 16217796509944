

export default [
    {
        imgSrc:'https://df5apg8r0m634.cloudfront.net/images/5610fe3aa56a462b8e575a9eb2dee686.png',
        name:'User Name1',
        text:'It is a long established fact that a reader will be distrvacted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal,It is a long established fact that a reader will be distrvacted by the readable content of a page when looking at its layout.',
        link:''
    },
    {
        imgSrc:'https://df5apg8r0m634.cloudfront.net/images/5610fe3aa56a462b8e575a9eb2dee686.png',
        name:'User Name2',
        text:'It is a long established fact that a reader will be distrvacted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal,It is a long established fact that a reader will be distrvacted by the readable content of a page when looking at its layout.',
        link:''
    }
]
import componentMount from "../../lib/componentMount";

import EditContainer from "./EditPcCouponForm";
import Component from "./PcCouponForm";
import Style from "./EditPcCouponFormStyles";
import staticRender from "./StaticRender.js";

const _Render = componentMount({
    Component:Component,
    Content:EditContainer,
    Style:Style,
    text:'领取code'
})

export default {
    componentMount:_Render,
    name:'PcCouponForm',
    text:'领取code',
    staticRender
}
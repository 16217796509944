
import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { FlexLine } from '../../style';
import { Input } from '../../components/index';

const getNumFromPx = (val) => {
  return `${val}`.split('px')[0];
}

export default function Border ({ styles, handleRefreshStyle}){
  const {
    borderWidth,
    borderColor,
    borderTopWidth,
    borderTopColor,
    borderRightWidth,
    borderRightColor,
    borderBottomWidth,
    borderBottomColor,
    borderLeftWidth,
    borderLeftColor
  } = styles
  return <Fragment>
    {/* 全边框 */}
    <FlexLine justify="flex-start" style={{ marginBottom: '10px' }}>
      <div style={{ padding: '5px', border: '1px solid #e4e4e4', marginRight: '20px' }}>
        <BorderBox bottom={true} top={true} left={true} right={true}/>
      </div>
      <div style={{ position: 'relative' }}>
        <Input
          type='number'
          name={'borderWidth'}
          style={{ width: '80px', margin: '5px', paddingRight: '0' }}
          onChange={(e) => {
            handleRefreshStyle('borderWidth', `${e.target.value}px`)
            handleRefreshStyle('borderStyle', `solid`)
          }}
          value={getNumFromPx(borderWidth)}
        />
        <SpanText>px</SpanText>
      </div>
      <Input
        value={borderColor}
        type="color"
        name='borderColor'
        onChange={(e) => handleRefreshStyle('borderColor', `${e.target.value}`)}
        style={{ width: '28px', padding: '2px' }}
      />
    </FlexLine>
    {/* 左边框 */}
    <FlexLine justify="flex-start" style={{ marginBottom: '10px' }}>
      <div style={{ padding: '5px', border: '1px solid #e4e4e4', marginRight: '20px' }}>
        <BorderBox left={true} />
      </div>
      <div style={{ position: 'relative' }}>
        <Input
          type='number'
          name={'borderLeftWidth'}
          style={{ width: '80px', margin: '5px', paddingRight: '0' }}
          onChange={(e) => {
            handleRefreshStyle('borderLeftWidth', `${e.target.value}px`)
            handleRefreshStyle('borderLeftStyle', `solid`)
          }}
          value={getNumFromPx(borderLeftWidth)}
        />
        <SpanText>px</SpanText>
      </div>
      <Input
        value={borderLeftColor}
        type="color"
        name='borderLeftColor'
        onChange={(e) => handleRefreshStyle('borderLeftColor', `${e.target.value}`)}
        style={{ width: '28px', padding: '2px' }}
      />
    </FlexLine>
    {/* 上边框 */}
    <FlexLine justify="flex-start" style={{ marginBottom: '10px' }}>
      <div style={{ padding: '5px', border: '1px solid #e4e4e4', marginRight: '20px' }}>
        <BorderBox top={true} />
      </div>
      <div style={{ position: 'relative' }}>
        <Input
          type='number'
          name={'borderTopWidth'}
          style={{ width: '80px', margin: '5px', paddingRight: '0' }}
          onChange={(e) => {
            handleRefreshStyle('borderTopWidth', `${e.target.value}px`)
            handleRefreshStyle('borderTopStyle', `solid`)
          }}
          value={getNumFromPx(borderTopWidth)}
        />
        <SpanText>px</SpanText>
      </div>
      <Input 
        value={borderTopColor} 
        type="color" 
        name='borderTopColor' 
        onChange={(e) => handleRefreshStyle('borderTopColor', `${e.target.value}`)}
        style={{ width: '28px', padding: '2px' }}
        />
    </FlexLine>

    {/* 右边框 */}
    <FlexLine justify="flex-start" style={{ marginBottom: '10px' }}>
      <div style={{ padding: '5px', border: '1px solid #e4e4e4', marginRight: '20px' }}>
        <BorderBox right={true} />
      </div>
      <div style={{ position: 'relative' }}>
        <Input
          type='number'
          name={'borderRightWidth'}
          style={{ width: '80px', margin: '5px', paddingRight: '0' }}
          onChange={(e) => {
            handleRefreshStyle('borderRightWidth', `${e.target.value}px`)
            handleRefreshStyle('borderRightStyle', `solid`)
          }}
          value={getNumFromPx(borderRightWidth)}
        />
        <SpanText>px</SpanText>
      </div>
      <Input 
        value={borderRightColor} 
        type="color" 
        name='borderRightColor'
        onChange={(e) => handleRefreshStyle('borderRightColor', `${e.target.value}`)} 
        style={{ width: '28px', padding: '2px' }}
      />
    </FlexLine>
    {/* 下边框 */}
    <FlexLine justify="flex-start" style={{ marginBottom: '10px' }}>
      <div style={{ padding: '5px', border: '1px solid #e4e4e4', marginRight: '20px' }}>
        <BorderBox bottom={true} />
      </div>
      <div style={{ position: 'relative' }}>
        <Input
          type='number'
          name={'borderBottomWidth'}
          style={{ width: '80px', margin: '5px', paddingRight: '0' }}
          onChange={(e) => {
            handleRefreshStyle('borderBottomWidth', `${e.target.value}px`)
            handleRefreshStyle('borderBottomStyle', `solid`)
          }}
          value={getNumFromPx(borderBottomWidth)}
        />
        <SpanText>px</SpanText>
      </div>
      <Input
        value={borderBottomColor}
        type="color"
        name='borderBottomColor'
        onChange={(e) => handleRefreshStyle('borderBottomColor', `${e.target.value}`)}
        style={{ width: '28px', padding: '2px' }}
      />
    </FlexLine>
  </Fragment>
}


const BorderBox = styled.div(({ top = false, right = false, left = false, bottom = false, active }) => ({
  width: '16px',
  height: '16px',
  borderStyle: 'solid',
  borderColor: active ? '#26ABFF' : '#000',
  borderTopWidth: top ? '1px' : 0,
  borderBottomWidth: bottom ? '1px' : 0,
  borderLeftWidth: left ? '1px' : 0,
  borderRightWidth: right ? '1px' : 0,
}))

const SpanText = styled.div({
  color: '#999',
  fontSize: '14px',
  textAlign: 'center',
  position: 'absolute',
  top: '10px',
  right: '25px'
})
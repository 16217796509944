import { observer, inject } from 'mobx-react'
import React, { Component } from 'react'
import styled from 'styled-components'

import NewPriview from '../components/preview/NewPriview'
import OldPreview from '../components/preview/OldPreview'

const PreviewBox = styled.div`
    font-family: Arial, Helvetica, sans-serif;
    padding: 0 0 100px;
`

@inject('viewStore')
@observer
class Preview extends Component {
  state = {
    flag: 0
  }

  componentDidMount() {
    const html_json = localStorage.getItem('html_json')
    try {
      JSON.parse(html_json)
      this.setState({ flag: 1 })
    } catch (_) {
      this.setState({ flag: 2 })
    }
  }

  render() {
    const { flag } = this.state;
    return (
      <PreviewBox>
        {flag === 1 ? <OldPreview /> : flag === 2 ? <NewPriview /> : null}
      </PreviewBox>
    );
  }
}

export default Preview;

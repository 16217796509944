import React, {Component, Fragment} from 'react';
import {Input, WhiteSpace} from '../../components/index';
import { Select } from '../../style';
import styled from 'styled-components';
import {inject, observer} from 'mobx-react';
import {FlexLine} from '../../style';

import Border from '../EditComponents/Border';
import Font from "../EditComponents/Font";

import Collapse from "easy-collapse";
import Icon from '../../components/Icon';

@inject('createStore', 'viewStore')
@observer
class BaseStyle extends Component {

  //根性某个样式
  handleRefreshStyle = (key, value) => {
    //更新某个组件，或则楼层样式属性
    const {seletedData, updateDataProps} = this.props.createStore;
    const {props} = seletedData;
    const {styles} = props;
    let updataData = {
      ...styles,
      [key]: value
    };
    updateDataProps('styles', updataData);
  }
  getNumFromPx = (val) => {
    return `${val}`.split('px')[0];
  }

  selectImg = () => {
    this.props.viewStore.setSiteView('image', (url) => {
      this.handleRefreshStyle('backgroundImage', `url("${url}")`)
      // this.handleRefreshStyle('backgroundSize', `100% auto`)
      this.handleRefreshStyle('backgroundPosition', `center`)
      this.handleRefreshStyle('backgroundRepeat', `no-repeat`)
      this.props.viewStore.clearCallback()
    })
  }

  cleanImg = () => {
    this.handleRefreshStyle('backgroundImage', ``)
  }

  editBgImg (backgroundImage, backgroundSize) {
    return <div style={{padding: '0 10px'}}>
      <SelectImg onClick={this.selectImg}>选择图片</SelectImg>
      {backgroundImage &&
      <FlexLine
        justify="flex-start"
        style={{paddingLeft: '45px', marginBottom: '40px', marginTop: '5px'}}
      >
        <div onClick={this.cleanImg} style={{cursor: 'pointer'}}>
          <Icon icon="icon-delete"/>
        </div>
        <div style={{marginLeft: '10px', color: '#26ABFF'}}>{backgroundImage}</div>
      </FlexLine>}
      <div>背景图大小：</div>
      <Select
        placeholder={'选择背景图样式'}
        value={backgroundSize}
        style={{
          width: '90%',
          paddingRight: '10px'
        }}
        onChange={(e) => {
          this.handleRefreshStyle('backgroundSize', e.target.value)
        }}
      >
        <option value={''}>请选择</option>
        {
          [
            {
              value: 'auto auto',
              label: '原图'
            },{
              value: '100% auto',
              label: '宽度撑满'
            }, {
              value: 'auto 100%',
              label: '高度撑满'
            }
          ].map((item, index) => (
            <option key={index} value={item.value}>{item.label}</option>
          ))
        }
      </Select>
    </div>
  }

  editBgColor(backgroundColor) {
    return (
      <Fragment>
        <div style={{padding: '0 10px'}}>
          <FlexLine justify='space-between'>
            <Input value={backgroundColor} type="color" name='backgroundColor'
                   onChange={(e) => this.handleRefreshStyle('backgroundColor', `${e.target.value}`)}
                   style={{width: '28px', padding: '2px'}}/>
            <Input type="text" name='backgroundColor' placeholder={'选中颜色/输入颜色'} value={backgroundColor || ''}
                   onChange={(e) => this.handleRefreshStyle('backgroundColor', `${e.target.value}`)}/>
          </FlexLine>
        </div>
      </Fragment>
    )
  }

  editTextColor(color) {
    return (
      <Fragment>
        <div style={{padding: '0 10px'}}>
          <FlexLine justify='space-between'>
            <Input value={color} type="color" name='color'
                   onChange={(e) => this.handleRefreshStyle('color', `${e.target.value}`)}
                   style={{width: '28px', padding: '2px'}}/>
            <Input type="text" name='color' placeholder={'选中颜色/输入颜色'} value={color || ''}
                   onChange={(e) => this.handleRefreshStyle('color', `${e.target.value}`)}/>
          </FlexLine>
        </div>
      </Fragment>
    )
  }

  editMargin(list) {
    return (
      <div style={{padding: '0 10px'}}>
        {
          list.map((item, index) => {
            let pixNum = this.getNumFromPx(item.value);
            if (pixNum === 'undefined') {
              pixNum = 0
            }
            return (
              <FlexLine justify='flex-start' key={index}>
                <p style={{fontSize: "14px", marginRight: '10px'}}>{item.title}:</p>
                <div style={{position: 'relative'}}>
                  <Input
                    type='number'
                    name={item.name}
                    style={{width: '120px', margin: '5px', paddingRight: '0'}}
                    onChange={(e) => this.handleRefreshStyle(item.name, `${e.target.value}px`)}
                    value={pixNum}
                  />
                  <SpanText>px</SpanText>
                </div>
              </FlexLine>
            )
          })
        }
      </div>
    )
  }

  //字号，字体颜色，行高，对其方式，字体样式，背景色，内距，外距，边框
  render() {
    const {seletedData = {}, user} = this.props.createStore;
    const {props = {}} = seletedData;
    const {styles = {}} = props;
    const {notShowStyles = []} = this.props;
    const {
      paddingTop,
      paddingRight,
      paddingBottom,
      paddingLeft,
      marginTop,
      marginRight,
      marginBottom,
      marginLeft,
      backgroundColor,
      color,
      borderRadius,
      fontFamily,
      display = 'block',
      backgroundImage,
      backgroundSize = "auto auto",
      fontSize,
      textAlign,
      zIndex,
    } = styles;

    const marginList = [
      {
        name: 'marginTop',
        title: '上距',
        value: marginTop
      }, {
        name: 'marginBottom',
        title: '下距',
        value: marginBottom
      }, {
        name: 'marginLeft',
        title: '左距',
        value: marginLeft
      }, {
        name: 'marginRight',
        title: '右距',
        value: marginRight
      }
    ]

    const paddingList = [
      {
        name: 'paddingTop',
        title: '上距',
        value: paddingTop
      }, {
        name: 'paddingBottom',
        title: '下距',
        value: paddingBottom
      }, {
        name: 'paddingLeft',
        title: '左距',
        value: paddingLeft
      }, {
        name: 'paddingRight',
        title: '右距',
        value: paddingRight
      }
    ]
    return (
      <Fragment>
        <WhiteSpace/>
        {/* {
                    user === 'admin' && <FlexLine justify='flex-start'>
                        <p style={{ fontSize: "16px", marginRight: '10px', fontWeight: 800, color: '#333' }}>display:</p>
                        <div style={{ position: 'relative' }}>
                            <Input
                                name={'display'}
                                style={{ width: '120px', margin: '5px', paddingRight: '0' }}
                                onChange={(e) => this.handleRefreshStyle('display', `${e.target.value}`)}
                                value={display}
                            />
                        </div>
                </FlexLine>
                } */}
        <WhiteSpace/>
        <div className="style-xollapse">
          <Collapse>
            <Collapse.Panel isOpen={false} title={"背景颜色"} height="80">
              <WhiteSpace/>
              {this.editBgColor(backgroundColor)}
            </Collapse.Panel>
            <Collapse.Panel isOpen={false} title={"背景图片"} height="260">
              <WhiteSpace/>
              {this.editBgImg(backgroundImage, backgroundSize)}
            </Collapse.Panel>
            <Collapse.Panel isOpen={false} title={"文字颜色"} height="80">
              <WhiteSpace/>
              {this.editTextColor(color)}
            </Collapse.Panel>
            <Collapse.Panel isOpen={false} title={"字体"} height="360">
              <WhiteSpace/>
              <Font textAlign={textAlign} fontSize={fontSize} fontFamily={fontFamily} handleRefreshStyle={this.handleRefreshStyle}/>
            </Collapse.Panel>
            <Collapse.Panel isOpen={false} title={'圆角'} height="80">
              <WhiteSpace/>
              <div style={{position: 'relative', width: '220px'}}>
                <Input
                  type='number'
                  name={'borderRadius'}
                  style={{width: '220px', margin: '5px', paddingRight: '0'}}
                  onChange={(e) => this.handleRefreshStyle('borderRadius', `${e.target.value}px`)}
                  value={this.getNumFromPx(borderRadius)}
                />
                <SpanText>px</SpanText>
              </div>
            </Collapse.Panel>
            <Collapse.Panel isOpen={false} title={'层级'} height="80">
              <WhiteSpace/>
              <div style={{position: 'relative', width: '220px'}}>
                <Input
                  type='number'
                  name={'zIndex'}
                  style={{width: '220px', margin: '5px', paddingRight: '0'}}
                  onChange={(e) => this.handleRefreshStyle('zIndex', `${e.target.value}`)}
                  value={zIndex}
                />
              </div>
            </Collapse.Panel>
            <Collapse.Panel isOpen={false} title={'边框'} height="240">
              <Border styles={styles} handleRefreshStyle={this.handleRefreshStyle}/>
            </Collapse.Panel>
            <Collapse.Panel isOpen={false} title={'内距'} height="220" v>
              {this.editMargin(paddingList)}
            </Collapse.Panel>
            <Collapse.Panel isOpen={false} title={'外距'} height="220">
              {this.editMargin(marginList)}
            </Collapse.Panel>
          </Collapse>
        </div>
      </Fragment>
    )
  }
}

export default BaseStyle;


const ContentTitle = styled.p(({style = {}}) => ({
  fontSize: '16px',
  fontWeight: '800',
  color: '#333',
  marign: 0,
  marginBottom: '20px',
  paddingBottom: '10px',
  borderBottom: '1px solid #e4e4e4',
  ...style
}))
const BorderBox = styled.div(({top = false, right = false, left = false, bottom = false, active}) => ({
  width: '16px',
  height: '16px',
  borderStyle: 'solid',
  borderColor: active ? '#26ABFF' : '#000',
  borderTopWidth: top ? '1px' : 0,
  borderBottomWidth: bottom ? '1px' : 0,
  borderLeftWidth: left ? '1px' : 0,
  borderRightWidth: right ? '1px' : 0,
}))

const SpanText = styled.div({
  color: '#999',
  fontSize: '14px',
  textAlign: 'center',
  position: 'absolute',
  top: '10px',
  right: '25px'
})

const SelectImg = styled.div({
  width: '100px',
  padding: '5px 0',
  textAlign: 'center',
  fontSize: '14px',
  borderRadius: '4px',
  border: '1px solid #ccc',
  cursor: 'pointer',
  marginLeft: '10px'
})
import { observable, action } from 'mobx';

class ViewStore {
  @observable
  html_json = {
    pc: [],
    h5: []
  };

  @action
  setHtmlJson = (data) => {
    this.html_json = data
  }

  @observable
  siteNav = 'page';

  @observable
  onCallback = null;

  @action
  setSiteView = (data,onCallback) => {
    this.siteNav = data;
    this.onCallback = onCallback;
  }

  @action
  clearCallback = () => {
    this.onCallback = null;
  }
}

export default ViewStore;

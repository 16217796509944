// //组件注册机制 componentsMachine
// 1.注册全部组件
// 2.根据组件名，render 组件
// 3，渲染对应的 编辑组件

import PCCompontList from "./pc/index";
import H5CompontList from "./h5/Index";
import EmailCompontList from "./email/index";

//pc list
const PcComponentsList = [
  {
    title: "基础组件",
    list: [
      {
        text: "标题",
        icon: "icon-t",
        name: "PcTitle",
      },
      {
        text: "文本",
        icon: "icon-a",
        name: "PcText",
      },
      {
        text: "图片",
        icon: "iconimage",
        name: "PcImg",
      },
      {
        text: "按钮",
        icon: "icon-fabulous",
        name: "PcButton",
      },
      {
        text: "视频",
        icon: "icon-video",
        name: "PcVideo",
      },
    ],
  },
  {
    title: "固定组件",
    list: [
      {
        text: "轮播图",
        icon: "icon-shuffling",
        name: "PcSwiperImg",
      },
      {
        text: "用户评论",
        icon: "icon-review1",
        name: "PcUserShow",
      },
    ],
  },
  {
    title: "营销互动模板",
    list: [
      {
        text: "领取code",
        icon: "icon-list",
        name: "PcCouponForm",
      },
      {
        text: "规则说明",
        icon: "icon-explain",
        name: "PcRule",
      },
      {
        text: "倒计时",
        icon: "icon-time",
        name: "PcTime",
      },
      {
        text: "商品",
        icon: "icon-glaesses",
        name: "PcProduct",
      },
    ],
  },
];
// h5 componets list
const H5ComponentsList = [
  {
    title: "基础组件",
    list: [
      {
        text: "标题",
        icon: "icon-t",
        name: "H5Title",
      },
      {
        text: "文本",
        icon: "icon-a",
        name: "H5Text",
      },
      {
        text: "图片",
        icon: "iconimage",
        name: "H5Img",
      },
      {
        text: "按钮",
        icon: "icon-fabulous",
        name: "H5Button",
      },
      {
        text: "视频",
        icon: "icon-video",
        name: "H5Video",
      },
    ],
  },
  {
    title: "固定组件",
    list: [
      {
        text: "轮播图",
        icon: "icon-shuffling",
        name: "H5SwiperImg",
      },
      {
        text: "用户评论",
        icon: "icon-review1",
        name: "H5UserShow",
      },
    ],
  },
  {
    title: "营销互动模板",
    list: [
      {
        text: "领取code",
        icon: "icon-list",
        name: "H5CouponForm",
      },
      {
        text: "规则说明",
        icon: "icon-explain",
        name: "H5Rule",
      },
      {
        text: "倒计时",
        icon: "icon-time",
        name: "H5Time",
      },
      {
        text: "商品",
        icon: "icon-glaesses",
        name: "H5Product",
      },
    ],
  },
];

const EmailComponentsList = [
  {
    title: "基础组件",
    list: [
      {
        text: "标题",
        icon: "icon-t",
        name: "Title",
      },
      {
        text: "文本",
        icon: "icon-a",
        name: "Text",
      },
      {
        text: "图片",
        icon: "iconimage",
        name: "Img",
      },
      {
        text: "按钮",
        icon: "icon-fabulous",
        name: "Button",
      },
      {
        text: "商品",
        icon: "icon-glaesses",
        name: "EmailProduct",
      },
    ],
  },
];

class PageComponentsMachine {
  PcComponentsList = PcComponentsList;
  H5ComponentsList = H5ComponentsList;
  EmailComponentsList = EmailComponentsList;

  get componentsMap() {
    //组件map

    let H5Coms = {};
    H5CompontList.forEach((ele) => {
      H5Coms[ele.name] = ele.componentMount;
    });

    let PcComs = {};
    PCCompontList.forEach((ele) => {
      PcComs[ele.name] = ele.componentMount;
    });
    let EmailComs = {};
    EmailCompontList.forEach((ele) => {
      EmailComs[ele.name] = ele.componentMount;
    });

    return {
      ...PcComs,
      ...H5Coms,
      ...EmailComs,
    };
  }

  render(name) {
    return this.componentsMap[name];
  }
}

export default new PageComponentsMachine();

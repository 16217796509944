
import React from 'react';
import styled from 'styled-components';

const Container = styled.div(({ styles }) => ({
  display: 'flex',
  width: '100%',
  boxSizing: 'border-box',
  ...styles
}))

export default function PCCodeContainer ({ text = 'Code:', styles = {}, align = '', boxWidth=100 }){
  return <Container id="new_user_code_container" styles={align ? { justifyContent: align } : {}}>
    <div style={{ fontSize: '28px', fontWeight: '800', width:`${boxWidth}%`, ...styles}} >
         {text && <span style={{marginRight:'5px'}}>{text}</span>}
          <span id="new_user_alert_code">XXXX</span>
      </div>
    </Container>
}
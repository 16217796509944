

export default [
    {
        imgSrc: 'https://df5apg8r0m634.cloudfront.net/images/78c90f804d28f67d63f85de13c3efc63.gif',
        href: '',
    },
    {
        imgSrc: 'https://df5apg8r0m634.cloudfront.net/images/7e43df168b14ace9a7e9f4fd6749c737.jpg',
        href: '',
    }
]
import componentMount from "../../lib/componentMount";

import EditH5Title from "./EditH5Title";
import H5Title from "./H5Title";
import BaseStyle from "../../BaseBox/BaseStyle";
import staticRender from "./StaticRender.js";

const H5TitleRender = componentMount({
    Component:H5Title,
    Content:EditH5Title,
    Style:BaseStyle,
    text:'标题'
})

export default {
    componentMount:H5TitleRender,
    name:'H5Title',
    text:'标题',
    staticRender
}
import EmailBaseBox from "../pageComponents/email/EmailBaseBox"
import EmailRowContent from "../pageComponents/email/EmailRowContent"
import Title from "../pageComponents/email/Title"
import Text from "../pageComponents/email/Text"
import Img from "../pageComponents/email/Img"
import Button from "../pageComponents/email/Button"
import EmailProduct from "../pageComponents/email/EmailProduct"

const map = {
  EmailBaseBox,
  EmailRowContent,
  Title,
  Text,
  Img,
  Button,
  EmailProduct
}

export default async function getEmailHtmlByConfig ({ emailFloorList }) {
  let emailCode = await getHtml(emailFloorList)
  // 统一处理 background-image:url("https://s3-us-west-1.amazonaws.com/imgtest.firmoo.com/resources/HEsfZvcXqmSULexs1UT0Yvf33g4aQOhF4ZxMPzbn.png");
  emailCode = emailCode.replace(/background-image:url([^;]+)/g, (match, p) => {
    return `background-image:url(${p.substring(2, p.length - 2)});`
  })
  return {
    statusCode: 0,
    code: emailCode
  }
}

/**
 * 遍历配置项 得到代码和样式。最后把样式去重，和代码合在一起返回
 */
async function getHtml (configList) {
  let code = ''
  // 对配置项进行循环。然后得到这个 组件和后代组件 的 样式和代码
  for (const config of configList) {
    const { html } = getCodeStyle({ config })
    code += html
  }
  return code
}

function getCodeStyle ({ config }) {
  // 如果遇到找不到的元素 需要给出提示
  if (!map[config.name] || !map[config.name].staticRender) return { styles: [], html: '' }
  // 首先拿到父元素的样式和代码
  let { html } = map[config.name].staticRender({ config })
  if (config.children && config.children.length) {
    config.children.forEach((childConfig, childIndex) => {
      const { html: childHtml } = getCodeStyle({ config: childConfig })
      html = html.replace(`{{CHILDREN_PLACEHOLDER_${childIndex}}}`, childHtml)
    })
  }
  return { html }
}

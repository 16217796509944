

import React from 'react';
import styled from 'styled-components';


const Box = styled.div(({ styles }) => {
  return {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100px',
    ...styles,
  }
});

export default function H5SwiperItem ({ styles = {}, children=''}){
  return <Box className="swiper-slide" styles={{ ...styles, background: styles.backgroundColor }}>{children}</Box>
}